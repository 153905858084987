import { t } from "i18n-js";
import { useMutation } from "react-query";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useEditForm = ({ comment, onUpdate }) => {
  const { id, post_id: postId, body_for_editor: body } = comment;
  const { success, error } = useToast();

  const defaultValues = { body };

  const { mutateAsync: updateComment } = useMutation(
    commentData =>
      reactQueryPatch(internalApi.comments.update({ postId, id }), {
        comment: commentData,
      }),
    {
      onSuccess: commentData => {
        onUpdate(commentData);
        success(t("comments.edit.alerts.success"));
      },
      onError: () => error(t("comments.edit.alerts.failure")),
    },
  );

  return {
    defaultValues,
    updateComment,
  };
};
