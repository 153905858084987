import { CommentHierarchy } from "./CommentHierarchy";
import { CommentsList } from "./CommentsList";

/**
 * @deprecated
 * Please use the new CommentsV3 component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export default {
  CommentHierarchy,
  CommentsList,
};
