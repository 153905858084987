import { t } from "i18n-js";
import type { ScheduledEmail } from "@circle-react-shared/ScheduledEmailModal";
import { ScheduledEmailModal } from "@circle-react-shared/ScheduledEmailModal";
import { useScheduledEmailsForm } from "@circle-react-shared/ScheduledEmailModal/useScheduledEmailsForm";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { DeleteScheduledEmail } from "./DeleteScheduledEmail";
import { useModal } from "./useModal";
import { useScheduledEmailContext } from "./useScheduledEmailContext";

interface ScheduledEmailDropdownActionsProps {
  scheduledEmail: ScheduledEmail;
  disabled?: boolean;
}

export const ScheduledEmailDropdownActions = ({
  scheduledEmail,
  disabled,
}: ScheduledEmailDropdownActionsProps) => {
  const {
    isOpen: isEmailFormOpen,
    closeModal: closeEmailForm,
    openModal: openEmailForm,
  } = useModal();

  const {
    isOpen: isDeleteConfirmationOpen,
    closeModal: closeDeleteConfirmation,
    openModal: openDeleteConfirmation,
  } = useModal();

  const { refetch, maxDate, minDate } = useScheduledEmailContext();
  const { destroyScheduledEmail, isDestroyLoading } = useScheduledEmailsForm();

  const onClose = async () => {
    closeEmailForm();
    await refetch?.();
  };

  const onDelete = async () => {
    await destroyScheduledEmail(scheduledEmail);
    closeDeleteConfirmation();
    await refetch?.();
  };

  return (
    <>
      <Dropdown
        direction="bottom-end"
        menuButtonEl="div"
        button={
          <IconButton
            name="20-menu-dots-horizontal"
            iconClassName="!text-default group-hover:!text-dark !w-5 !h-5"
            className="group"
            variant="secondary"
            iconSize={20}
            buttonSize={28}
            ariaLabel={t("events.event_actions")}
            dataTestId="action-more"
            disabled={disabled}
          />
        }
        transitionWrapperClassName="z-50"
        size="small"
      >
        <Dropdown.ItemWithLink onClick={openEmailForm} linkType="button">
          {t("edit")}
        </Dropdown.ItemWithLink>

        <Dropdown.ItemWithLink
          onClick={openDeleteConfirmation}
          linkType="button"
        >
          <span className="text-v2-danger">{t("delete")}</span>
        </Dropdown.ItemWithLink>
      </Dropdown>
      <ScheduledEmailModal
        isOpen={isEmailFormOpen}
        onClose={onClose}
        scheduledEmail={scheduledEmail}
        maxDate={maxDate}
        minDate={minDate}
      />
      <DeleteScheduledEmail
        shouldShowConfirmationModal={isDeleteConfirmationOpen}
        toggleConfirmationModal={closeDeleteConfirmation}
        handleSubmit={onDelete}
        isLoading={isDestroyLoading}
      />
    </>
  );
};
