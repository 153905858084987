import { useEffect } from "react";
import type { Dispatch, RefObject, SetStateAction } from "react";
import type { Editor } from "@tiptap/react";
import { defer } from "lodash";
import {
  mentionBlock,
  textBlock,
} from "@circle-react-shared/uikit/TipTap/utilities/contentBuilder";

export const useCommunityMemberReply = (
  communityMemberForReply: any,
  currentCommunityMember: any,
  editorRef: RefObject<Editor> | undefined,
  setSgidToObjectMap: Dispatch<SetStateAction<object>>,
) => {
  useEffect(() => {
    if (
      communityMemberForReply &&
      communityMemberForReply.id !== currentCommunityMember?.id
    ) {
      setSgidToObjectMap(prevMap => ({
        ...prevMap,
        [communityMemberForReply.rich_text_field_sgid]: communityMemberForReply,
      }));
      defer(() => {
        editorRef?.current
          ?.chain?.()
          .focus("start")
          .insertContent([
            mentionBlock({
              sgid: communityMemberForReply.rich_text_field_sgid,
            }),
            textBlock({
              text: " ",
            }),
          ])
          .run();
      });
    }
  }, [
    communityMemberForReply,
    currentCommunityMember,
    editorRef,
    setSgidToObjectMap,
  ]);
};
