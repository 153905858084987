import { t } from "i18n-js";
import { postsPath } from "@circle-react/helpers/urlHelpers";

interface LeaveCommentButtonProps {
  post: {
    slug: string;
    space_slug: string;
  };
}

/**
 * @deprecated
 * Please use the new LeaveCommentButton component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const LeaveCommentButton = ({ post }: LeaveCommentButtonProps) => {
  const { slug, space_slug: spaceSlug } = post || {};

  return (
    <div className="border-primary flex items-center justify-center gap-4 border-t p-12">
      <a
        className="btn btn-secondary"
        href={postsPath({
          spaceSlug,
          slug,
        })}
      >
        {t("leave_a_comment")}
      </a>
    </div>
  );
};
