import { t } from "i18n-js";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { ModalCloseButton } from "@circle-react-shared/ModalsV2/FullScreenModal/components/ModalCloseButton";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";
import { useValidateFormSections } from "./hooks/useValidateFormSections";
import { SECTIONS } from "./utils";

interface MobileEventsModalHeaderProps {
  isOpen: boolean;
  handleClose: () => void;
  isEditMode: boolean;
}

export const MobileEventsModalHeader = ({
  isOpen,
  handleClose,
  isEditMode,
}: MobileEventsModalHeaderProps) => {
  const { currentSection, setCurrentSection } = useCustomizeUIData();
  const { validateAndSetCurrentSection } = useValidateFormSections();
  const isCurrentSectionOverview = currentSection === SECTIONS.overview;

  if (isCurrentSectionOverview) {
    return (
      <>
        <FullScreenModal.HeaderContent>
          <Typography.TitleSm truncate weight="semibold">
            {t("edit_event")}
          </Typography.TitleSm>
        </FullScreenModal.HeaderContent>
        <ModalCloseButton
          onClick={handleClose}
          isVisible={isOpen}
          className="!pr-0"
        />
      </>
    );
  }

  return (
    <FullScreenModal.HeaderContent>
      <div className="flex w-full items-center justify-between gap-2">
        {isEditMode && (
          <IconButton
            name="16-arrow-left"
            onClick={() => {
              void validateAndSetCurrentSection(
                SECTIONS.overview,
                setCurrentSection,
              );
            }}
            ariaLabel={t("back")}
          />
        )}
        <Typography.TitleSm truncate weight="semibold">
          {isEditMode ? t("edit_event") : t("events.create.title")}
        </Typography.TitleSm>
        <ModalCloseButton onClick={handleClose} isVisible={isOpen} />
      </div>
    </FullScreenModal.HeaderContent>
  );
};
