import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import {
  isDisplayCardView,
  isPublishedPost,
} from "@/react/helpers/postHelpers";
import { userIsAdminOrModeratorOfSpace } from "@/react/helpers/spaceHelpers";
import { usePlanFeatureFlags } from "@/react/hooks/usePlanFeatureFlags";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import { DateInput } from "@circle-react-uikit/DateInput";
import { Form } from "@circle-react-uikit/Form";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { Loader } from "@circle-react-uikit/Loader";
import { FieldLabel } from "../../shared/Form/FieldLabel";
import { If } from "../../shared/If";
import { SpaceSelect } from "../../shared/SpaceSelect";
import { FormInput } from "./FormInput";
import { MetaAttributesSection } from "./MetaAttributesSection";
import { ToggleItem } from "./ToggleItem";

export const SettingsSection = ({ space }) => {
  const { post } = useCurrentPostHelpers();
  const { watch } = useFormContext();
  const created_at = watch("created_at");
  const isCommentsDisabled = watch("is_comments_disabled");
  const canChangeAuthor = !!userIsAdminOrModeratorOfSpace(space || {});
  const spaces = useSpacesContext().helpers.spacesWithPostCreationAllowed();
  const { currentCommunityMember } = usePunditUserContext();
  const createdAtMaxDate = new Date().toUTCString();
  const { data: planFeatureFlags = {}, isLoading: isPlanFeatureFlagsLoading } =
    usePlanFeatureFlags({
      enabled: isCommunityAdmin(currentCommunityMember),
    });
  const isEditingPublishedPost = Boolean(post.id) && isPublishedPost(post);

  if (isPlanFeatureFlagsLoading) {
    return <Loader />;
  }

  return (
    <div className="settings-menu__wrapper">
      <div className="form-wrapper quickpost-modal__form-settings">
        <div className="form-content">
          <div className="-space-y-2">
            <ToggleItem name="hide_meta_info" />
            <ToggleItem name="is_comments_disabled" />
            {!isCommentsDisabled && <ToggleItem name="is_comments_closed" />}
            <ToggleItem name="is_liking_disabled" />
            <ToggleItem name="is_truncation_disabled" />
            {isEditingPublishedPost && (
              <>
                <ToggleItem name="pin_to_top" />
                <ToggleItem name="pinned_at" />
              </>
            )}
            {!space.hide_from_featured_areas && (
              <ToggleItem name="hide_from_featured_areas" />
            )}
            {isEditingPublishedPost && (
              <div className="form-group form-group--column !mt-0">
                <FieldLabel
                  field="created_at"
                  label={t("publish_date")}
                  description={t("publish_date_description")}
                />
                <DateInput name="created_at" maxDate={createdAtMaxDate} />
              </div>
            )}
          </div>
          <If condition={isDisplayCardView(space)}>
            <div className="form-group form-group--two-columns">
              <FieldLabel
                field="cardview_thumbnail"
                label={t("cardview_thumbnail")}
              />
              <ImageInput name="cardview_thumbnail" aspectRatio={600 / 300} />
            </div>
          </If>
          {isCommunityAdmin(currentCommunityMember) &&
            planFeatureFlags.internal_custom_html_enabled && (
              <FormInput
                name="internal_custom_html"
                variant="textarea"
                fullWidth
              />
            )}
          <Form.Item name="space_id" translationRoot="post" fullWidth>
            <SpaceSelect name="space_id" variant="form-input" spaces={spaces} />
          </Form.Item>

          <FormInput name="slug" fullWidth />
          <If condition={canChangeAuthor}>
            <Form.Item name="user_id" translationRoot="post" fullWidth>
              <Form.CommunityMemberSelect direction="up" valueField="user_id" />
            </Form.Item>
          </If>
          <If condition={!!created_at}>
            <MetaAttributesSection />
          </If>
        </div>
      </div>
    </div>
  );
};

SettingsSection.propTypes = {
  space: PropTypes.object.isRequired,
};
