import { t } from "i18n-js";
import { noop } from "lodash";
import { AdjustImages } from "@/react/components/Spaces/ImageSpace/ImagePostModal/AdjustImages";
import { CreatePost } from "@/react/components/Spaces/ImageSpace/ImagePostModal/CreatePost";
import { UploadImages } from "@/react/components/Spaces/ImageSpace/ImagePostModal/UploadImages";
import { STEPS } from "@/react/components/Spaces/ImageSpace/ImagePostModal/constants";
import { useInitializeStore } from "@/react/components/Spaces/ImageSpace/ImagePostModal/hooks/useInitializeStore";
import { usePost } from "@/react/components/Spaces/ImageSpace/ImagePostModal/hooks/usePost";
import { useImagePostModalStore } from "@/react/components/Spaces/ImageSpace/ImagePostModal/store";
import type { ImagePost } from "@/react/types/Post";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";

export interface ImagePostModalComponentProps {
  spaceId: number;
  onClose: () => void;
  currentPost?: ImagePost;
  onPostChange?: (post: ImagePost) => void;
  shouldSkipRedirectAfterSubmit?: boolean;
}

const ImagePostModalComponent = ({
  spaceId,
  onClose,
  currentPost,
  onPostChange = noop,
  shouldSkipRedirectAfterSubmit = false,
}: ImagePostModalComponentProps) => {
  const modal = useModal();
  const { currentStep, setCurrentStep, reset, isFormDirty, isImagesDirty } =
    useImagePostModalStore();
  const onModalClose = (skipDirtyCheck: boolean = false) => {
    if (
      skipDirtyCheck ||
      !(isFormDirty || isImagesDirty) ||
      confirm(t("quick_post_v2.accidental_leave_confirmation"))
    ) {
      reset();
      onClose();
    }
  };
  const post = usePost(currentPost);

  useInitializeStore(post);

  if (!modal || !modal.visible || !post) return null;

  return (
    <CurrentSpaceContextProvider spaceId={spaceId}>
      <PostsDetailsContextProvider perPagePostIds={[[post.id]]}>
        <CurrentPostContextProvider post={post}>
          {currentStep === STEPS.UPLOAD_IMAGES && (
            <UploadImages
              onClose={onModalClose}
              onSelection={() => setCurrentStep(STEPS.ADJUST_IMAGES)}
            />
          )}

          {currentStep === STEPS.ADJUST_IMAGES && (
            <AdjustImages onClose={onModalClose} />
          )}

          {currentStep !== STEPS.UPLOAD_IMAGES &&
            currentStep !== STEPS.ADJUST_IMAGES && (
              <CreatePost
                onClose={onModalClose}
                onPostChange={onPostChange}
                shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
              />
            )}
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};

const ImagePostModal = ModalManager.create(
  (props: ImagePostModalComponentProps) => (
    <ImagePostModalComponent {...props} />
  ),
);

export const useImagePostModal = () => useModal(ImagePostModal);
