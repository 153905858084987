import PropTypes from "prop-types";
import { t } from "i18n-js";
import { LOCATION_TYPES } from "@circle-react/components/EventsV3/Modal/utils";
import { DETAILS_SECTION_INDEX } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/utils";
import { useEditEventModal } from "@circle-react/components/Modals/EditEventModal";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useAddonsContext } from "../../AddonsProvider";
import { useFormSteps } from "./hooks/useFormSteps";

export const Footer = ({ roomType, validateAndContinue }) => {
  const {
    formId,
    isSaving,
    currentStep,
    totalSteps,
    isFirstStep,
    isLastStep,
    next,
    prev,
    onExit,
  } = useFormSteps(roomType);
  const editEventModal = useEditEventModal();

  const {
    streamingHoursAvailable,
    recordingHoursReached,
    limitsReachedOnOpen,
  } = useAddonsContext();

  const handleNextButtonClick = () => {
    if (roomType == ROOM_TYPES.EVENT) {
      onExit();
      editEventModal.show({ locationType: LOCATION_TYPES.LIVE_STREAM });
    } else {
      validateAndContinue(next);
    }
  };

  const handleUpgrade = event => {
    event.preventDefault();
    limitsReachedOnOpen();
  };
  return (
    <div className="flex w-full items-center justify-between">
      <Typography.TitleSm weight="semibold" color="text-gray-800">
        {t("live_streams.form.step", { currentStep, totalSteps })}
      </Typography.TitleSm>
      <div className="flex items-center gap-5">
        {currentStep === DETAILS_SECTION_INDEX &&
        !streamingHoursAvailable &&
        recordingHoursReached ? (
          <div className="flex justify-start">
            <button
              type="button"
              className="mr-1 cursor-pointer underline"
              onClick={handleUpgrade}
            >
              {t("live_streams.form.two_upgrades")}
            </button>
            <p>{t("live_streams.form.included")}</p>
          </div>
        ) : currentStep === DETAILS_SECTION_INDEX &&
          (!streamingHoursAvailable || recordingHoursReached) ? (
          <div className="flex justify-start">
            <button
              type="button"
              className="mr-1 cursor-pointer underline"
              onClick={handleUpgrade}
            >
              {t("live_streams.form.one_upgrade")}
            </button>
            <p>{t("live_streams.form.included")}</p>
          </div>
        ) : null}

        {isFirstStep && (
          <Button onClick={onExit} variant="secondary">
            {t("live_streams.form.cancel")}
          </Button>
        )}
        {!isFirstStep && (
          <Button onClick={prev} type="button" variant="secondary">
            {t("live_streams.form.prev")}
          </Button>
        )}
        {!isLastStep && (
          <Button
            onClick={handleNextButtonClick}
            type="button"
            variant="circle"
            className="border-darkest border"
          >
            {t("live_streams.form.next")}
          </Button>
        )}
        {isLastStep && (
          <Button
            form={formId}
            disabled={isSaving}
            type="submit"
            variant="circle"
            className="border-darkest border"
          >
            {t("live_streams.form.submit_button_label")}
          </Button>
        )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  roomType: PropTypes.string,
  validateAndContinue: PropTypes.func.isRequired,
};
