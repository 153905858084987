import { Fragment, useState } from "react";
import { AttendeeMenu } from "@circle-react/components/Events/EditForm/AttendeeMenu";
import { useRemoveCohostMutation } from "@circle-react/components/Events/EditForm/useRemoveCohostMutation";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { TruncateText } from "@circle-react/components/shared/uikit/TruncateText";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { Event } from "@circle-react/types/Event";
import { MemberProfileWrapper } from "@circle-react/wrappers/MemberProfileWrapper";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { AttendeeRemovalConfirmationModal } from "../AttendeeRemovalConfirmationModal";
import { RsvpStatusBadge } from "./RsvpStatusBadge";

interface ListProps {
  records: any[];
  event: Event;
  onChange: () => void;
}

const dateDateLongMonthYearString = (dateString: string, timeZone?: string) =>
  formatDateString(dateString, {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone,
  });

export const List = ({ records, event, onChange }: ListProps) => {
  const { removeCohostMutation } = useRemoveCohostMutation(event, onChange);
  const [selectedAttendeeId, setSelectedAttendeeId] = useState(null);
  const [
    shouldShowAttendeeRemovalConfirmation,
    setShouldShowAttendeeRemovalConfirmation,
  ] = useState(false);
  if (!records?.length) {
    return null;
  }
  const selectedRecord = records.find(
    record => record.id === selectedAttendeeId,
  );
  const selectedCharge = selectedRecord?.charge;
  const dismissAttendeeRemovalConfirmation = () =>
    setShouldShowAttendeeRemovalConfirmation(false);

  return (
    <div className="flex w-full flex-col">
      {records?.length > 0 && <hr />}
      {records?.map(
        ({
          id,
          avatar_url,
          name,
          email,
          rsvp_date,
          rsvp_status: rsvpStatus,
          public_uid,
          access_type,
        }) => (
          <Fragment key={id}>
            <div className="hover:bg-secondary group flex h-14 items-center rounded-md px-2">
              <div className="mr-3">
                <MemberProfileWrapper member={{ name, public_uid }}>
                  <UserImage src={avatar_url} name={name} size="8" />
                </MemberProfileWrapper>
              </div>
              <div className="w-1/2 shrink-0 md:w-1/3">
                <MemberProfileWrapper member={{ name, public_uid }}>
                  <TruncateText>
                    <Typography.LabelSm weight="semibold">
                      {name}
                    </Typography.LabelSm>
                  </TruncateText>
                </MemberProfileWrapper>
              </div>
              <div className="hidden w-1/4 shrink-0 md:[display:revert]">
                <TruncateText>
                  <Typography.LabelSm>{email}</Typography.LabelSm>
                </TruncateText>
              </div>
              <div className="w-1/2 text-right md:w-1/4">
                <Typography.LabelSm>
                  {dateDateLongMonthYearString(rsvp_date)}
                </Typography.LabelSm>
              </div>
              <div className="mx-2 flex w-24 shrink-0 justify-end">
                <RsvpStatusBadge rsvpStatus={rsvpStatus} />
              </div>
              <div className="flex-1">
                <AttendeeMenu
                  event={event}
                  attendeeId={id}
                  onRemove={onChange}
                  accessType={access_type}
                  setSelectedAttendeeId={setSelectedAttendeeId}
                  setShouldShowAttendeeRemovalConfirmation={
                    setShouldShowAttendeeRemovalConfirmation
                  }
                />
              </div>
            </div>
            <hr />
          </Fragment>
        ),
      )}
      {isPaidEvent(event) && (
        <AttendeeRemovalConfirmationModal
          isOpen={shouldShowAttendeeRemovalConfirmation}
          onClose={dismissAttendeeRemovalConfirmation}
          removeAttendeeMutation={removeCohostMutation.mutate}
          attendeeId={selectedAttendeeId}
          charge={selectedCharge}
        />
      )}
    </div>
  );
};
