import { useRef } from "react";
import classNames from "classnames";
import { useQueryClient } from "react-query";
import { usePostResource } from "@/react/hooks/posts/usePostResource";
import type { ImagePost } from "@/react/types";
import { COMMENTS_BASE_QUERY_KEY } from "@circle-react/components/CommentsV3/CommentsList/useCommentsList";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Content } from "./Content";

interface FullPostModalComponentProps {
  post?: ImagePost;
  spaceId?: number;
  onPostChange: (post: ImagePost) => void;
  onPostDestroy: (postId: number) => void;
  startingImageIndex?: number;
  autofocusReplyForm?: boolean;
  isSharedPostView?: boolean;
}
export const FullPostModalComponent = ({
  post,
  spaceId,
  onPostChange,
  onPostDestroy,
  startingImageIndex,
  autofocusReplyForm = false,
  isSharedPostView = false,
}: FullPostModalComponentProps) => {
  const modal = useModal();
  const nullInitialFocusRef = useRef(null);
  const { post: postResource, onPostChange: onPostResourceChange } =
    usePostResource({
      spaceId: post?.space_id,
      spaceSlug: post?.space_slug,
      postSlug: post?.slug,
    });
  const { currentCommunitySettings } = usePunditUserContext();
  const isTailwindPostsEnabled =
    currentCommunitySettings?.tailwind_posts_enabled;
  const queryClient = useQueryClient();

  return (
    <CurrentSpaceContextProvider spaceId={spaceId}>
      <PostsDetailsContextProvider
        perPagePostIds={[[post?.id]]}
        isSharedPostView={isSharedPostView}
      >
        <CurrentPostContextProvider
          post={postResource || post}
          usedIn="full-post-modal"
        >
          <Modal
            initialFocus={nullInitialFocusRef}
            isOpen={modal.visible}
            onClose={() => {
              void queryClient.invalidateQueries([
                COMMENTS_BASE_QUERY_KEY,
                post?.id,
              ]);
              modal.remove();
            }}
            contentPortalId="full-post-modal-portal"
          >
            <Modal.Overlay />
            <Modal.Content
              size="full"
              className={classNames(
                "flex !flex-row flex-wrap content-start sm:!max-h-[836px] sm:!max-w-[1256px]",
                {
                  "post-v2": !isTailwindPostsEnabled,
                },
              )}
            >
              {/* This is a hack to prevent the modal from focusing the ImageOptions right away */}
              <div ref={nullInitialFocusRef} tabIndex={-1} />
              <Content
                onPostChange={(post, options) => {
                  void onPostChange(post); // this updates the infinite query list
                  void onPostResourceChange(post, options); // this updates the post resource
                }}
                onPostDestroy={onPostDestroy}
                startingImageIndex={startingImageIndex}
                autofocusReplyForm={autofocusReplyForm}
              />
            </Modal.Content>
          </Modal>
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};
const FullPostModal = ModalManager.create(
  (props: FullPostModalComponentProps) => <FullPostModalComponent {...props} />,
);
export const useFullPostModal = () => useModal(FullPostModal);
