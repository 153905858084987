import { Portal } from "@material-ui/core";
import { t } from "i18n-js";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { EmojiPickerV3 } from "@circle-react-uikit/EmojiPickerV3";

export const CaptionToolbar = ({ editor, portalId }: any) => {
  if (!editor) {
    return null;
  }

  return (
    <Portal container={() => document.getElementById(portalId)}>
      <div className="flex items-center md:pt-2">
        <EmojiPickerV3
          popoverPanelClassName="md:!w-[21rem]"
          onEmojiSelect={emojiData =>
            editor.chain().focus().insertContent(emojiData.emoji).run()
          }
          targetWrapperClassName="!flex"
          target={
            <IconButton
              iconSize={20}
              buttonSize={28}
              iconClassName="!text-dark !w-6 !h-6"
              name="20-emoji"
              ariaLabel={t("emoji_picker")}
            />
          }
        />
      </div>
    </Portal>
  );
};
