import { t } from "i18n-js";
import { cloneDeep } from "lodash";
import { Slider } from "@circle-react-shared/uikit/Slider";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import type { SelectedImage } from "../../store";
import { useImagePostModalStore } from "../../store";

interface ZoomSliderProps {
  currentImage: SelectedImage;
}

export const ZoomSlider = ({ currentImage }: ZoomSliderProps) => {
  const { updateImage } = useImagePostModalStore();
  return (
    <div className="text-light flex items-center justify-between">
      <IconButton
        name="16-zoom-out"
        onClick={() => {
          const updatedImage = {
            ...cloneDeep(currentImage),
            zoom: currentImage.zoom - 0.1,
          };
          if (updatedImage.zoom >= 1) {
            updateImage(updatedImage);
          }
        }}
        className="mr-3"
        iconSize={16}
        buttonSize={28}
        ariaLabel={t("zoom_out")}
      />
      <div className="flex h-4 w-32 items-center">
        <Slider.Root
          value={[Math.round(currentImage.zoom * 10) / 10]}
          max={5}
          min={1}
          step={0.1}
          onValueChange={([zoom]) => {
            if (zoom === currentImage.zoom) return;
            updateImage({ ...cloneDeep(currentImage), zoom });
          }}
        >
          <Slider.Track>
            <Slider.Range className="!bg-badge" />
          </Slider.Track>
          <Slider.Thumb className="before:!bg-badge" />
        </Slider.Root>
      </div>
      <IconButton
        onClick={() => {
          const updatedImage = {
            ...cloneDeep(currentImage),
            zoom: currentImage.zoom + 0.1,
          };
          if (updatedImage.zoom <= 5) {
            updateImage(updatedImage);
          }
        }}
        className="ml-3"
        name="16-zoom-in"
        iconSize={16}
        buttonSize={28}
        ariaLabel={t("zoom_in")}
      />
    </div>
  );
};
