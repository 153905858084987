import classnames from "classnames";
import { t } from "i18n-js";
import { toLocaleString } from "@circle-react/helpers/number";
import type { Post } from "@circle-react/types/Post";
import { Icon } from "@circle-react-shared/Icon";
import { ShowPageLink } from "../PostViewListItem/ShowPageLink";

interface CommentButtonProps {
  post: Post;
  comments_count: number;
  className?: string;
  showCount?: boolean;
  onCommentsClick?: () => void;
  iconClassName: "!text-default" | "!text-dark";
}

/**
 * @deprecated
 * Please use the new CommentButton component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentButton = ({
  post,
  comments_count,
  className,
  showCount = true,
  onCommentsClick,
  iconClassName = "!text-default",
}: CommentButtonProps) => (
  <div className={classnames("col-span-1", className)}>
    <ShowPageLink
      post={post}
      allComments
      ariaLabel={t("cap_comment_aria_label", {
        title: post?.name || t("this_post"),
      })}
      className="action-item action-comment !flex !items-center !justify-center !text-center"
      onClick={onCommentsClick}
    >
      <span className="action__icon !inline-flex align-text-top">
        <Icon
          aria-hidden
          type="message"
          className={iconClassName}
          useWithFillCurrentColor
        />
      </span>
      {showCount && (
        <span className="count !text-dark">
          {toLocaleString(comments_count)}
        </span>
      )}
    </ShowPageLink>
  </div>
);
