import type { ReactNode } from "react";
import classNames from "classnames";

interface InputProps {
  value: string;
  label?: ReactNode;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
}

export const Input = ({
  label,
  value,
  placeholder,
  error,
  disabled,
  leftAddon,
  rightAddon,
  ...rest
}: InputProps) => (
  <div>
    {label && <div className="text-sm font-semibold">{label}</div>}
    <div className="mt-2 flex w-full">
      {leftAddon && (
        <div
          className={classNames(
            { "cursor-not-allowed bg-gray-50": disabled },
            "flex h-11 items-center rounded-l-lg border border-gray-300 bg-white px-4 py-3",
          )}
        >
          {leftAddon}
        </div>
      )}
      <input
        className={classNames(
          {
            "border-red-600 focus:border-red-600": error,
            "border-primary bg-secondary text-light cursor-not-allowed":
              disabled,
            "text-dark border-gray-400": !disabled,
            "rounded-l-none": leftAddon,
            "rounded-r-none": rightAddon,
          },
          "placeholder:text-light h-11 grow rounded-lg border border-solid px-4 py-3 outline-none placeholder:opacity-100 focus:border-2 focus:border-gray-500",
        )}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
      {rightAddon && (
        <div
          className={classNames(
            {
              "border-primary bg-secondary text-light cursor-not-allowed":
                disabled,
            },
            "bg-primary border-primary text-dark ml-[-1px] flex h-11 items-center rounded-r-lg border px-4 py-3",
          )}
        >
          {rightAddon}
        </div>
      )}
    </div>

    {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
  </div>
);
