import { immer } from "zustand/middleware/immer";

export interface UISliceProps {
  ui: {
    liveChatRoomUuid: null | string;
  };
}

export const createUISlice = immer<UISliceProps>(set => ({
  ui: {
    liveChatRoomUuid: null,
    setLiveChatRoomUuid: (uuid: string) =>
      set(state => {
        state.ui.liveChatRoomUuid = uuid;
      }),
  },
}));
