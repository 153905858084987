import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Option } from "@circle-react-shared/uikit/Combobox/Option";

export const CreatableOption = ({ filter, createTopicMutation }: any) => (
  <Option
    option="create"
    key="create"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      if (createTopicMutation.isLoading) return;
      createTopicMutation.mutate(filter);
    }}
    className="flex items-center"
  >
    <Icon
      className="mr-1"
      size={16}
      type={createTopicMutation.isLoading ? "loader" : "plus-v2"}
    />
    {t(
      createTopicMutation.isLoading
        ? "quick_post_v2.topics.creating"
        : "quick_post_v2.topics.create",
    )}
    <span className="text-dark ml-1 font-semibold">
      {t("quick_post_v2.topics.topic_quoted", {
        topic: filter,
      })}
    </span>
  </Option>
);
