import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { CommentEditBox } from "@circle-react/components/CommentsV3/Comment/EditModal/CommentEditBox";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";

export const TiptapEdit = ({ comment, updateComment }: any) => {
  const modal = useModal();

  useHotkeys(
    "esc",
    () => modal.remove(),
    {
      enableOnContentEditable: true,
    },
    [],
  );

  return (
    <>
      <Modal.Overlay />
      <Modal.Content size="lg" className="!overflow-visible">
        <Modal.Header>
          <Modal.Title size="sm">{t("comments.edit.form.title")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!overflow-y-visible pb-6">
          <CommentEditBox comment={comment} updateComment={updateComment} />
        </Modal.Body>
      </Modal.Content>
    </>
  );
};
