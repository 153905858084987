import { useMemo, useState } from "react";
import { t } from "i18n-js";
import { find } from "lodash";
import { useFormContext } from "react-hook-form";
import { DatePicker } from "@/react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import { PostSubmitButton } from "@circle-react/components/QuickPostV2/Content/Footer/PostSubmitButton";
import { ScheduledBar } from "@circle-react/components/QuickPostV2/Content/Footer/ScheduledBar";
import { hasSpacesFindByIdHelper } from "@circle-react/components/QuickPostV2/Settings/Form";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import {
  isValidBody,
  isValidName,
  isValidTiptapBody,
} from "@circle-react/components/QuickPostV2/validation";
import {
  useCurrentPostContext,
  usePunditUserContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { isPublishedPost } from "@circle-react/helpers/postHelpers";
import { userIsAdminOrModeratorOfSpace } from "@circle-react/helpers/spaceHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ActionSelectorButton } from "@circle-react-uikit/ActionSelectorButton";
import type { Option } from "@circle-react-uikit/ActionSelectorButton";

export const Actions = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const tiptapPostEnabled = currentCommunitySettings?.tiptap_post_enabled;
  const { watch, setValue, getValues } = useFormContext();
  const { published_at: publishedAt, status } = watch();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const { isSubmitting } = useQuickPostModalStore();
  const { name, body, tiptap_body, space_id, topics } = getValues();
  const { record: post, helpers, isSubmitEnabled } = useCurrentPostContext();
  const isTiptapPost = Boolean(helpers?.isTiptapPost);
  const isEdit = !!post?.id;
  const isPublished = isPublishedPost(post);
  const isUsingTiptap = isEdit ? isTiptapPost : tiptapPostEnabled;

  const hasValidContent = isUsingTiptap
    ? isValidTiptapBody(tiptap_body)
    : isValidBody(body);

  const translationKey = `quick_post.scheduled_post_ui.${
    isSubmitting ? "submitting_button" : "submit_button"
  }`;

  const options: Option[] = [
    {
      icon: "16-download",
      label: t(`${translationKey}.save_draft`),
      value: "draft",
    },
    {
      icon: "16-calendar",
      label: t(`${translationKey}.schedule_post`),
      value: "scheduled",
    },
    {
      icon: "16-publish",
      label: t(`${translationKey}.publish`),
      value: "published",
    },
  ];

  const selectedAction = find(options, { value: status || "published" });

  const setSelectedAction = (action: any) => {
    if (action.value === "scheduled") {
      setIsDatePickerVisible(true);
    } else {
      setValue("status", action.value);
      setIsDatePickerVisible(false);
      updatePublishedAt(null);
    }
  };

  const updatePublishedAt = (date: any) => {
    setValue("published_at", date);
  };

  const onScheduleDateChange = (date: any) => {
    updatePublishedAt(date);
    setValue("status", "scheduled");
    setIsDatePickerVisible(false);
  };

  const onCancelClick = (event: any) => {
    event && event.preventDefault();
    setIsDatePickerVisible(false);
    if (status === "scheduled") {
      revertToDraftState();
    }
  };

  const revertToDraftState = () => {
    setSelectedAction(options[0]);
  };

  const spaceId = watch("space_id");
  const spacesContext = useSpacesContext();
  let space: any;
  if (hasSpacesFindByIdHelper(spacesContext)) {
    space = spacesContext.helpers.findById(spaceId);
  }

  const errorMessage = useMemo(() => {
    if (!isValidName(name) && !hasValidContent) {
      return t("quick_post_v2.error_messages.no_title_or_body");
    }
    if (!space_id) {
      return t("quick_post_v2.error_messages.no_space");
    }
    if (space?.require_topic_selection && Number(topics?.length) <= 0) {
      return t("quick_post_v2.error_messages.no_topics");
    }
    return null;
  }, [name, space_id, hasValidContent, topics, space]);

  const isSubmitDisabled = !!errorMessage || isSubmitting || !isSubmitEnabled;

  const shouldShowActionSelector =
    space && userIsAdminOrModeratorOfSpace(space) && !isPublished;

  return (
    <>
      <ScheduledBar
        publishedAt={publishedAt}
        revertToDraftState={revertToDraftState}
        status={status}
        updatePublishedAt={updatePublishedAt}
      />
      <DatePicker
        datePickerVisible={isDatePickerVisible}
        onCancel={onCancelClick}
        value={publishedAt}
        onSave={onScheduleDateChange}
        className="-bottom-11"
        saveButtonVariant="circle"
      >
        <TippyV2
          disabled={!isSubmitDisabled}
          content={errorMessage}
          trigger="mouseenter click focus focusin"
        >
          {shouldShowActionSelector ? (
            <ActionSelectorButton
              type="submit"
              disabled={isSubmitDisabled}
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
              options={options}
              variant="circle"
            />
          ) : (
            <PostSubmitButton isEdit={isEdit} isDisabled={isSubmitDisabled} />
          )}
        </TippyV2>
      </DatePicker>
    </>
  );
};
