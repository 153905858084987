import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";

export const ToggleItem = ({ name }) => (
  <Form.Item
    id={`post_${name}`}
    name={name}
    label={t(`post.${name}`)}
    hideBorder
    hideDescription
    inlineReverse
  >
    <Form.ToggleSwitch variant="small" />
  </Form.Item>
);

ToggleItem.propTypes = {
  name: PropTypes.string.isRequired,
};
