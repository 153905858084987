import { t } from "i18n-js";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import { Button } from "@circle-react-uikit/Button";

export const PostSubmitButton = ({
  isDisabled,
  isEdit,
}: {
  isDisabled: boolean;
  isEdit: boolean;
}) => {
  const { isSubmitting } = useQuickPostModalStore();
  return (
    <Button disabled={isDisabled} type="submit" variant="circle">
      {t(
        `quick_post.scheduled_post_ui.${
          isSubmitting ? "submitting_button" : "submit_button"
        }.${isEdit ? "save" : "publish"}`,
      )}
    </Button>
  );
};
