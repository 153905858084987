import { t } from "i18n-js";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2";
import { UserImageStatus } from "@circle-react-shared/UserImageStatus";
import { Typography } from "@circle-react-uikit/Typography";
import { getParticipantNames } from "./helpers";

interface User {
  name: string;
  avatar_url: string;
  id: string;
  status: string;
}

export interface NewConversationProps {
  chatRoomUuid?: string;
  userList?: User[];
}

export const NewConversation = ({
  chatRoomUuid,
  userList,
}: NewConversationProps) => {
  const { chatParticipants, isLoading } = useChatParticipantsApi(chatRoomUuid);
  const participants = userList ? userList : chatParticipants;

  if (isLoading) return null;
  const participantNames = getParticipantNames(participants);

  return (
    <div className="block px-5 py-4">
      <div className="mb-2 flex space-x-2" data-testid="participants">
        {participants.map(participant => {
          const { name, avatar_url: src, id, status } = participant;
          return (
            <UserImageStatus
              key={id}
              src={src}
              name={name}
              size="10"
              status={status}
            />
          );
        })}
      </div>
      <Typography.LabelLg
        weight="medium"
        color="text-darkest"
        dataTestId="start-conversation"
      >
        {t("messaging.start_conversation")}
      </Typography.LabelLg>
      <Typography.BodyMd data-testid="start-conversation-message">
        {t("messaging.direct_message_history")} {participantNames}
      </Typography.BodyMd>
    </div>
  );
};
