import classnames from "classnames";
import { t } from "i18n-js";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { useIsOrCanBeCommunityMember } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { CommentCountLink } from "./CommentCountLink";

interface CommentsFormProps {
  shouldOpenCommentPage: boolean;
  showCommentAndFocusCommentForm: () => void;
  toggleComment: () => void;
}

/**
 * @deprecated
 * Please use the new CommentsForm component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentsForm = ({
  shouldOpenCommentPage,
  showCommentAndFocusCommentForm,
  toggleComment,
}: CommentsFormProps) => {
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const { post } = useCurrentPostHelpers();
  const {
    is_comments_closed: isCommentsClosed,
    is_comments_enabled: isCommentsEnabled,
    comments_count: commentsCount = 0,
    post_details_loaded,
  } = post;

  const isCommentsForm = isCommentsEnabled && !shouldOpenCommentPage;

  if (!isCommentsForm) return null;

  return (
    <>
      {!isCommentsClosed && isOrCanBeCommunityMember && (
        <button
          type="button"
          onClick={showCommentAndFocusCommentForm}
          aria-label={t("cap_comment_aria_label", {
            title: post?.name ?? t("this_post"),
          })}
          className={classnames(
            "action-comment text-default hover:!text-dark focus-visible:!text-dark transition-colors duration-150 ease-in-out",
          )}
        >
          <span className="action__icon">
            <Icon useWithFillCurrentColor type="message" />
          </span>
          <span className={classnames("action__label")}>
            {t("cap_comment")}
          </span>
        </button>
      )}
      <div className="engagement__comments">
        <CommentCountLink
          count={commentsCount}
          toggleComment={toggleComment}
          hasPostDetailsLoaded={post_details_loaded}
        />
      </div>
    </>
  );
};
