import { t } from "i18n-js";
import { noop } from "lodash";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  chatRoomPath,
  getRouteWithPrevLocation,
} from "@circle-react/helpers/urlHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";

interface MessageButtonProps {
  communityMemberId: number | string;
  closeCard: () => void;
}

export const MessageButton = ({
  communityMemberId,
  closeCard,
}: MessageButtonProps) => {
  const history = useHistory();
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { findOrCreateChatRoom } = useChatRoom();

  const { mutate: handleOnClick, isLoading } = useMutation(
    () =>
      findOrCreateChatRoom({
        kind: DIRECT_CHAT_ROOM,
        community_member_ids: [communityMemberId],
      }),
    {
      onSuccess: chatRoomUuid => {
        history.push(
          getRouteWithPrevLocation({
            url: chatRoomPath({ uuid: chatRoomUuid }),
          }),
        );
      },
    },
  );

  const onMessageButtonClick = () => {
    isViewOnlyMasquerading ? noop() : handleOnClick();
    closeCard();
  };

  return (
    <Button
      onClick={onMessageButtonClick}
      className="!border-primary flex items-center space-x-2 !px-3 !py-1.5 !font-medium"
      variant="secondary"
    >
      <Icon
        type={isLoading ? "loader" : "16-chat"}
        useWithFillCurrentColor
        size={16}
        aria-hidden
      />
      <span>{t("message")}</span>
    </Button>
  );
};
