import type { MouseEvent } from "react";
import { t } from "i18n-js";
import { isString, noop } from "lodash";
import { communityMemberProfilePath } from "@/react/helpers/urlHelpers";

// Use this only for non-standard links where it's not possible
// to use new URL(link).pathname
export const removeProtocolFromUrlString = <Type = unknown>(link: Type) =>
  isString(link) ? link.replace(/(^\w+:|^)\/\//, "") : link;

export interface ParamsShape {
  [key: string]: string | number | boolean | undefined;
}

export interface ShowProfileArg {
  memberPublicId: string;
  params: ParamsShape;
}

export type ShowMemberProfileDrawer = (arg: ShowProfileArg) => void;

export interface GetProfileLinkArgs {
  isProfileDrawerEnabled: boolean;
  name?: string;
  params: ParamsShape;
  publicUid: string;
  shouldOpenInNewTab?: boolean;
  showMemberProfileDrawer: ShowMemberProfileDrawer;
}

export interface ProfileLinkProps {
  ["aria-label"]: string;
  href: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  rel?: string;
  target?: string;
}

export const getProfileLinkProps = ({
  isProfileDrawerEnabled,
  name,
  params,
  publicUid,
  shouldOpenInNewTab = false,
  showMemberProfileDrawer,
}: GetProfileLinkArgs): ProfileLinkProps => {
  const user = name ? name : t("view_user_profile_name");
  const ariaLabel = t("view_user_profile", { user });

  if (!publicUid) {
    return {
      onClick: noop,
      href: "#",
      ["aria-label"]: "",
    };
  }

  if (shouldOpenInNewTab) {
    return {
      href: communityMemberProfilePath({
        public_uid: publicUid,
        params,
      }),
      target: "_blank",
      rel: "noreferrer",
      ["aria-label"]: ariaLabel,
    };
  }

  if (isProfileDrawerEnabled) {
    return {
      onClick: event => {
        event.preventDefault();
        showMemberProfileDrawer({
          memberPublicId: publicUid,
          params,
        });
      },
      href: communityMemberProfilePath({
        public_uid: publicUid,
      }),
      ["aria-label"]: ariaLabel,
    };
  }

  return {
    href: communityMemberProfilePath({
      public_uid: publicUid,
      params,
    }),
    ["aria-label"]: ariaLabel,
  };
};
