import PropTypes from "prop-types";
import { t } from "i18n-js";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { postApi } from "@/react/api";
import { POST_FORM_CACHE } from "@/react/components/constants";
import {
  POST_STATUS_PUBLISHED,
  afterSaveRedirectPath,
} from "@/react/helpers/postHelpers";
import { usePersistedFormFieldValues } from "@/react/hooks/utils/usePersistFormFields";
import {
  useCurrentPostContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ModalContent } from "./ModalContent";
import { QPWrapper } from "./QPWrapper";

export const ModalForm = ({
  maxZIndexEnabled = false,
  shouldSkipRedirectAfterSubmit = false,
  onSubmitSuccess,
  ...props
}) => {
  const { show, closeModal } = props;
  const spaceContext = useSpacesContext();
  const currentPostContext = useCurrentPostContext();
  const post = currentPostContext.helpers.postForTrixEditor();
  const quickPostPropsLoaded =
    spaceContext.helpers.isDataLoaded() && currentPostContext.record;
  const history = useHistory();
  const { resetValues } = usePersistedFormFieldValues({
    cacheKey: POST_FORM_CACHE,
  });
  const { success, error } = useToast();

  const handleSubmitSuccess = ({ responseData }) => {
    const redirectUrl = afterSaveRedirectPath({ post: responseData });

    currentPostContext.onPostChange(responseData);

    if (history) {
      if (responseData.status === post.status) {
        success(t("quick_post.toasts.changes_saved"));
      } else {
        success(t(`quick_post.toasts.${responseData.status}_saved`));
      }

      closeModal();
      resetValues();

      if (!shouldSkipRedirectAfterSubmit) {
        history.push(redirectUrl);
      }
    } else {
      closeModal();
      resetValues();

      if (!shouldSkipRedirectAfterSubmit) {
        window.location.href = redirectUrl;
      }
    }

    onSubmitSuccess && onSubmitSuccess(responseData);
  };

  const handleSubmitFailure = () => {
    if (!post.id) {
      error(t("quick_post.toasts.failed_to_create"));
    } else {
      error(t("quick_post.toasts.failed_to_update"));
    }
  };

  const onAddPost = async formData => {
    const response = await postApi.createOrUpdate({
      post: {
        ...post,
        space_id: post.space_id || formData.space_id,
      },
      formData,
    });

    if (response.ok) {
      const responseData = await response.json();

      handleSubmitSuccess({ responseData });

      // Return statements are used by react hook form to detect if the promise is resolved or no
      return true;
    }
    handleSubmitFailure();

    return Promise.reject(new Error("Failed to create or update the post"));
  };

  const formDefaultValues = {
    status: POST_STATUS_PUBLISHED,
    ...post,
  };

  if (!quickPostPropsLoaded) {
    return <Loader variant="fullscreen" />;
  }
  return (
    <div>
      <QPWrapper
        open={show}
        onClose={noop}
        version={2}
        maxZIndexEnabled={maxZIndexEnabled}
      >
        <Form onSubmit={onAddPost} defaultValues={formDefaultValues}>
          <ModalContent {...props} />
        </Form>
      </QPWrapper>
    </div>
  );
};

ModalForm.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  maxZIndexEnabled: PropTypes.bool,
  shouldSkipRedirectAfterSubmit: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};
