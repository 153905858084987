import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import type { Post } from "@/react/types/Post";
import { ALL_COMMENTS_ANCHOR } from "@circle-react/components/CommentsV2/constants";
import { isSharedPostView } from "@circle-react/helpers/postHelpers";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useModalData } from "@circle-react-uikit/ModalV2";

interface ShowPageLinkProps extends ComponentPropsWithoutRef<"a"> {
  post: Post;
  allComments?: boolean;
  forwardedRef?: any;
  children?: ReactNode;
  ariaLabel?: string;
  onClick?: () => void;
}

interface RouteParams {
  postSlug: string;
}

/**
 * @deprecated
 * Please use the new ShowPageLink component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const ShowPageLink = ({
  post,
  ariaLabel,
  allComments = false,
  forwardedRef,
  onClick,
  children,
  ...passThroughProps
}: ShowPageLinkProps) => {
  const { postSlug: postSlugInUrl } = useParams<RouteParams>();
  const isIframeEmbed = useIsIframeEmbed();
  const { isInsideModal, onClose } = useModalData();

  const postHref = postsPath({
    spaceSlug: post.space_slug,
    slug: post.slug,
    hash: allComments ? ALL_COMMENTS_ANCHOR : "",
  });

  if (isIframeEmbed) {
    return (
      <a
        href={postHref}
        target="_blank"
        rel="noreferrer"
        aria-label={ariaLabel}
        {...passThroughProps}
        {...(forwardedRef && { ref: forwardedRef })}
      >
        {children}
      </a>
    );
  }
  if (isSharedPostView(post, { postSlugInUrl })) {
    // Using this to do a hard reload to handle authorization on the backend for now
    return (
      <a
        href={postHref}
        aria-label={ariaLabel}
        {...passThroughProps}
        {...(forwardedRef && { ref: forwardedRef })}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={postHref}
      aria-label={ariaLabel}
      {...passThroughProps}
      {...(forwardedRef && { ref: forwardedRef })}
      onClick={isInsideModal ? onClose : onClick}
    >
      {children}
    </Link>
  );
};
