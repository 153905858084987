/* eslint-disable react-hooks/rules-of-hooks
 -- Disabled to set CI to fail on this issue on new files, PR #5195 */
import { usePostResource } from "@/react/hooks/posts/usePostResource";
import { ModalForm } from "@circle-react/components/QuickPost/ModalForm";
import { CurrentPostContextProvider } from "@circle-react/contexts";
import { Loader } from "@circle-react-uikit/Loader";

export const EditPostModal = ({
  show,
  closeModal,
  post: stalePost,
  shouldSkipRedirectAfterSubmit = false,
  onChange,
  onSubmitSuccess,
}) => {
  if (!show) return null;
  const { isLoading, post, remove } = usePostResource({
    spaceId: stalePost.space_id,
    postSlug: stalePost.slug,
    // eslint-disable-next-line no-restricted-syntax -- Not a translatable string
    context: "edit-post-modal",
  });

  if (isLoading) {
    return <Loader variant="fullscreen" />;
  }

  const handleCloseModal = () => {
    closeModal && closeModal();
    remove();
  };

  return (
    <CurrentPostContextProvider post={post} onPostChange={onChange}>
      <ModalForm
        show={show}
        closeModal={handleCloseModal}
        shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </CurrentPostContextProvider>
  );
};
