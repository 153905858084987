export const shouldShowMessageOption = (currentCommunityMember, member) => {
  const isCurrentCommunityMemberProfile =
    currentCommunityMember?.id === member.community_member_id;
  return (
    !isCurrentCommunityMemberProfile &&
    currentCommunityMember?.messaging_enabled &&
    (member.messaging_enabled || currentCommunityMember.is_admin)
  );
};

export const shouldShowRemoveOption = (currentCommunityMember, member) => {
  const isCurrentCommunityMemberProfile =
    currentCommunityMember?.id === member.community_member_id;
  return !isCurrentCommunityMemberProfile && currentCommunityMember?.is_admin;
};
