import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

interface CopilotItemProps {
  localeItem: string;
}

export const CopilotItem = ({ localeItem }: CopilotItemProps) => {
  const localeScope = "settings.community_ai.marketing_modal";

  return (
    <span className="bg-primary rounded-full px-3 py-1">
      <Typography.BodyXs>
        {t([localeScope, "copilot_items", localeItem])}
      </Typography.BodyXs>
    </span>
  );
};
