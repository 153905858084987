import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { useShouldDisplayV3OptInBar } from "./OptInBar/hooks/useShouldDisplayV3OptInBar";
import { useShouldDisplayV3PreviewBar } from "./PreviewBar/hooks/useShouldDisplayV3PreviewBar";

export const useIsEitherOptInOrPreviewBarVisible = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const shouldDisplayV3OptInBar = useShouldDisplayV3OptInBar();
  const shouldDisplayV3PreviewBar = useShouldDisplayV3PreviewBar();
  const { isSettingsRoute } = useSettingsHeader();
  const isCurrentMemberAdmin = isCommunityAdmin(currentCommunityMember);

  if (!isCurrentMemberAdmin || isSettingsRoute) return false;

  return shouldDisplayV3OptInBar || shouldDisplayV3PreviewBar;
};
