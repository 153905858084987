import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";

export const usePreventAccidentalModalClose = ({
  shouldPrevent,
  message,
}: {
  shouldPrevent: boolean;
  message?: string;
}) => {
  const modal = useModal();
  return () => {
    if (
      !shouldPrevent ||
      confirm(message || t("quick_post_v2.accidental_leave_confirmation"))
    ) {
      modal.remove();
    }
  };
};
