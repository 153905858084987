import { t } from "i18n-js";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import "./styles.scss";

export const Loading = () => (
  <div className="flex flex-col items-center justify-center py-52">
    <div className="override-svg-width">
      <Loader />
    </div>
    <div className="mb-4 text-center">
      <Typography.TitleMd>
        {t("events.create_modal.creating_event")}
      </Typography.TitleMd>
    </div>
    <div className="mb-4 text-center">
      <Typography.LabelMd>
        {t("events.create_modal.creating_event_message")}
      </Typography.LabelMd>
    </div>
  </div>
);
