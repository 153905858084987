import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptyList = () => (
  <div className="flex h-64 w-full flex-col items-center justify-center">
    <Typography.TitleSm weight="semibold">
      {t("events.edit_modal.overview.empty_rsvp")}
    </Typography.TitleSm>
  </div>
);
