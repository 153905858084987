import { t } from "i18n-js";

const unreadMessageLineClasses =
  "relative flex items-center justify-center z-0 mx-5 before:w-full before:m-0 before:border-t before:border-solid before:border-v2-danger before:z-[-1] before:content-['']";

export const UnreadMessageLine = () => (
  <div
    id="unread-thread-message-breakpoint"
    className={unreadMessageLineClasses}
  >
    <span className="text-v2-danger border-none py-[5px] pl-[10px] text-xs font-semibold uppercase">
      {t("messaging.new")}
    </span>
  </div>
);
