import { usePunditUserContext } from "@circle-react/contexts";

export const useEventsFeatureFlags = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const {
    events_thumbnail_enabled: eventsThumbnailEnabled,
    limit_url_sharing_enabled: limitUrlSharingEnabled,
    self_updating_calendar_invites_enabled: selfUpdateCalendarEnabled,
  } = currentCommunitySettings || {};

  return {
    eventsThumbnailEnabled,
    limitUrlSharingEnabled,
    selfUpdateCalendarEnabled,
  };
};
