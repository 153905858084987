import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useWatch } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../Common/FormItem";
import { ThankYouMessage } from "./ThankYouMessage";

export const Notifications = ({ event }) => {
  const enableCustomThankYouMessage = useWatch({
    name: "event_setting_attributes.enable_custom_thank_you_message",
    defaultValue:
      event?.event_setting_attributes.enable_custom_thank_you_message || false,
  });

  return (
    <div className="mb-6 md:mb-12">
      <Typography.TitleXl>
        {t("events.edit_modal.notifications.title")}
      </Typography.TitleXl>
      <div className="mt-6 md:mb-3 md:mt-8">
        <Typography.TitleMd>
          {t("events.edit_modal.notifications.publish")}
        </Typography.TitleMd>
      </div>
      <FormItem
        name="event_setting_attributes.send_publish_email"
        tooltipText={t(
          "events.edit_modal.event_setting_attributes.send_publish_email_tooltip",
        )}
        inlineReverse
        className="!pb-0"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
      <div className="mt-6 md:mb-3 md:mt-8">
        <Typography.TitleMd>
          {t("events.edit_modal.notifications.confirmation")}
        </Typography.TitleMd>
      </div>
      <FormItem
        name="event_setting_attributes.send_email_confirmation"
        tooltipText={t(
          "events.edit_modal.event_setting_attributes.send_email_confirmation_tooltip",
        )}
        inlineReverse
        className="!pb-0"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
      <FormItem
        name="event_setting_attributes.send_in_app_notification_confirmation"
        tooltipText={t(
          "events.edit_modal.event_setting_attributes.send_in_app_notification_confirmation_tooltip",
        )}
        inlineReverse
        className="!pb-0"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
      <FormItem
        name="event_setting_attributes.enable_custom_thank_you_message"
        tooltipText={t(
          "events.edit_modal.event_setting_attributes.enable_custom_thank_you_message_tooltip",
        )}
        inlineReverse
        className="!pb-0"
      >
        <Form.ToggleSwitch variant="small" />
      </FormItem>
      {enableCustomThankYouMessage && <ThankYouMessage />}
    </div>
  );
};

Notifications.propTypes = {
  event: PropTypes.object,
};
