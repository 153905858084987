import { useEffect } from "react";
import { t } from "i18n-js";
import { noop } from "lodash";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { STEPS } from "../constants";
import { useImagePostModalStore } from "../store";
import { Carousel } from "./Carousel";
import { useUploadImages } from "./hooks/useUploadImages";

interface AdjustImagesProps {
  onClose: (skipDirtyValidation?: boolean) => void;
  onCompleted?: () => void;
  isOnTop?: boolean;
  editingImageSignedId?: string;
}
export const AdjustImages = ({
  onClose,
  onCompleted = noop,
  isOnTop = false,
  editingImageSignedId,
}: AdjustImagesProps) => {
  const { setCurrentStep, resetSelectedFiles } = useImagePostModalStore();
  const {
    uploadImages,
    overallProgress,
    resetUploads,
    status,
    fileStates,
    updateStoredImages,
  }: any = useUploadImages();

  const onModalClose = () => {
    resetSelectedFiles();
    onClose();
  };

  const onCancel = () => {
    resetSelectedFiles();
    isOnTop ? onClose() : setCurrentStep(STEPS.UPLOAD_IMAGES);
  };

  useEffect(() => {
    if (status === "completed") {
      updateStoredImages(editingImageSignedId);
      resetUploads();
      resetSelectedFiles();
      setCurrentStep(STEPS.CREATE_POST);
      onCompleted();
      if (isOnTop) onClose();
    } else if (status === "error") {
      resetUploads();
    }
  }, [isOnTop, status]);

  return (
    <Modal
      isOpen
      onClose={onModalClose}
      contentPortalId="adjust-images-modal-portal"
    >
      <Modal.Overlay />
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title size="sm">
            {t("image_space.post_modal.steps.adjust_images.title")}
          </Modal.Title>
          <Modal.HeaderActions className="!gap-3">
            <Button type="button" variant="secondary" onClick={onCancel}>
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              variant="circle"
              disabled={status === "uploading"}
              onClick={() => uploadImages()}
            >
              {isOnTop ? t("save") : t("next")}
            </Button>
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="flex flex-col pb-5 pt-4">
          <Carousel
            status={status}
            fileStates={fileStates}
            overallProgress={overallProgress}
            editingImageSignedId={editingImageSignedId}
            onDeleteAllImages={onCancel}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
