import { useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

const COMMENT_FLAGGED_CONTENTABLE_TYPE = "Comment";

/**
 * @deprecated
 * Please use the new useReportHandler hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useReportHandler = ({ comment }) => {
  const { id } = comment;
  const { success, error } = useToast();
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const openReportModal = () => setIsReportModalOpen(true);
  const closeReportModal = () => setIsReportModalOpen(false);

  const { mutate: submitReport } = useMutation(
    formData =>
      reactQueryPost(internalApi.flaggedContent.create(), {
        flagged_content: {
          ...formData,
          flagged_contentable_id: id,
          flagged_contentable_type: COMMENT_FLAGGED_CONTENTABLE_TYPE,
        },
      }),
    {
      onSuccess: () => {
        success(t("comments.report.alerts.success"), {
          duration: "short",
          shouldUseProgress: false,
        });
        closeReportModal();
      },
      onError: () => {
        error(t("comments.report.alerts.failure"));
      },
    },
  );

  return {
    isReportModalOpen,
    openReportModal,
    closeReportModal,
    submitReport,
  };
};
