import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { CommentsForm } from "./CommentsForm";
import { CommentsLink } from "./CommentsLink";
import { LikeButton } from "./LikeButton";
import { PostLikedByMemberStack } from "./PostLikedByMemberStack";

interface EngagementActionsProps {
  toggleComment: () => void;
  showCommentAndFocusCommentForm: () => void;
  shouldOpenCommentPage?: boolean;
  shouldForceSamePageInteractions?: boolean;
  className?: string;
}

export const EngagementActions = ({
  toggleComment,
  showCommentAndFocusCommentForm,
  shouldOpenCommentPage = false,
  shouldForceSamePageInteractions = false,
  className = "",
}: EngagementActionsProps) => {
  const { showEngagementActions: shouldShowActions } = useCurrentPostHelpers();

  if (!shouldShowActions) {
    return null;
  }

  return (
    <>
      <PostLikedByMemberStack />
      <div
        className={classNames(
          "post__actions post__actions-visible border-primary flex items-center gap-1 border-t px-3 py-2.5 md:px-4",
          className,
        )}
        data-testid="post-engagement-actions"
      >
        <BlockInViewOnlyMode>
          <LikeButton />
        </BlockInViewOnlyMode>

        <CommentsForm
          shouldOpenCommentPage={shouldOpenCommentPage}
          showCommentAndFocusCommentForm={showCommentAndFocusCommentForm}
          toggleComment={toggleComment}
          shouldForceSamePageInteractions={shouldForceSamePageInteractions}
        />
        <CommentsLink
          shouldOpenCommentPage={shouldOpenCommentPage}
          toggleComment={toggleComment}
        />
      </div>
    </>
  );
};
