export const ROOM_STATUS = Object.freeze({
  INITIALIZING: "initializing",
  BACKSTAGE: "backstage",
  LIVE: "active",
  ENDED: "inactive",
  CLOSING: "closing",
});

export const ROOM_TYPES = Object.freeze({
  CONFERENCE: "conference",
  STREAM: "stream",
  EVENT: "event",
});

export const ROOM_ACCESS_TYPES = Object.freeze({
  OPEN: "open",
  SECRET: "secret",
  PUBLIC_STREAM: "public_stream",
});

export const ROOM_VIEW_TYPES = Object.freeze({
  SPEAKER: "speaker_view",
  GRID: "grid_view",
});

export const PARTICIPANT_ROLES = Object.freeze({
  HOST: "host",
  SPECTATOR: "spectator",
  HLS_SPECTATOR: "hlsspectator",
  MODERATOR: "moderator",
});

export const PARTICIPANT_DISPLAY_ROLES = Object.freeze({
  HOST: "host",
  MODERATOR: "moderator",
  COHOST: "cohost",
  PARTICIPANT: "participant",
});

export const PARTICIPANT_STATUS = Object.freeze({
  JOINING: "joining",
  ACTIVE: "active",
  INACTIVE: "inactive",
});

export const ROOM_PROVIDERS = Object.freeze({
  HMS: "hms",
  LIVEKIT: "livekit",
});

export const INITIAL_HOST_NUMBER = 1;

export const isBackstage = room => room?.status === ROOM_STATUS.BACKSTAGE;
export const isLive = room => room?.status === ROOM_STATUS.LIVE;
export const isClosing = room => room?.status === ROOM_STATUS.CLOSING;
export const isEnded = room => room?.status === ROOM_STATUS.ENDED;
export const isStream = room => room?.room_type === ROOM_TYPES.STREAM;
export const isConference = room => room?.room_type === ROOM_TYPES.CONFERENCE;
export const isRecordingEnabled = room => room?.recording_enabled === true;
export const canManageRoom = room => !!room?.can_manage;
export const canHostRoom = room => !!room?.can_host;
export const canModerateRoom = room => !!room?.can_moderate;
export const roomHostCapacityReached = room => !!room?.host_capacity_reached;
export const roomSpectatorCapacityReached = room =>
  !!room?.spectator_capacity_reached;
export const roomTypeData = room => room?.room_type || ROOM_TYPES.STREAM;
export const isSpeakerView = room =>
  !room?.view_type || room?.view_type === ROOM_VIEW_TYPES.SPEAKER;
export const isGridView = room => room?.view_type === ROOM_VIEW_TYPES.GRID;
export const isSecretRoom = room =>
  room?.access_type === ROOM_ACCESS_TYPES.SECRET;
export const isPublicStream = room =>
  room?.access_type === ROOM_ACCESS_TYPES.PUBLIC_STREAM;
export const isParticipantInactive = participant =>
  participant?.status === "inactive";
export const isHostRole = participant =>
  participant?.role === PARTICIPANT_ROLES.HOST;
export const isSpectatorRole = participant =>
  participant?.role === PARTICIPANT_ROLES.SPECTATOR;
export const isHLSSpectatorRole = participant =>
  participant?.role === PARTICIPANT_ROLES.HLS_SPECTATOR;
export const isSecretRoomSelectedInForm = field =>
  field === ROOM_ACCESS_TYPES.SECRET;
export const isGridViewSelectedInForm = field => field === ROOM_VIEW_TYPES.GRID;
export const isHMSProvider = room => room?.room_provider === ROOM_PROVIDERS.HMS;
export const isLivekitProvider = room =>
  room?.room_provider === ROOM_PROVIDERS.LIVEKIT;
export const canManagePublicStream = room =>
  isPublicStream(room) && canManageRoom(room);
export const isLimitUrlSharingEnabled = room =>
  room?.limit_url_sharing === true;
export const shouldHideParticipantsList = room =>
  !!room?.hide_participants_list;
export const shouldHideParticipantsListForCurrentUser = room =>
  shouldHideParticipantsList(room) &&
  !canManageRoom(room) &&
  !canModerateRoom(room);
export const getParticipantFromPeerMetadata = peer => {
  const strMetadata = peer?.metadata;
  if (!strMetadata) {
    return null;
  }
  try {
    const participant = JSON.parse(strMetadata);
    return { ...participant, role: peer.roleName };
  } catch (e) {
    console.error("Error parsing peer metadata", e);
    return null;
  }
};
