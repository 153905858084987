import { t } from "i18n-js";
import type { GalleryImage } from "@/react/types/GalleryImage";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Icon } from "@circle-react-shared/Icon";
import { ModalCloseButton } from "@circle-react-uikit/ModalV2/ModalCloseButton";

interface HeaderProps {
  image: GalleryImage;
  canDownload: boolean;
}
export const Header = ({ image, canDownload }: HeaderProps) => {
  const modal = useModal();
  return (
    <div className="flex justify-end gap-2 pr-2 pt-2">
      {canDownload && (
        <a
          href={image.original_url}
          download
          aria-label={t("download")}
          className="text-dark hover:text-dark focus:text-dark"
        >
          <Icon type="16-download" size={16} useWithFillCurrentColor />
        </a>
      )}
      <ModalCloseButton onClick={modal.remove} />
    </div>
  );
};
