import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import { POST_FORM_CACHE } from "@circle-react/components/constants";
import { useCurrentPostContext } from "@circle-react/contexts";
import { usePersistFormFields } from "@circle-react/hooks/utils/usePersistFormFields";
import { Body } from "./Body";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const SCHEDULED_POST_DATE_RENDER_PORTAL_ID =
  "scheduled-post-date-render-portal";

const hasTriggerFormSubmit = (
  form: UseFormReturn<any>,
): form is UseFormReturn<any> & { triggerFormSubmit: () => void } =>
  "triggerFormSubmit" in form;

export const Content = () => {
  const { setIsDirty } = useQuickPostModalStore();
  const formContext = useFormContext();
  const {
    formState: { isDirty, isSubmitSuccessful },
  } = formContext;
  const { record: post } = useCurrentPostContext();
  const isEdit = !!post?.id;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  usePersistFormFields({
    cacheKey: POST_FORM_CACHE,
    fields: ["name", "body", "tiptap_body", "space_id", "cover_image"],
    enabled: !isEdit && !isSubmitSuccessful,
  });

  useHotkeys(
    "cmd+enter, ctrl+enter",
    () => {
      if (hasTriggerFormSubmit(formContext)) {
        formContext.triggerFormSubmit();
      }
    },
    {
      enableOnContentEditable: true,
      enableOnTags: ["INPUT", "TEXTAREA"],
    },
    [formContext],
  );

  return (
    <>
      <Header />
      <div id={SCHEDULED_POST_DATE_RENDER_PORTAL_ID} />
      <Body />
      <div id="space-select-for-mobile" />
      <div id="topics-picker-portal" />
      <Footer />
    </>
  );
};
