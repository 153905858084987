import { t } from "i18n-js";
import { SearchCategory } from "./SearchCategory";

export const CategorySelector = ({ setCategory }) => (
  <div className="mb-2 overflow-y-auto md:max-h-96">
    <ul className="list-none">
      <SearchCategory
        name={t("view_only_masquerading.categories.members")}
        icon="16-account"
        onClick={() => setCategory("members")}
      />
      <SearchCategory
        name={t("view_only_masquerading.categories.paywalls")}
        icon="credit-card"
        onClick={() => setCategory("paywalls")}
      />
      <SearchCategory
        name={t("view_only_masquerading.categories.links")}
        icon="16-link"
        onClick={() => setCategory("links")}
      />
    </ul>
  </div>
);
