import { t } from "i18n-js";
import { useMutation } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useCommentForm = ({ postId, parentCommentId, onCreate }) => {
  const { error } = useToast();

  const { mutateAsync: createComment } = useMutation(
    commentData =>
      reactQueryPost(internalApi.comments.create({ postId }), {
        comment: { parent_comment_id: parentCommentId, ...commentData },
      }),
    {
      onSuccess: onCreate,
      onError: () => error(t("comments.create.alerts.failure")),
    },
  );

  return { createComment };
};
