import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import type { Post } from "@circle-react/types/Post";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export interface PostUserInfoProps {
  post: Post;
}

/**
 * @deprecated
 * Please use the new PostUserInfo component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostUserInfo = ({ post }: PostUserInfoProps) => {
  const { community_member } = post;
  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid ?? "",
    params: { show_back_link: true },
    name: community_member.name,
  });

  return (
    <div className="post__avatar">
      <a {...profileLinkProps}>
        <div className="mr-[14px]" data-testid="post-avatar">
          <UserImage
            src={community_member.avatar_url}
            name={community_member.name ?? ""}
            size="10"
          />
        </div>
      </a>
    </div>
  );
};
