import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsCommunityAiIncludedInCurrentPlan } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export const ModalFooter = ({ plan, handleClose, onSuccess, source }: any) => {
  const upgradePlanModal = useUpgradePlanModal();
  const history = useHistory();
  const isCommunityAiIncludedInCurrentPlan =
    useIsCommunityAiIncludedInCurrentPlan();

  const onChangePlanSuccess = async () => {
    history.push(settingsNavPath.settings.community_ai);

    if (onSuccess) {
      onSuccess();
    }

    await handleClose();
  };

  const handleClick = async () => {
    if (isCommunityAiIncludedInCurrentPlan) {
      history.push(settingsNavPath.settings.community_ai);
      await handleClose();
    } else {
      await upgradePlanModal.show({
        planId: plan.id,
        onSuccess: onChangePlanSuccess,
        source,
        successButtonLabel: t(
          "settings.community_ai.marketing_modal.go_to_community_ai",
        ),
        showPlanDetails: true,
      });
    }
  };

  return (
    <Modal.Footer>
      <div>
        <Button onClick={handleClick} large full variant="circle">
          {t("settings.community_ai.marketing_modal.submit")}
        </Button>
      </div>
      {!isCommunityAiIncludedInCurrentPlan && (
        <div className="my-3 text-center">
          <Typography.BodySm color="text-default">
            <SanitizeHTMLString
              content={t(
                "settings.community_ai.marketing_modal.by_proceeding",
                {
                  plan_name: plan.display_name,
                  price: plan.monthly_base_amount_with_interval,
                },
              )}
              as="span"
            />
          </Typography.BodySm>
        </div>
      )}
    </Modal.Footer>
  );
};
