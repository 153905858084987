import classnames from "classnames";
import { t } from "i18n-js";
import { toLocaleString } from "@circle-react/helpers/number";
import { useThemeContext } from "@circle-react/providers";

interface LikeCounterProps {
  likesCount: number;
  onClick: () => void;
}

/**
 * @deprecated
 * Please use the new LikeCounter component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const LikeCounter = ({ likesCount, onClick }: LikeCounterProps) => {
  const { isDarkAppearance } = useThemeContext();

  return (
    <div className="w-full">
      <button
        type="submit"
        className={classnames("text-default float-right text-sm font-medium", {
          "text-darkest": isDarkAppearance,
        })}
        data-testid="like-count"
        onClick={onClick}
      >
        {t("comments.likes", {
          count: likesCount,
          formatted_count: toLocaleString(likesCount),
        })}
      </button>
    </div>
  );
};
