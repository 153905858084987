import { RichTextMessageBox } from "../sharedV2";
import { NewConversation } from "./NewConversation";

export interface EmptyChatRoomProps {
  userList: any[];
}

export const EmptyChatRoom = ({ userList }: EmptyChatRoomProps) => (
  <div>
    <NewConversation userList={userList} />
    <RichTextMessageBox
      userList={userList}
      isChatPreview
      portalTargetId="fullscreen-modal-body"
    />
  </div>
);
