export const hasTags = member => member.member_tags?.length > 0;

export const hasSocialLinks = member =>
  !!member.website_url ||
  !!member.facebook_url ||
  !!member.twitter_url ||
  !!member.linkedin_url ||
  !!member.instagram_url;

/**
 * Profile fields is required to know the pages where the API is being used
 * We add the param for this.
 */
export const PROFILE_PAGES = {
  SIGNUP: "signup",
  EDIT_PROFILE: "edit_profile",
  PROFILE_VIEW: "profile_view",
};
