import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isBasicPost, isImagePost } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Post } from "@circle-react/types/Post";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { PinnedToTopIcon } from "./PinnedToTopIcon";
import { PostActionsDropdownMenu } from "./PostActionsDropdownMenu";
import { PostShareOptions } from "./PostShareOptions";

export interface PostActionsProps {
  post: Post;
  onChange: (data: any) => void;
  onPostDestroy: (data: any) => void;
  refetchPosts?: () => void;
  hideShareLink?: boolean;
  hidePinnedToTopIcon?: boolean;
  hideBookmarkButton?: boolean;
}

export const PostActions = ({
  post,
  onChange,
  onPostDestroy,
  refetchPosts,
  hideShareLink,
  hidePinnedToTopIcon,
  hideBookmarkButton,
}: PostActionsProps) => {
  const { refetchPostDetails, isSharedPostView } = useCurrentPostHelpers();
  const isSmScreen = useSmScreenMediaQuery();
  const isPostImagePost = isImagePost(post);
  const isPostBasicPost = isBasicPost(post);
  const { isUsedInMinimalTipTapViewModal } = useCurrentPostContext();

  const isPost = isPostImagePost || isPostBasicPost;

  const hasBookmark = !hideBookmarkButton && !isSharedPostView && !isSmScreen;
  const hasShareLink = !hideShareLink && !isSmScreen;
  const hasPinnedToTopIcon =
    !hidePinnedToTopIcon && !isSharedPostView && !isSmScreen;
  const hasDropdownMenu = !isUsedInMinimalTipTapViewModal;

  const hasAnyAction =
    hasBookmark || hasDropdownMenu || hasShareLink || hasPinnedToTopIcon;

  if (!hasAnyAction) {
    return null;
  }

  return (
    <div
      className="post__actions-container text-dark flex items-center gap-0.5"
      data-testid="post-header-actions"
    >
      {hasBookmark && (
        <BookmarkButton
          bookmarkType={isPost ? "post" : "event"}
          record={post}
          onSuccess={refetchPostDetails}
          className={classNames("!text-default", {
            "group-hover:!text-dark": !post.bookmark_id,
          })}
        />
      )}
      {hasShareLink && <PostShareOptions onChange={onChange} />}
      {hasDropdownMenu && (
        <PostActionsDropdownMenu
          onChange={onChange}
          onPostDestroy={onPostDestroy}
          refetchPosts={refetchPosts}
        />
      )}
      {hasPinnedToTopIcon && <PinnedToTopIcon post={post} />}
    </div>
  );
};
