import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

interface UnrsvpConfirmationModalProps {
  onClose: () => void;
  isOpen?: boolean;
  toggleRsvp: () => void;
}

export const UnrsvpConfirmationModal = ({
  isOpen,
  onClose,
  toggleRsvp,
}: UnrsvpConfirmationModalProps) => {
  const onButtonClick = () => {
    toggleRsvp();
    onClose();
  };

  return (
    <Modal
      title={t("events.rsvp.unrsvp_modal.title")}
      isOpen={!!isOpen}
      onClose={onClose}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="md" className="text-center">
            {t("events.rsvp.unrsvp_modal.title")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="px-6 pb-4 pt-5 text-center">
            <Typography.BodyMd size="text-sm">
              {t("events.rsvp.unrsvp_modal.description")}
            </Typography.BodyMd>
          </div>
        </Modal.Body>
        <Modal.Footer className="space-y-2">
          <Button
            variant="circle"
            full
            large
            onClick={onButtonClick}
            className="h-10"
          >
            {t("events.rsvp.unrsvp_modal.confirm_button")}
          </Button>
          <Button
            variant="secondary"
            full
            large
            onClick={onClose}
            className="h-10"
          >
            {t("events.rsvp.unrsvp_modal.decline_button")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
