import { useRef } from "react";
import { t } from "i18n-js";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../Common/FormItem";
import { EventCoverImage } from "./EventCoverImage";
import "./styles.scss";

export const Details = () => {
  const descriptionRef = useRef();

  return (
    <>
      <Typography.TitleXl>
        {t("events.edit_modal.details.title")}
      </Typography.TitleXl>
      <div className="my-8">
        <Typography.TitleMd>
          {t("events.edit_modal.details.cover_image")}
        </Typography.TitleMd>
        <EventCoverImage />
      </div>

      <div className="mb-8 mt-6 md:mt-12">
        <Typography.TitleMd>
          {t("events.edit_modal.details.description")}
        </Typography.TitleMd>
        <FormItem name="body" hideLabel>
          <div className="trix-v2 event-description-wrapper trix-v2--modal-3 mt-2">
            <RichTextInput
              placeholder={t(
                "events.edit_modal.details.description_placeholder",
              )}
              id="event-details-body"
              ref={descriptionRef}
              name="body"
            />
          </div>
        </FormItem>
      </div>
    </>
  );
};
