import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ModalManager } from "@circle-react/components/Layout/ModalManagerProvider";
import { usePunditUserContext } from "@circle-react/contexts";
import { EditMasqueradingSearchModal } from "./EditMasqueradingSearchModal";
import { SearchComponent } from "./SearchComponent";
import { ViewMasqueradingSearchModal } from "./ViewMasqueradingSearchModal";

const EditMasqueradingDrawer = ModalManager.create(() => (
  <EditMasqueradingSearchModal />
));

const ViewMasqueradingDrawer = ModalManager.create(() => {
  const { currentCommunitySettings } = usePunditUserContext();

  if (currentCommunitySettings?.masquerading_entities_enabled) {
    return <ViewMasqueradingSearchModal />;
  }
  return <SearchComponent />;
});

export const useViewMasqueradingSearch = () => useModal(ViewMasqueradingDrawer);

export const useEditMasqueradingSearch = () => useModal(EditMasqueradingDrawer);
