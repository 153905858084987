import type { MouseEvent } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { find } from "lodash";
import { useFormContext } from "react-hook-form";
import { DatePicker } from "@/react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import { ScheduleDate } from "@/react/components/QuickPost/ModalForm/SubmitButton/ScheduleDate";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { ActionSelectorButton } from "@circle-react-uikit/ActionSelectorButton";
import type { Option } from "@circle-react-uikit/ActionSelectorButton";

export interface SchedulePostUIProps {
  submitDisabled?: boolean;
  datepickerPlacement?: "top" | "bottom";
  isSubmitting?: boolean;
}

export const SchedulePostUI = ({
  submitDisabled = false,
  isSubmitting = false,
  datepickerPlacement = "top",
}: SchedulePostUIProps) => {
  const getLabelForAction = (action: string) => {
    const translationKey = ["quick_post", "scheduled_post_ui"];
    translationKey.push(isSubmitting ? "submitting_button" : "submit_button");
    translationKey.push(action);
    return t(translationKey.join("."));
  };

  const DRAFT_OPTION: Option = {
    icon: "16-download",
    label: getLabelForAction("save_draft"),
    value: "draft",
  };
  const SCHEDULED_OPTION: Option = {
    icon: "16-calendar",
    label: getLabelForAction("schedule_post"),
    value: "scheduled",
  };
  const PUBLISHED_OPTION: Option = {
    icon: "16-publish",
    label: getLabelForAction("publish_post"),
    value: "published",
  };

  const options: Option[] = [DRAFT_OPTION, SCHEDULED_OPTION, PUBLISHED_OPTION];

  const { watch, setValue } = useFormContext();
  const { published_at: publishedAt, status } = watch();
  const [isDatePickerVisible, , setPickerVisibility] = useBoolean(false);

  const selectedAction = find(options, { value: status });

  const updatePublishedAt = (date: string | null) => {
    setValue("published_at", date);
  };

  const _setSelectedAction = (action?: Option) => {
    // TODO: ADD validation for schedule date and possbile status change here.
    if (action?.value === SCHEDULED_OPTION.value) {
      setPickerVisibility(true);
    } else {
      setValue("status", action?.value);
      setPickerVisibility(false);
      updatePublishedAt(null);
    }
  };

  const onScheduleDateChange = (date: string | null) => {
    updatePublishedAt(date);
    setValue("status", SCHEDULED_OPTION.value);
    setPickerVisibility(false);
  };

  const onPickerCancelClick = (event: MouseEvent<HTMLButtonElement>) => {
    event && event.preventDefault();
    setPickerVisibility(false);
    if (status === SCHEDULED_OPTION.value) {
      revertToDraftState();
    }
  };

  const revertToDraftState = () => {
    _setSelectedAction(DRAFT_OPTION);
  };

  return (
    <>
      <ScheduleDate
        publishedAt={publishedAt}
        updatePublishedAt={updatePublishedAt}
        status={status}
        revertToDraftState={revertToDraftState}
      />
      <DatePicker
        datePickerVisible={isDatePickerVisible}
        onCancel={onPickerCancelClick}
        value={publishedAt}
        onSave={onScheduleDateChange}
        className={classNames({
          "-bottom-11": datepickerPlacement === "top",
          "-top-7": datepickerPlacement === "bottom",
        })}
      >
        {isDatePickerVisible ? (
          <span />
        ) : (
          <ActionSelectorButton
            type="submit"
            disabled={submitDisabled}
            selectedAction={selectedAction}
            setSelectedAction={_setSelectedAction}
            options={options}
          />
        )}
      </DatePicker>
    </>
  );
};
