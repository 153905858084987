import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface Member {
  name: string;
  avatar_url: string;
  public_uid: string;
}

interface MemberRowProps {
  member: Member;
  hideModal: () => void;
}

/**
 * @deprecated
 * Please use the new MemberRow component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const MemberRow = ({ member, hideModal }: MemberRowProps) => {
  const { name, avatar_url: avatarUrl, public_uid: memberPublicId } = member;
  const {
    onClick,
    "aria-label": ariaLabel,
    href,
    rel,
    target,
  } = useProfileLinkProps({
    public_uid: memberPublicId,
  });

  return (
    <a
      className="flex items-center gap-3"
      onClick={event => {
        hideModal();
        onClick && onClick(event);
      }}
      aria-label={ariaLabel}
      href={href}
      rel={rel}
      target={target}
    >
      <div className="mt-0">
        <UserImage name={name} src={avatarUrl} size="10" />
      </div>
      <Typography.LabelSm weight="medium">{name}</Typography.LabelSm>
    </a>
  );
};
