import { t } from "i18n-js";
import { isFunction, noop } from "lodash";
import { useMutation } from "react-query";
import { postApi } from "@circle-react/api";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

export const FeaturedAreaVisibilityAction = ({
  post,
  handleClose = noop,
  onChange,
  refetchPosts,
}) => {
  const hiddenFromFeaturedAreas = post.hide_from_featured_areas;
  const { success, error } = useToast();

  const toggleVisibility = useMutation(
    () =>
      hiddenFromFeaturedAreas
        ? postApi.showInFeaturedAreas({ post })
        : postApi.hideFromFeaturedAreas({ post }),
    {
      onSuccess: async post => {
        onChange(post, { refetch: false });
        if (isFunction(refetchPosts)) {
          await refetchPosts();
        }
        success(
          I18n.t(
            hiddenFromFeaturedAreas
              ? "post.marked_unhidden"
              : "post.marked_hidden",
          ),
        );
      },
      onError: () => {
        error(I18n.t("something_went_wrong"));
      },
    },
  );

  const handleClick = () => {
    handleClose();
    toggleVisibility.mutate();
  };

  return (
    <Dropdown.ItemLink onClick={handleClick}>
      <ToggleSwitchWithLabel
        label={t("post.hide_from_featured_areas")}
        field="hide_from_featured_areas"
        value={hiddenFromFeaturedAreas}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
