import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { CopilotItem } from "./CopilotItem";
import { customTextColorClass } from "./constants";

export const CopilotFeature = () => {
  const localeScope = "settings.community_ai.marketing_modal";

  const firstRowTags = ["give_me_ideas", "translate", "generate_a_paragraph"];
  const secondRowTags = ["improve_writing", "summarize", "adjust_spelling"];
  return (
    <div className="border-primary rounded border p-5 pb-0">
      <div className="mb-1">
        <Typography.LabelLg weight="bold" color="text-default">
          {t(`${localeScope}.copilot.title`)}
        </Typography.LabelLg>
      </div>
      <Typography.BodySm color="text-default">
        {t(`${localeScope}.copilot.description`)}
      </Typography.BodySm>
      <div className="relative mt-4 grid grid-cols-2 gap-1 p-4">
        <div className="absolute inset-0 z-[-1] rounded-t-lg bg-gradient-to-r from-indigo-600 via-purple-600 to-indigo-600 opacity-5"></div>
        <div className="flex flex-row">
          <Icon type="16-artificial-intelligence" className="text-blue-100" />
          <Typography.LabelXs weight="semibold" color={customTextColorClass}>
            {t(`${localeScope}.ask_ai_to`)}
          </Typography.LabelXs>
        </div>

        <div className="col-span-2 flex justify-around">
          {firstRowTags.map(item => (
            <CopilotItem key={item} localeItem={item} />
          ))}
        </div>

        <div className="col-span-2 mt-2 flex justify-around">
          {secondRowTags.map(item => (
            <CopilotItem key={item} localeItem={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
