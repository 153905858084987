import classNames from "classnames";
import { t } from "i18n-js";
import { isNumber } from "lodash";
import { useLevelColors } from "@circle-react/components/Leaderboard/colorHelper";
import { toLocaleString } from "@circle-react/helpers/number";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PointsInfoLevelItem = ({
  level,
  levelName,
  pointsRequired,
}: {
  level: number;
  levelName: string;
  pointsRequired: number;
}) => {
  const levelColors = useLevelColors(level);
  return (
    <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0">
      <div>
        <Typography.LabelSm weight="semibold">
          <div className={classNames(levelColors.text)}>
            {levelName ||
              t("leaderboard.level_n", {
                level,
              })}
          </div>
        </Typography.LabelSm>
        {isNumber(pointsRequired) && (
          <Typography.LabelXs weight="regular">
            <div className="text-default">
              {t("leaderboard.n_points_required", {
                count: toLocaleString(pointsRequired),
              })}
            </div>
          </Typography.LabelXs>
        )}
      </div>
    </div>
  );
};
