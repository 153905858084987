import classnames from "classnames";
import { Counter } from "@circle-react/components/Events/Counter";
import { ShowPageLink } from "@circle-react/components/PostsV2/PostViewListItem/ShowPageLink";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";

export const PostName = () => {
  const { post, canManagePost } = useCurrentPostHelpers();
  if (!post.name) {
    return null;
  }
  const { event_setting_attributes: eventData } = post || {};
  const { starts_at: startsAt, ends_at: endsAt } = eventData || {};

  const classNames = classnames("post__header", {
    "post-meta--hidden": post.hide_meta_info,
  });

  return (
    <div className={classNames}>
      {startsAt && endsAt && (
        <Counter startsAt={startsAt} endsAt={endsAt} status={post?.status} />
      )}
      <h1
        className={classnames("post__title text-2xl", {
          "with-admin-actions": canManagePost,
        })}
      >
        <ShowPageLink post={post}>
          {post.name}
          {post.flagged_for_approval_at && (
            <span className="post__headline-tag">{I18n.t("in_review")}</span>
          )}
        </ShowPageLink>
      </h1>
    </div>
  );
};
