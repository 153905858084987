import linkifyHtml from "linkifyjs/html";

export const linkifyTrixContent = content =>
  linkifyHtml(content, {
    defaultProtocol: "https",
    target: {
      url: "_blank",
    },
    rel: {
      url: "noreferrer",
    },
    ignoreTags: [
      "a",
      "style",
      "script",
      "img",
      "iframe",
      "action-text-attachment",
      "video",
      "audio",
    ],
    validate: {
      url: value => /^[^A-Z]/.test(value),
    },
  });

export const setNoViewDownload = trixContentElement => {
  trixContentElement.querySelectorAll("video").forEach(videoElement => {
    videoElement.setAttribute("controlsList", "nodownload");
  });
};

export const makeAllLinksOpenInNewWindow = ({
  containerElement,
  shouldIncludeInternalLinks = false,
}) => {
  containerElement.querySelectorAll("a").forEach(element => {
    if (
      element.tagName === "A" &&
      !element.isContentEditable &&
      (shouldIncludeInternalLinks || element.host !== window.location.host)
    ) {
      element.setAttribute("target", "_blank");
      element.setAttribute("rel", "noreferrer");
    }
  });
};

export const transformDataBackgroundImage = trixContentElement => {
  trixContentElement.querySelectorAll("[data-background-image]").forEach(e => {
    const image = document.createElement("img");
    image.src = e.dataset.backgroundImage;
    e.style.backgroundImage = `url("${e.dataset.backgroundImage}")`;
    image.onload = function () {
      const { parentElement } = e;
      parentElement.classList.add("show");
      parentElement.classList.remove("hide");
    };
  });
};

export function setupAttachmentDownload(trixContentElement) {
  trixContentElement
    .querySelectorAll("[data-target~='downloadAttachment']")
    .forEach(element => {
      element.addEventListener("click", downloadAttachment);
    });
}

export function downloadAttachment(event) {
  event.preventDefault();
  event.stopPropagation();
  const {
    currentTarget: {
      dataset: { url, fileName, logUrl },
    },
  } = event;
  download(url, fileName);
  trackDownload(logUrl);
}

function download(url, filename) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.setAttribute("download", filename);
  a.click();
}

function trackDownload(url) {
  fetch(url, { method: "POST" });
}
