import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { EventDropdownActions } from "./EventDropdownActions";
import { PostDropdownActions } from "./PostDropdownActions";

export const NewPostActionsDropdown = ({
  onChange,
  onPostDestroy,
  refetchPosts,
  currentCommunity,
}) => {
  const { isBasicPost, isEventPost, isImagePost } = useCurrentPostHelpers();
  return (
    <>
      {(isBasicPost || isImagePost) && (
        <PostDropdownActions
          onChange={onChange}
          onPostDestroy={onPostDestroy}
          refetchPosts={refetchPosts}
          currentCommunity={currentCommunity}
        />
      )}
      {isEventPost && (
        <EventDropdownActions
          onChange={onChange}
          onPostDestroy={onPostDestroy}
        />
      )}
    </>
  );
};
