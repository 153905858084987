import PropTypes from "prop-types";
import classnames from "classnames";
import { Rsvp } from "@circle-react/components/Events/Rsvp";
import { useCurrentPostContext } from "@circle-react/contexts";
import "./styles.scss";

export const Sidebar = ({ onChange, insidePost = false }) => {
  const { isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  return (
    <section
      className={classnames("right-sidebar-container", {
        "right-sidebar-container--insidePost": insidePost,
        "!block": isUsedInMinimalTipTapViewModal,
      })}
    >
      <Rsvp onChange={onChange} />
    </section>
  );
};

// TODO: Properly define prop types
Sidebar.propTypes = {
  event: PropTypes.any,
};
