import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { useThemeContext } from "@/react/providers";
import { shouldRenderLockIcon } from "@circle-react/components/Spaces/utils";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePunditUserContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { viewSpacePath } from "@circle-react/helpers/urlHelpers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface SpaceProps {
  onClose: () => void;
  space: {
    emoji: string;
    custom_emoji_url: string;
    custom_emoji_dark_url: string;
    hide_members_count: boolean;
    name: string;
    slug: string;
    member_count: number;
    is_private: boolean;
    show_lock_icon_for_non_members: boolean;
    is_space_member: boolean;
  };
}

export const Space = ({
  onClose,
  space: {
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
    hide_members_count: shouldHideMembersCount,
    name,
    slug,
    member_count,
    is_private,
    show_lock_icon_for_non_members,
    is_space_member,
  },
}: SpaceProps) => {
  const { currentAppearance } = useThemeContext();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const shouldUseLockIcon = shouldRenderLockIcon({
    shouldShowLockIconForNonMembers:
      show_lock_icon_for_non_members && !is_space_member,
    isPrivate: is_private,
    isAdmin,
  });

  return (
    <Link
      to={viewSpacePath({ spaceSlug: slug })}
      onClick={e => (isIntendedOnNewTab(e) ? false : onClose())}
      className="hover:bg-secondary bg-primary flex items-center space-x-3 rounded-md px-4 py-3.5"
    >
      <EmojiRenderer
        wrapperClassName="profile-spaces__emoji self-start"
        className="gated-or-not with-emoji"
        emoji={emoji}
        customEmojiUrl={customEmojiUrl}
        customEmojiDarkUrl={customEmojiDarkUrl}
        appearance={currentAppearance}
        shouldUseLockIcon={shouldUseLockIcon}
        lockIcon={
          <span className="gated-or-not">
            <Icon type="lock" useWithFillCurrentColor className="text-dark" />
          </span>
        }
      />
      <div className="flex flex-col space-y-1.5">
        <span className="text-darkest leading-none">
          <Typography.LabelMd weight="semibold">{name}</Typography.LabelMd>
        </span>
        {!shouldHideMembersCount && (
          <span className="text-light leading-none">
            <Typography.LabelSm>
              {t("profiles.members", { count: member_count })}
            </Typography.LabelSm>
          </span>
        )}
      </div>
    </Link>
  );
};
