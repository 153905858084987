import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import type { CurrentPostContextObject } from "@circle-react/contexts";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { isPublicCommunity } from "@circle-react/helpers/communityHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import * as postHelpers from "@circle-react/helpers/postHelpers";
import { canSharePost } from "@circle-react/helpers/postHelpers";
import {
  isPublicSpace,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import type { Post } from "@circle-react/types/Post";

interface Options {
  refetch: false;
}

export interface UseCurrentPostHelpersProps {
  onChangeSharePost?: (post: Post, options: Options) => void;
}

interface Params {
  postSlug: string;
}

type MutationError = unknown;

interface MutationVariables {
  postId: string;
  body: any;
}

type PostContextObject = Omit<CurrentPostContextObject, "record">;

export interface UseCurrentPostHelpers extends PostContextObject {
  post: any;
  canManagePost: boolean;
  canDuplicatePost: boolean;
  canDestroyPost: boolean;
  canUpdatePost: boolean;
  canReportPost: boolean;
  isSharedPostView: boolean;
  showPostActions: boolean;
  showEngagementActions: boolean;
  showGatedCommentSection: boolean;
  showGatedCommentSectionAtTop: boolean;
  showGatedCommentSectionAtBottom: boolean;
  shouldShowLeaveCommentButton: boolean;
  showModerationInActionsDropdown: boolean;
  isBasicPost: boolean;
  isEventPost: boolean;
  isImagePost: boolean;
  showSpaceName: boolean;
  isSharedPostViewWithMinimalLayout: boolean;
  isSharedPostWithTeaserLayout: boolean;
  isTiptapPost: boolean;
  isTrixPost: boolean;
  getSharePostLink: any;
  sharePostData: any;
  isLoadingSharePostData: boolean;
  showShareOnWebOptions: (space: any) => boolean;
  shouldHideCommentsListForSpace: ({ space }: { space: any }) => boolean;
  isPostBodyEmpty: boolean;
  isPostMetaHidden: boolean;
}

/**
 * @deprecated
 * Please use the new useCurrentPostHelpers hook from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useCurrentPostHelpers = ({
  onChangeSharePost,
}: UseCurrentPostHelpersProps = {}): UseCurrentPostHelpers => {
  const { postSlug } = useParams<Params>();
  const isIframeEmbed = useIsIframeEmbed();
  const { record: post, ...rest } = useCurrentPostContext();

  const { currentUser, currentCommunity, currentCommunityMember } =
    usePunditUserContext();
  const isSharedPostView = postHelpers.isSharedPostView(post, {
    postSlugInUrl: postSlug,
  });
  const isSharedPostViewWithMinimalLayout =
    isSharedPostView && post.minimal_layout;
  const isSharedPostWithTeaserLayout =
    isSharedPostView && post.show_post_teaser;
  const shouldShowPostActions =
    !isIframeEmbed &&
    postHelpers.showPostActions(post, {
      currentUser,
      postSlugInUrl: postSlug,
    });
  const canManagePost = postHelpers.canManagePost(post);
  const canUpdatePost = postHelpers.canUpdatePost(post);
  const canDuplicatePost = postHelpers.canDuplicatePost(post);
  const canDestroyPost = postHelpers.canDestroyPost(post);
  const canReportPost = postHelpers.canReportPost(post);
  const showShareOnWebOptions = (space: any) =>
    !isSharedPostView &&
    !isIframeEmbed &&
    canSharePost(post, currentCommunity, space);
  const showSpaceName = isSharedPostViewWithMinimalLayout;
  const showGatedCommentSection =
    (!currentUser && post.is_comments_enabled && !post.is_comments_closed) ||
    isSharedPostWithTeaserLayout;
  const showGatedCommentSectionAtTop =
    showGatedCommentSection && post.comments_count > 5;
  const showGatedCommentSectionAtBottom =
    showGatedCommentSection && post.comments_count <= 5;
  const showModerationInActionsDropdown =
    post.flagged_for_approval_at && canManagePost;
  const isBasicPost = postHelpers.isBasicPost(post);
  const isEventPost = postHelpers.isEventPost(post);
  const isImagePost = postHelpers.isImagePost(post);
  const isTiptapPost = postHelpers.isUsingTiptapEditor(post);
  const isTrixPost = postHelpers.isUsingTrixEditor(post);
  const isPostBodyEmpty = postHelpers.isPostBodyEmpty(post);
  const isPostMetaHidden = postHelpers.isPostMetaHidden(post);

  const showEngagementActions =
    !isSharedPostViewWithMinimalLayout &&
    (post.is_liking_enabled || post.is_comments_enabled);
  const shouldShowLeaveCommentButton =
    isSharedPostViewWithMinimalLayout && currentCommunityMember;

  const shouldHideCommentsListForSpace = ({ space }: any) => {
    const canAccessCommunity =
      isPublicCommunity(currentCommunity) || currentCommunityMember;
    const canAccessSpace =
      isPublicSpace(space) ||
      isSpaceMember(space) ||
      isCommunityAdmin(currentCommunityMember);
    const canAccessPost = canAccessCommunity && canAccessSpace;

    return (
      !canAccessPost ||
      isSharedPostViewWithMinimalLayout ||
      isSharedPostWithTeaserLayout
    );
  };

  const {
    mutate: getSharePostLink,
    data: sharePostData,
    isLoading: isLoadingSharePostData,
  } = useMutation<Post, MutationError, MutationVariables>(
    ({ postId, body }) => reactQueryPut(`/posts/${postId}/share`, body),
    {
      onSuccess: data => {
        if (onChangeSharePost) {
          onChangeSharePost(
            {
              ...post,
              ...data,
            },
            { refetch: false },
          );
        }
      },
    },
  );

  return {
    post,
    ...rest,
    canManagePost,
    canDuplicatePost,
    canDestroyPost,
    canUpdatePost,
    canReportPost,
    isSharedPostView,
    showPostActions: shouldShowPostActions,
    showEngagementActions,
    showGatedCommentSection,
    showGatedCommentSectionAtTop,
    showGatedCommentSectionAtBottom,
    showModerationInActionsDropdown,
    isBasicPost,
    isEventPost,
    isImagePost,
    showSpaceName,
    isSharedPostViewWithMinimalLayout,
    isSharedPostWithTeaserLayout,
    isTiptapPost,
    isTrixPost,
    getSharePostLink,
    sharePostData,
    isLoadingSharePostData,
    showShareOnWebOptions,
    shouldHideCommentsListForSpace,
    isPostBodyEmpty,
    isPostMetaHidden,
    shouldShowLeaveCommentButton,
  };
};
