import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { Attendees } from "./Attendees";
import { MetaTags } from "./MetaTags";
import { Permissions } from "./Permissions";

export const AdvancedOptions = ({ event }) => (
  <>
    <Typography.TitleXl>
      {t("events.edit_modal.advanced_options.title")}
    </Typography.TitleXl>
    <Permissions />
    <Attendees event={event} />
    <MetaTags />
  </>
);
