import { ProfileInfo } from "@circle-react/components/ProfileModalV3/Body/About/Info";
import { GamificationInfo } from "@circle-react/components/ProfileModalV3/Sidebar/GamificationInfo";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";

export const ProfileAbout = () => {
  const { member } = useProfileContext();

  return (
    <>
      <GamificationInfo />
      <ProfileInfo member={member} />
    </>
  );
};
