import { useEffect } from "react";
import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useChatHeaderApi } from "@/react/hooks/chats/useChatHeaderApi";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";

export const useChatNotification = ({ community } = {}) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();

  const communityMemberId = community
    ? community.community_member_id
    : currentCommunityMember?.id;
  const communityId = community ? community.id : currentCommunity?.id;

  const {
    unreadChatRoomUuids,
    onEventReceive,
    setUnreadChatRoomUuids,
    unreadChatRoomUuidsRef,
    newUnreadChatRoomBatch,
    newUnreadChatRoomBatchRef,
  } = useChatHeaderApi({ communityId });

  const fetchRequest = () =>
    reactQueryGet(
      internalApi.chatRooms.unreadChatRooms({
        params: {
          community_id: communityId,
        },
      }),
    );

  useQuery(
    ["UNREAD_CHAT_ROOMS_COUNT", communityId, communityMemberId],
    fetchRequest,
    {
      enabled: Boolean(communityId && communityMemberId),
      onSuccess: data => {
        if (data.chat_room_uuids) {
          setUnreadChatRoomUuids(communityId, data.chat_room_uuids);
        }
      },
    },
  );

  useWebSocket({
    channel: "ChatRoomChannel",
    onMessageReceive: data => {
      onEventReceive(unreadChatRoomUuidsRef.current, data, communityMemberId);
    },
    community_member_id: communityMemberId,
    canCreateConnection: Boolean(communityId && communityMemberId),
  });

  //componentWillUpdate
  useEffect(() => {
    unreadChatRoomUuidsRef.current = unreadChatRoomUuids;
  }, [unreadChatRoomUuids]);

  //componentWillUpdate
  useEffect(() => {
    newUnreadChatRoomBatchRef.current = newUnreadChatRoomBatch;
  }, [newUnreadChatRoomBatch]);

  const unreadChatCount = unreadChatRoomUuids.length;

  return { unreadChatCount, unreadChatRoomUuids };
};
