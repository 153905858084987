import { t } from "i18n-js";
import { toLocaleString } from "@circle-react/helpers/number";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

interface LikedByMessageProps {
  firstCommunityMemberToLike: any;
  secondCommunityMemberToLike: any;
  likesCount: number;
  isLoading: boolean;
}

export const LikedByMessage = ({
  firstCommunityMemberToLike,
  secondCommunityMemberToLike,
  likesCount,
  isLoading,
}: LikedByMessageProps) => {
  if (isLoading) {
    return <SkeletonLoader variant="row" lg extraLong />;
  }

  if (!firstCommunityMemberToLike) {
    return null;
  }

  const name1 = firstCommunityMemberToLike?.first_name || "";
  const name2 = secondCommunityMemberToLike?.first_name || "";

  let likedByMessage = "";

  if (likesCount === 1) {
    likedByMessage = t("liked_by_one", {
      name: name1,
    });
  } else if (likesCount === 2) {
    likedByMessage = t("liked_by_two", {
      first: name1,
      second: name2,
    });
  } else if (likesCount > 2) {
    likedByMessage = t("liked_by_three_or_more", {
      name: name1,
      amount: toLocaleString(likesCount - 1),
    });
  }

  return <span className="overflow-anywhere">{likedByMessage}</span>;
};
