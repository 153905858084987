import { useEffect, useRef, useState } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { isNull, isUndefined } from "lodash";
import {
  useCurrentCommunityMember,
  usePunditUserContext,
} from "@/react/contexts";
import { useUpdateMissingParticipants } from "@/react/hooks/chatsV2/useUpdateMissingParticipants";
import { useTypingWebSocket } from "@circle-react/hooks/chatsV2/useTypingWebSocket";

const STOP_TYPING_EVENT_DELAY = 3000;
const INACTIVITY_TIMEOUT = 1000 * 30; //30 seconds
const SEND_BUTTON_ID = "tiptap-send";

export interface UseTypingIndicatorProps {
  enabled: boolean;
  chatRoomUuid: string;
  currentParticipant?: any;
  parentMessageId?: number;
  participants?: any[];
  editorRef: RefObject<Editor | null>;
  messageBoxId: string;
}

export interface ChannelMessage {
  event: string;
  chat_room_participant_id: number;
  chat_room_participant_name: string;
  parent_message_id: number;
}

export const useTypingIndicator = ({
  enabled,
  chatRoomUuid,
  currentParticipant,
  parentMessageId,
  editorRef,
  participants = [],
  messageBoxId,
}: UseTypingIndicatorProps) => {
  const [typingParticipantIds, setTypingParticipantIds] = useState(new Set());
  const fallbackTimersRef = useRef(new Map<number, any>());
  const currentCommunityMember = useCurrentCommunityMember();
  const { currentCommunity } = usePunditUserContext();
  const typingSentRef = useRef(false);
  const isThread = !!parentMessageId;

  const onWebsocketMessageReceive = (message: ChannelMessage) => {
    const isMatchingThread =
      (!isUndefined(parentMessageId) &&
        parentMessageId === message.parent_message_id) ||
      (isUndefined(parentMessageId) && isNull(message.parent_message_id));

    setTypingParticipantIds(prevtypingParticipantIds => {
      const updatedTypingParticipantIds = new Set(prevtypingParticipantIds);

      if (
        isMatchingThread &&
        message.chat_room_participant_id !== currentParticipant.id
      ) {
        const participantId = message.chat_room_participant_id;

        if (message.event === "typing") {
          setTimerForParticipant(participantId);
          updatedTypingParticipantIds.add(message.chat_room_participant_id);
        } else if (message.event === "stopTyping") {
          clearTimerForParticipant(participantId);
          updatedTypingParticipantIds.delete(message.chat_room_participant_id);
        }
      }

      return updatedTypingParticipantIds;
    });
  };

  const setTimerForParticipant = (participantId: number) => {
    // Clear any existing timeout for this participant
    if (fallbackTimersRef.current.has(participantId)) {
      clearTimeout(fallbackTimersRef.current.get(participantId));
    }
    // Set a new timeout for the participant
    const timeout: any = setTimeout(
      () => removeParticipantId(participantId),
      INACTIVITY_TIMEOUT,
    );
    fallbackTimersRef.current.set(participantId, timeout); // Store the timer in the map
  };

  const clearTimerForParticipant = (participantId: number) => {
    // Clear the timer and remove participant
    if (fallbackTimersRef.current.has(participantId)) {
      clearTimeout(fallbackTimersRef.current.get(participantId));
      fallbackTimersRef.current.delete(participantId); // Remove the timeout from the map
    }
  };

  const removeParticipantId = (participantId: number) => {
    clearTimerForParticipant(participantId);
    setTypingParticipantIds(prevtypingParticipantIds => {
      const updatedTypingParticipantIds = new Set(prevtypingParticipantIds);
      updatedTypingParticipantIds.delete(participantId);
      return updatedTypingParticipantIds;
    });
  };

  const { subscription } = useTypingWebSocket({
    enabled,
    chatRoomUuid,
    isThread,
    currentCommunityMember,
    onMessageReceive: onWebsocketMessageReceive,
  });

  const handleStartTyping = () => {
    if (!typingSentRef.current) {
      subscription.perform("typing", {
        community_id: currentCommunity.id,
        chat_room_uuid: chatRoomUuid,
        community_member_id: currentCommunityMember?.id,
        parent_message_id: parentMessageId,
      });

      typingSentRef.current = true;
    }
  };

  const handleStopTyping = () => {
    if (typingSentRef.current) {
      sendStopTyping();
    }
  };

  const sendStopTyping = () => {
    subscription.perform("stop_typing", {
      community_id: currentCommunity.id,
      chat_room_uuid: chatRoomUuid,
      community_member_id: currentCommunityMember?.id,
      parent_message_id: parentMessageId,
    });

    typingSentRef.current = false;
  };

  useEffect(() => {
    const editor = editorRef.current;

    if (enabled && editor) {
      editor.on("update", ({ transaction }) => {
        if (transaction?.docChanged) {
          handleStartTyping();
        }
      });
    }

    return () => {
      if (enabled && editor) {
        editor.off("update");
      }
    };
  }, [editorRef?.current]);

  let typingTimer: any; // Timer identifier
  const messageBox = document.getElementById(messageBoxId);
  messageBox?.addEventListener("keyup", event => {
    if (typingTimer) clearTimeout(typingTimer);
    if (event?.key === "Enter") {
      handleStopTyping();
    }
    typingTimer = setTimeout(handleStopTyping, STOP_TYPING_EVENT_DELAY);
  });

  const sendButton = document.getElementById(SEND_BUTTON_ID);
  sendButton?.addEventListener("click", () => {
    if (typingTimer) clearTimeout(typingTimer);
    handleStopTyping();
  });

  const missingParticipantIds = Array.from(typingParticipantIds).filter(
    typingId => !participants.some(participant => participant.id === typingId),
  );

  const typingUserNames = participants
    .filter(participant => typingParticipantIds.has(participant.id))
    .map(participant => participant.name);

  useUpdateMissingParticipants({
    uuid: chatRoomUuid,
    ids: missingParticipantIds,
    enabled: missingParticipantIds.length > 0,
  });

  return {
    typingUserNames,
    isTyping: typingUserNames.length > 0,
  };
};
