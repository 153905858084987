import classNames from "classnames";
import { t } from "i18n-js";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PinnedToTopIcon = ({ post, mobileWrapperClassName = "" }) => {
  const isSmScreen = useSmScreenMediaQuery();
  if (!post.pinned_at_top_of_space) {
    return null;
  }

  if (isSmScreen) {
    return (
      <div
        className={classNames(
          "mx-5 mt-5 flex items-center gap-2",
          mobileWrapperClassName,
        )}
      >
        <Icon size={20} type="20-pinned-post" className="text-dark" />
        <Typography.LabelXxs weight="font-semibold">
          {t("post.pinned_post")}
        </Typography.LabelXxs>
      </div>
    );
  }

  return (
    <div className="post__pin post__pin--pinned inline-flex p-1">
      <Icon
        type="20-pin-post"
        className="text-default !h-5 !w-5"
        size={20}
        useWithStrokeCurrentColor
      />
    </div>
  );
};
