import { ThreeUserRow } from "@circleco/ui-kit";
import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { LikedByMessage } from "./LikedByMessage";
import { usePostLikesModal } from "./PostLikesModal";

/**
 * @deprecated
 * Please use the new PostLikedByMemberStack component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostLikedByMemberStack = () => {
  const { post, isPostBodyEmpty } = useCurrentPostHelpers();
  const { isV3Enabled } = useIsV3();

  const {
    user_likes_count: likesCount,
    is_liking_enabled: isLikingEnabled,
    sample_user_likes_community_members: communityMembersThatLiked = [],
    post_details_loaded: hasPostDetailsLoaded,
  } = post;
  const postLikesModal = usePostLikesModal();

  if (!hasPostDetailsLoaded) {
    return (
      <div
        className={classNames("post__engagement", {
          "!pt-5": isImagePost(post) && isPostBodyEmpty,
        })}
      >
        <SkeletonLoader variant="members-avatar-row" />
      </div>
    );
  }

  if (!isLikingEnabled || !likesCount || !communityMembersThatLiked.length) {
    return null;
  }

  const transformUserRowData = () =>
    communityMembersThatLiked.slice(0, 3).map((user: any) => ({
      src: user.avatar_url,
      name: user.name,
    }));

  return (
    <button
      type="button"
      onClick={() => postLikesModal.show({ post })}
      className={classNames("post__engagement", {
        "!pt-5": isImagePost(post) && isPostBodyEmpty,
      })}
    >
      <div className="engagement__likes">
        <div className="engagement__members border-tertiary">
          <ThreeUserRow
            users={transformUserRowData()}
            isFullRounded={isV3Enabled}
          />
        </div>
        <div
          className="engagement__members--descriptor"
          data-testid="post-engagement"
        >
          <LikedByMessage
            firstCommunityMemberToLike={communityMembersThatLiked[0]}
            secondCommunityMemberToLike={communityMembersThatLiked[1]}
            likesCount={likesCount}
            isLoading={!hasPostDetailsLoaded}
          />
        </div>
      </div>
    </button>
  );
};
