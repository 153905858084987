/* eslint-disable react-hooks/rules-of-hooks
 -- Disabled to set CI to fail on this issue on new files, PR #5195 */
import { useCallback, useEffect, useRef, useState } from "react";
import type { Editor as TipTapEditor } from "@tiptap/react";
import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { Carousel } from "@/react/components/Spaces/ImageSpace/FeedView/Carousel";
import { Sidebar } from "@circle-react/components/Events/Sidebar";
import { CommentsSection } from "@circle-react/components/PostsV3/PostViewListItem/CommentsSection";
import { EngagementActions } from "@circle-react/components/PostsV3/PostViewListItem/EngagementActions";
import { PinnedToTopIcon } from "@circle-react/components/PostsV3/PostViewListItem/PinnedToTopIcon";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import {
  isBasicPost,
  isEventPost,
  isImagePost,
} from "@circle-react/helpers/postHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { TrixEditorRefObject as TrixEditor } from "@circle-react-shared/TrixEditor";
import { isTrixEditorRefObject } from "@circle-react-shared/TrixEditor";
import { TranscriptsPlayer } from "@circle-react-uikit/TranscriptsPlayer";
import { MinimalPostBody } from "./MinimalPostBody";
import { PostBody } from "./PostBody";
import { PostContentWrapper } from "./PostContentWrapper";
import { PostCoverImage } from "./PostCoverImage";
import { PostHeader } from "./PostHeader";

export interface MainContentProps {
  autofocusReplyForm?: boolean;
  className?: string;
  defaultShowComment?: boolean;
  hideAuthorHeadline?: boolean;
  hideBookmarkButton?: boolean;
  hidePinnedToTopIcon?: boolean;
  hideShareLink?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  metaInfoWrapperClassName?: string;
  onPostChange: (post: any, options?: any) => void;
  onPostDestroy: (post: any) => void;
  pageSupportsContentTruncation?: boolean;
  refetchPosts?: () => void;
  shouldOpenCommentPage?: boolean;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  shouldForceSamePageInteractions?: boolean;
}

export const MainContent = ({
  autofocusReplyForm = false,
  className,
  defaultShowComment = false,
  hideAuthorHeadline = false,
  hideBookmarkButton = false,
  hidePinnedToTopIcon = false,
  hideShareLink = false,
  isRenderedOutsideTheSpace = false,
  metaInfoWrapperClassName,
  onPostChange,
  onPostDestroy,
  pageSupportsContentTruncation = true,
  refetchPosts,
  shouldOpenCommentPage = false,
  tagsLimit = 3,
  tagsShowMoreLabelVariant = "numbers",
  shouldForceSamePageInteractions = false,
}: MainContentProps) => {
  const commentsFormRef = useRef<TrixEditor | TipTapEditor>(null);
  const { record: post, usedIn } = useCurrentPostContext();
  const { isPostBodyEmpty, showPostActions: shouldShowPostActions } =
    useCurrentPostHelpers();
  const [shouldShowComment, setShouldShowComment] =
    useState(defaultShowComment);
  const [shouldAutofocusReplyForm, setShouldAutofocusReplyForm] =
    useState(autofocusReplyForm);
  const isSmScreen = useSmScreenMediaQuery();

  const {
    name,
    is_truncation_disabled,
    is_comments_enabled,
    flagged_for_approval_at,
    hide_meta_info,
    pinned_at_top_of_space,
    post_details_loaded,
    event_setting_attributes: event_setting,
  } = post;

  const liveRoomSettingAttributes =
    event_setting?.live_stream_room_setting_attributes;
  const recordingUrl = liveRoomSettingAttributes?.recording_url;
  const recordingTranscriptId =
    liveRoomSettingAttributes?.recording_transcript_id;
  const recordingThumbnailUrl = liveRoomSettingAttributes?.recording_thumbnail;
  const postingRecording =
    liveRoomSettingAttributes?.auto_post_recording_enabled;
  const { isV3Enabled } = useIsV3();
  const isShowPostPage = useRouteMatch("/c/:spaceSlug/:postSlug")?.isExact;
  const shouldRenderMinimalTipTapView =
    isBasicPost(post) &&
    isV3Enabled &&
    !isShowPostPage &&
    !is_truncation_disabled &&
    !shouldForceSamePageInteractions;

  const isPostTruncationEnabled = !is_truncation_disabled;
  const { currentUser, currentCommunitySettings } = usePunditUserContext();
  const isReactifiedCommentsEnabled =
    currentCommunitySettings?.reactified_comments_enabled;

  const { isSharedPostView, isSharedPostWithTeaserLayout } =
    useCurrentPostHelpers();

  const hasContentTruncation =
    (pageSupportsContentTruncation && isPostTruncationEnabled) ||
    isSharedPostWithTeaserLayout;

  const isImageOrBasicPost = isImagePost(post) || isBasicPost(post);

  const isUsedInFullPostModal = usedIn === "full-post-modal";

  const shouldNotRenderHeader =
    !shouldShowPostActions && usedIn === "full-post-modal" && hide_meta_info;

  if (!post || !post.id) {
    return null;
  }

  const focusNewCommentForm = () => {
    setTimeout(() => {
      const element = document.querySelector(
        `[input="comment-body-${post.id}"]`,
      );
      if (element && element instanceof HTMLElement) {
        element.focus();
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);
  };

  const focusCommentsForm = useCallback(
    () =>
      isTrixEditorRefObject(commentsFormRef.current) &&
      commentsFormRef.current.focus(),
    [commentsFormRef],
  );

  const toggleComment = () => {
    setShouldAutofocusReplyForm(false);
    setShouldShowComment(prev => !prev);
  };

  useEffect(() => {
    if (shouldAutofocusReplyForm) {
      focusCommentsForm();
    }
  }, [focusCommentsForm]);

  const containerClassName = classNames(
    "post post--parent bg-primary border-primary mb-5 md:mb-6 rounded-none border first:mt-5 first:md:mt-0",
    {
      "md:rounded-lg": !isV3Enabled,
      "md:rounded-2xl": isV3Enabled,
      "post--shared": isSharedPostView,
      "post--shared__truncated": isSharedPostWithTeaserLayout && !currentUser,
      "is-truncation-disabled": !hasContentTruncation,
      "flagged-for-approval": flagged_for_approval_at,
      "post-name--empty": !name,
      "post-meta--hidden": hide_meta_info,
      "is-pinned": pinned_at_top_of_space,
      "!shadow-none !rounded-none !border-0 !border-b": isUsedInFullPostModal,
    },
    className,
  );

  const onChange = (updates = {}, options = {}) => {
    onPostChange({ ...post, ...updates }, options);
  };

  const showComments = useCallback(() => {
    setShouldShowComment(true);

    if (isReactifiedCommentsEnabled) {
      setShouldAutofocusReplyForm(true);

      // Focus the reply box if its already open
      if (commentsFormRef.current) {
        if (!isTrixEditorRefObject(commentsFormRef.current)) {
          commentsFormRef.current.commands?.focus?.("end");
        } else if (isTrixEditorRefObject(commentsFormRef.current)) {
          commentsFormRef.current.focus();
        }
      }
    } else {
      focusNewCommentForm();
    }
  }, [isReactifiedCommentsEnabled, focusNewCommentForm]);

  const isEventPostType = isEventPost(post);
  return (
    <div className={containerClassName}>
      {!hidePinnedToTopIcon && isSmScreen && isImageOrBasicPost && (
        <PinnedToTopIcon post={post} />
      )}
      <PostCoverImage post={post} />
      <div
        className={classNames("post__post", {
          "!border-b": !currentUser && isSharedPostView && isEventPostType,
        })}
      >
        <PostContentWrapper>
          <div
            className={classNames({
              "mb-5": !isPostBodyEmpty,
              "px-5 md:px-6": isImagePost(post),
              hidden: shouldNotRenderHeader,
            })}
          >
            <PostHeader
              post={post}
              isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
              onChange={onChange}
              refetchPosts={refetchPosts}
              onPostDestroy={onPostDestroy}
              hideShareLink={hideShareLink}
              hidePinnedToTopIcon={hidePinnedToTopIcon}
              hideBookmarkButton={hideBookmarkButton}
              tagsLimit={isSmScreen ? 1 : tagsLimit}
              tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
              metaInfoWrapperClassName={metaInfoWrapperClassName}
              hideAuthorHeadline={hideAuthorHeadline}
            />
          </div>

          {isEventPostType && <Sidebar onChange={onChange} insidePost />}
          {recordingUrl && postingRecording && (
            <div className="px-6 py-4">
              <TranscriptsPlayer
                src={recordingUrl}
                type="video/mp4"
                mediaTranscriptId={recordingTranscriptId}
                poster={recordingThumbnailUrl}
              />
            </div>
          )}
          {isImagePost(post) && usedIn !== "full-post-modal" && (
            <Carousel
              onPostChange={onPostChange}
              onPostDestroy={onPostDestroy}
            />
          )}
          {shouldRenderMinimalTipTapView ? (
            <MinimalPostBody />
          ) : (
            <PostBody enableTruncation={hasContentTruncation} />
          )}
          {isSharedPostWithTeaserLayout && (
            <div className="to-primary absolute bottom-0 left-0 z-10 h-1/2 w-full bg-gradient-to-b from-transparent" />
          )}
        </PostContentWrapper>
      </div>
      <div className="mt-auto">
        <EngagementActions
          toggleComment={toggleComment}
          showCommentAndFocusCommentForm={showComments}
          shouldOpenCommentPage={shouldOpenCommentPage}
          shouldForceSamePageInteractions={shouldForceSamePageInteractions}
        />
      </div>
      {post_details_loaded && is_comments_enabled && shouldShowComment && (
        <CommentsSection
          commentsFormRef={commentsFormRef}
          autofocusReplyForm={shouldAutofocusReplyForm}
        />
      )}
    </div>
  );
};
