import { t } from "i18n-js";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { FieldLabel } from "@circle-react-shared/Form/FieldLabel";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { ImageInputBanner } from "@circle-react-uikit/CoverImageInput/ImageInputBanner";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common/FormItem";

export const MetaTags = () => (
  <>
    <div className="md:mb-3">
      <Typography.TitleMd>
        {t("events.edit_modal.advanced_options.meta_tags")}
      </Typography.TitleMd>
    </div>
    <div className="pb-4">
      <FormItem
        name="meta_tag_attributes.meta_title"
        hidePlaceholder
        label={t("events.edit_modal.meta_tag_attributes.meta_title")}
        tooltipText={t(
          "events.edit_modal.meta_tag_attributes.meta_title_description",
          {
            obj: "event",
          },
        )}
      >
        <Form.Input />
      </FormItem>
      <FormItem
        name="meta_tag_attributes.meta_description"
        hidePlaceholder
        label={t("events.edit_modal.meta_tag_attributes.meta_description")}
        tooltipText={t(
          "events.edit_modal.meta_tag_attributes.meta_description_description",
        )}
      >
        <Form.Input variant="textarea" />
      </FormItem>
      <FormItem
        name="meta_tag_attributes.opengraph_title"
        hidePlaceholder
        label={t("events.edit_modal.meta_tag_attributes.opengraph_title")}
        tooltipText={t(
          "events.edit_modal.meta_tag_attributes.opengraph_title_description",
          {
            obj: "event",
          },
        )}
      >
        <Form.Input />
      </FormItem>
      <FormItem
        name="meta_tag_attributes.opengraph_description"
        hidePlaceholder
        label={t("events.edit_modal.meta_tag_attributes.opengraph_description")}
        tooltipText={t(
          "events.edit_modal.meta_tag_attributes.opengraph_description_description",
        )}
      >
        <Form.Input variant="textarea" />
      </FormItem>
      <div className="mt-4 grid grid-cols-1">
        <div className="w-full">
          <FieldLabel
            field="hide_location_from_non_attendees"
            label={t("events.edit_modal.meta_tag_attributes.opengraph_image")}
            description={" "}
          />
          <CoverImageInput
            name="meta_tag_attributes[opengraph_image]"
            alt={t("spaces.form.edit.options.seo.opengraph_image.label")}
            className="relative"
            showAddCoverBtn
            aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
            changeImageText={t(
              "spaces.form.edit.options.seo.opengraph_image.change",
            )}
            customAddCoverBtn={
              <ImageInputBanner
                title={t("spaces.form.edit.options.seo.opengraph_image.add")}
                description={t(
                  "spaces.form.edit.options.seo.opengraph_image.description",
                )}
              />
            }
          />
        </div>
      </div>
    </div>
  </>
);
