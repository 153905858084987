import { t } from "i18n-js";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { Typography } from "@circle-react-uikit/Typography";

export const NoResults = ({ setCategory, setQuery }: any) => {
  const resetSearch = () => {
    if (typeof setCategory == "function") {
      setCategory("");
    }
    setQuery("");
  };

  return (
    <div className="mx-auto flex h-32 flex-col items-center justify-center pb-8 pt-6 leading-tight">
      <div className="pb-2">
        <Typography.TitleSm weight="semibold">
          {t("view_only_masquerading.no_results")}
        </Typography.TitleSm>
      </div>
      <div>
        <Typography.BodySm color="text-default">
          {t("view_only_masquerading.try_a_different_term")}
        </Typography.BodySm>
      </div>
      <div className="pt-2">
        <button type="button" onClick={resetSearch}>
          <LabelMd color="text-circle" weight="semibold">
            {t("view_only_masquerading.back")}
          </LabelMd>
        </button>
      </div>
    </div>
  );
};
