import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { isBasicPost, isImagePost } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Post } from "@circle-react/types/Post";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { PinnedToTopIcon } from "./PinnedToTopIcon";
import { PostActionsDropdownMenu } from "./PostActionsDropdownMenu";
import { PostShareOptions } from "./PostShareOptions";

export interface PostActionsProps {
  post: Post;
  onChange: (data: any) => void;
  onPostDestroy: (data: any) => void;
  refetchPosts?: () => void;
  hideShareLink?: boolean;
  hidePinnedToTopIcon?: boolean;
  hideBookmarkButton?: boolean;
}

/**
 * @deprecated
 * Please use the new PostActions component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostActions = ({
  post,
  onChange,
  onPostDestroy,
  refetchPosts,
  hideShareLink,
  hidePinnedToTopIcon,
  hideBookmarkButton,
}: PostActionsProps) => {
  const { refetchPostDetails } = useCurrentPostHelpers();
  const isSmScreen = useSmScreenMediaQuery();
  const isPostImagePost = isImagePost(post);
  const isPostBasicPost = isBasicPost(post);
  const isPost = isPostImagePost || isPostBasicPost;
  if (isSmScreen && isPost) {
    return (
      <div
        className={classNames("post-actions", {
          "flex flex-col gap-2": post.pinned_at_top_of_space,
          "float-right": !post.pinned_at_top_of_space,
        })}
      >
        <div
          className={classNames("px-5 pt-5", {
            "flex w-full flex-row justify-between": post.pinned_at_top_of_space,
          })}
        >
          {!hidePinnedToTopIcon && <PinnedToTopIcon post={post} />}
          <div className="flex flex-row gap-1">
            <PostActionsDropdownMenu
              onChange={onChange}
              onPostDestroy={onPostDestroy}
              refetchPosts={refetchPosts}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="post__actions-container text-dark items-center justify-center"
      data-testid="post-header-actions"
    >
      {!hidePinnedToTopIcon && <PinnedToTopIcon post={post} />}
      <PostActionsDropdownMenu
        onChange={onChange}
        onPostDestroy={onPostDestroy}
        refetchPosts={refetchPosts}
      />
      {!hideShareLink && <PostShareOptions onChange={onChange} />}
      {!hideBookmarkButton && (
        <span
          className={classNames({
            "z-10 mb-0.5": isPost,
            "mt-0.5": !isPost,
          })}
        >
          <BookmarkButton
            bookmarkType={isPost ? "post" : "event"}
            record={post}
            onSuccess={refetchPostDetails}
          />
        </span>
      )}
    </div>
  );
};
