import classNames from "classnames";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPrivateCommunity } from "@circle-react/helpers/communityHelpers";
import { isPrivateSpace } from "@circle-react/helpers/spaceHelpers";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useCurrentPostHelpers } from "../../useCurrentPostHelpers";
import { CommentsBanner } from "./CommentsBanner";
import { CustomCommentsBanner } from "./CustomCommentsBanner";

export const GatedComments = ({ post, space }) => {
  const { currentCommunity, currentUser } = usePunditUserContext();
  const {
    isSharedPostWithTeaserLayout,
    showGatedCommentSectionAtBottom: shouldShowGatedCommentSectionAtBottom,
    showGatedCommentSectionAtTop: shouldShowGatedCommentsAtTop,
    isSharedPostViewWithMinimalLayout,
  } = useCurrentPostHelpers();
  const isIframeEmbed = useIsIframeEmbed();

  const lockedPostCtaContent = {
    heading: currentCommunity.locked_post_cta_heading || t("login_to_comment"),
    body: currentCommunity.locked_post_cta_body,
    buttonLabel: currentCommunity.locked_post_cta_button_text || t("log_in"),
    buttonLink:
      currentCommunity.locked_post_cta_button_url ||
      newSessionPath({
        params: {
          post_login_redirect: window.location.href,
        },
      }),
  };

  const isLoggedIn = !!currentUser;
  const shouldShowGradient = !isLoggedIn && isSharedPostWithTeaserLayout;

  const communityIsPrivate = isPrivateCommunity(currentCommunity);

  const containerClassNames = classNames(
    "gated-post gated-post--custom-cta py-10 px-7 text-center",
    {
      "border-t border-primary": isSharedPostViewWithMinimalLayout,
      post__logged_out_comments: !communityIsPrivate,
      "post__bottom_border border-b border-primary":
        shouldShowGatedCommentsAtTop && !isSharedPostViewWithMinimalLayout,
      "post__top_border border-t border-primary":
        shouldShowGatedCommentSectionAtBottom &&
        !isSharedPostViewWithMinimalLayout,
      "gated-post--standard-layout": !post.minimal_layout,
      "gated-post--no-gradient": !shouldShowGradient,
      "gated-post--gradient": shouldShowGradient,
    },
  );

  if (
    (communityIsPrivate || isPrivateSpace(space)) &&
    currentCommunity.custom_cta_for_share_links
  ) {
    return (
      <CustomCommentsBanner
        containerClassNames={containerClassNames}
        lockedPostCtaContent={lockedPostCtaContent}
      />
    );
  }
  if (post.is_comments_enabled && !isLoggedIn) {
    return (
      <CommentsBanner
        containerClassNames={containerClassNames}
        isIframeEmbed={isIframeEmbed}
        currentCommunity={currentCommunity}
      />
    );
  }

  return null;
};
