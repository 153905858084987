import PropTypes from "prop-types";
import classnames from "classnames";
import { useThemeContext } from "@circle-react/providers";

/**
 * @deprecated
 * Please use the new ActionButton component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const ActionButton = ({
  label,
  onClick,
  disabled = false,
  isLiked = false,
  className,
  ...rest
}) => {
  const { isDarkAppearance } = useThemeContext();

  return (
    <div>
      <button
        type="button"
        className={className}
        onClick={event => {
          event.preventDefault();
          if (!disabled) onClick();
        }}
        aria-disabled={disabled}
        {...rest}
      >
        <span
          aria-checked={isLiked}
          className={classnames("text-sm font-medium", {
            "font-bold": isLiked,
            "text-default": !isLiked,
            "!text-darkest": isDarkAppearance && isLiked,
          })}
        >
          {label}
        </span>
      </button>
    </div>
  );
};

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLiked: PropTypes.bool,
  className: PropTypes.string,
};
