import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { liveStreamApi } from "@circle-react/api";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isPublicStream,
  roomTypeData,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useLiveRoom = () => {
  const { currentUser } = usePunditUserContext();
  const { error, success } = useToast();
  const history = useHistory();
  const { data, remove: removeRoom } = useQuery(
    internalApi.liveStreams.liveNow({
      params: { per_page: 1 },
    }),
  );
  const room = data?.records?.[0];
  const handleNavigateToRoom = () => {
    if (!room) {
      return;
    }

    let url = liveStreamPaths.room({ roomSlug: room.slug });

    if (!currentUser) {
      if (isPublicStream(room)) {
        url = liveStreamPaths.publicRoom({ roomSlug: room.slug });
      } else {
        window.location.href = liveStreamPaths.room({ roomSlug: room.slug });
      }
    }
    history.push(url);
  };
  const endStreamMutation = useMutation(
    () => liveStreamApi.endStream({ id: room?.id }),
    {
      onSuccess: () => {
        success(t(`live_streams.room.${roomTypeData(room)}.end_success`));
        removeRoom();
      },
      onError: () =>
        error(t(`live_streams.room.${roomTypeData(room)}.end_error`)),
    },
  );
  const handleEndStream = event => {
    event.stopPropagation();
    // TODO: display confirmation box
    endStreamMutation.mutate();
  };

  return {
    room,
    canManage: room?.can_manage,
    handleNavigateToRoom,
    handleEndStream,
  };
};
