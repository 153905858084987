import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { NewPostActionsDropdown } from "./NewPostActionsDropdown";

export interface PostActionsDropdownMenuProps {
  onChange: (data: any) => void;
  onPostDestroy: (data: any) => void;
  refetchPosts?: () => void;
}

/**
 * @deprecated
 * Please use the new PostActionsDropdownMenu component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostActionsDropdownMenu = ({
  onChange,
  onPostDestroy,
  refetchPosts,
}: PostActionsDropdownMenuProps) => {
  const {
    showPostActions: canShowPostActions,
    canUpdatePost,
    canDestroyPost,
    isEventPost,
  } = useCurrentPostHelpers();

  const { currentCommunity } = usePunditUserContext();

  if (!canShowPostActions) {
    return null;
  }

  if (isEventPost && !canUpdatePost && !canDestroyPost) {
    return null;
  }

  return (
    <NewPostActionsDropdown
      onChange={onChange}
      onPostDestroy={onPostDestroy}
      refetchPosts={refetchPosts}
      currentCommunity={currentCommunity}
    />
  );
};
