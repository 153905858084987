import { t } from "i18n-js";
import { useMutation } from "react-query";
import { createImagePost, updateImagePost } from "@/react/api/imagePostApi";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useImagePostModalStore } from "../../store";
import { formPayload } from "../helpers";

export const useImagePostMutation = ({ space, post, onSuccess }: any) => {
  const toast = useToast();
  const { setIsSubmitting, uploadedImages } = useImagePostModalStore();
  const isEdit = !!post.slug;

  const options = {
    onSuccess: (response: any) => {
      setIsSubmitting(false);
      onSuccess(response);
      toast.success(
        t(
          isEdit
            ? "quick_post.toasts.changes_saved"
            : `quick_post.toasts.${response.status}_saved`,
        ),
      );
    },
    onError: (response: any) => {
      setIsSubmitting(false);
      toast.error(response.errorDetails, {
        shouldShowCloseButton: true,
        shouldUseProgress: false,
      });
    },
  };
  const { mutate } = useMutation((payload: Record<string, unknown>) => {
    if (post.slug)
      return updateImagePost({
        spaceId: space.id,
        postSlug: post.slug,
        payload,
      });

    return createImagePost({ spaceId: space.id, payload });
  }, options);

  const onSubmit = (payload: any) => {
    setIsSubmitting(true);
    mutate(formPayload({ payload, space, uploadedImages }));
  };

  return { onSubmit };
};
