import { t } from "i18n-js";
import { useCommunityPlanAddon } from "@circle-react/hooks/addons/useCommunityPlanAddon";
import { useToast } from "@circle-react-uikit/ToastV2";

const STREAM_HOURS_ADDON_SLUG = "live_stream_monthly_stream_hours";

export const useStreamHoursAddon = (onSuccess, options = { enabled: true }) => {
  const { error } = useToast();
  const onPurchaseError = () =>
    error(t("live_streams.room.stream_hours_addon_error"));
  return useCommunityPlanAddon(
    STREAM_HOURS_ADDON_SLUG,
    onPurchaseError,
    onSuccess,
    options,
  );
};
