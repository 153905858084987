import PropTypes from "prop-types";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../../Common";
import { LOCATION_TYPES } from "../../../utils";
import { LocationContent } from "./LocationContent";

export const Location = ({ event, setRecordingEnabled, setLocationType }) => {
  const {
    currentCommunity: { supports_live_streams },
  } = usePunditUserContext();

  const locationTypeOptions = [
    {
      label: t(
        "events.create_modal.event_setting_attributes.location_type_in_person",
      ),
      value: LOCATION_TYPES.IN_PERSON,
    },
    {
      label: t(
        "events.create_modal.event_setting_attributes.location_type_virtual",
      ),
      value: LOCATION_TYPES.VIRTUAL,
    },
    {
      label: t(
        "events.create_modal.event_setting_attributes.location_type_live_stream",
      ),
      value: LOCATION_TYPES.LIVE_STREAM,
      hidden: !supports_live_streams,
    },
    {
      label: t(
        "events.create_modal.event_setting_attributes.location_type_live_room",
      ),
      value: LOCATION_TYPES.LIVE_ROOM,
      hidden: !supports_live_streams,
    },
    {
      label: t(
        "events.create_modal.event_setting_attributes.location_type_tbd",
      ),
      value: LOCATION_TYPES.TBD,
    },
  ];

  return (
    <div className="my-6 md:mb-8 md:mt-16">
      <Typography.TitleMd>
        {t("events.create.form.event_location")}
      </Typography.TitleMd>
      <div className="flex items-stretch">
        <div className="w-full">
          <FormItem
            name="event_setting_attributes.location_type"
            placeholder={t(
              "events.create_modal.event_setting_attributes.location_type_placeholder",
            )}
            rules={{
              required: t(
                "events.create_modal.event_setting_attributes.location_required_error",
              ),
            }}
            translationRoot="events.create.form"
          >
            <Form.AnimatedSelect
              fullWidth
              options={locationTypeOptions}
              allowEmpty
              dataTestId="event-location"
            />
          </FormItem>
        </div>
      </div>
      <LocationContent
        setRecordingEnabled={setRecordingEnabled}
        setLocationType={setLocationType}
        event={event}
      />
    </div>
  );
};

Location.propTypes = {
  event: PropTypes.object,
  setLocationType: PropTypes.func,
  setRecordingEnabled: PropTypes.func,
};
