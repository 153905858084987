import { t } from "i18n-js";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";

export const useFormSections = (roomType = ROOM_TYPES.STREAM) => {
  const sections = {
    roomType: t("live_streams.form.sections.room_type"),
    details: t(`live_streams.form.sections.${roomType}.room_details`),
    people: t(`live_streams.form.sections.people`),
  };

  return {
    sections,
    sectionTitles: Object.values(sections),
  };
};
