import { t } from "i18n-js";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { LinkButton } from "@circle-react-uikit/LinkButton";

export const StripeConnect = () => (
  <div className="bg-tertiary mb-16 mt-5 flex h-[208px] w-full items-center justify-center rounded-xl">
    <div className="flex w-[300px] flex-col space-y-6 text-center">
      <Typography.LabelLg weight="medium">
        {t("events.create.form.connect_to_stripe_description")}
      </Typography.LabelLg>
      <div className="mx-6">
        <LinkButton href="/settings/paywalls" variant="circle" full>
          {t("events.create.form.connect_to_stripe")}
        </LinkButton>
      </div>
    </div>
  </div>
);
