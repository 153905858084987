import classnames from "classnames";
import { t } from "i18n-js";
import { useAnyHoverMediaQuery } from "@/react/hooks/useMediaQuery";
import type { ImagePost } from "@/react/types";
import type { GalleryImage } from "@/react/types/GalleryImage";
import { useCurrentPostContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";

interface ImageOptionsProps {
  image: GalleryImage;
}
export const ImageOptions = ({ image }: ImageOptionsProps) => {
  const { record: post }: { record?: ImagePost } = useCurrentPostContext();
  const canHover = useAnyHoverMediaQuery();
  return (
    <div
      className={classnames("absolute right-4 top-4 z-[2] flex", {
        "hidden group-hover:flex": canHover,
      })}
    >
      {post?.gallery?.downloadable_images && (
        <a
          href={image.original_url}
          download
          aria-label={t("download")}
          className="flex h-8 w-8 justify-center rounded-full bg-black p-1 text-white transition-transform hover:text-white focus:text-white"
        >
          <Icon type="16-download" size={16} useWithFillCurrentColor />
        </a>
      )}
    </div>
  );
};
