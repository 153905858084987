import { t } from "i18n-js";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { openUrl } from "@circle-react/helpers/urlHelpers/openUrl";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import type { Event, RecurringEvent } from "@circle-react/types";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { ConfirmationDate } from "./ConfirmationDate";
import { RecurringEventConfirmationDate } from "./RecurringEventConfirmationDate";
import "./styles.scss";

interface ConfirmationModalProps {
  event: Event;
  events: RecurringEvent[];
  show: boolean;
  onClose: () => void;
  shouldShowConfirmationDate?: boolean;
  isRecurring?: boolean;
  eventsCount?: number;
}

export const ConfirmationModal = ({
  event,
  events = [],
  show,
  onClose,
  shouldShowConfirmationDate = true,
  isRecurring = false,
  eventsCount,
}: ConfirmationModalProps) => {
  const { name, event_setting_attributes: eventData } = event || {};
  const {
    confirmation_message_title: title,
    confirmation_message_description: message,
    confirmation_message_button_title: buttonLabel,
    confirmation_message_button_link: buttonLink,
    enable_custom_thank_you_message: isEnableCustomThankYouMessage,
  } = eventData || {};

  const shouldShowConfirmationDatesForRecurringEvents =
    !isPaidEvent(event) && isRecurring;

  const onButtonClick = () => {
    if (buttonLink) {
      openUrl(buttonLink);
    }
    onClose();
  };
  const { selfUpdateCalendarEnabled } = useEventsFeatureFlags();
  return (
    <Modal isOpen={!!show} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className="flex w-full !justify-end pb-0 md:!pb-0">
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="mb-8 !overflow-visible pt-0">
          {!selfUpdateCalendarEnabled && (
            <div className="event-confirmation">
              <h2>{t("events.notifications.event_setting_attributes.clap")}</h2>
              <h3>
                {title ||
                  t(
                    "events.notifications.event_setting_attributes.confirmation_message_title_placeholder",
                    { event_name: name },
                  )}
              </h3>
              <p>
                {isRecurring
                  ? message ||
                    t("events.rsvp.recurring_modal.confirmation_description", {
                      events: `${eventsCount} ${
                        eventsCount === 1
                          ? t("events.rsvp.recurring_modal.event")
                          : t("events.rsvp.recurring_modal.events")
                      }`,
                    })
                  : message ||
                    t(
                      "events.notifications.event_setting_attributes.confirmation_message_description_placeholder",
                    )}
              </p>
              {shouldShowConfirmationDate && (
                <ConfirmationDate
                  event={event}
                  label={isRecurring ? t("add_all_events_to_calendar") : ""}
                />
              )}
            </div>
          )}

          {selfUpdateCalendarEnabled && (
            <div className="flex flex-col items-center text-center">
              <h2 className="mb-4 mt-0">
                {t("events.notifications.event_setting_attributes.clap")}
              </h2>
              <div className="mb-4">
                <Typography.TitleSm weight="bold" color="text-default">
                  {isEnableCustomThankYouMessage
                    ? title
                    : t(
                        "events.notifications.event_setting_attributes.thank_you_message",
                      )}
                </Typography.TitleSm>
              </div>
              <div className="mb-6">
                {isEnableCustomThankYouMessage ? (
                  message
                ) : (
                  <Typography.TitleMd color="text-darkest">
                    {name}
                  </Typography.TitleMd>
                )}
              </div>
              {shouldShowConfirmationDate &&
                !shouldShowConfirmationDatesForRecurringEvents && (
                  <ConfirmationDate
                    event={event}
                    label={isRecurring ? t("add_all_events_to_calendar") : ""}
                  />
                )}
              {shouldShowConfirmationDate &&
                shouldShowConfirmationDatesForRecurringEvents &&
                events.map((recurringEvent, index) => (
                  <div className="pb-2" key={recurringEvent.id || index}>
                    <RecurringEventConfirmationDate
                      recurringEvent={recurringEvent}
                    />
                  </div>
                ))}
              <div>
                <div className="mb-12 mt-6">
                  <Typography.BodyMd color="text-light">
                    {t(
                      "events.notifications.event_setting_attributes.calendar_invite_message",
                    )}
                  </Typography.BodyMd>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {!selfUpdateCalendarEnabled && (
          <Modal.Footer>
            <Button variant="primary" full onClick={onButtonClick}>
              {buttonLabel ||
                t(
                  "events.notifications.event_setting_attributes.confirmation_message_button_title_placeholder",
                )}
            </Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
};
