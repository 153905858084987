import type { ReactNode } from "react";
import { forwardRef } from "react";
import classnames from "classnames";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";

export interface PostViewListItemWrapperProps {
  doesPostHaveCoverImage?: boolean;
  className?: string;
  children: ReactNode;
}

/**
 * @deprecated
 * Please use the new PostViewListItemWrapper component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostViewListItemWrapper = forwardRef<
  HTMLDivElement,
  PostViewListItemWrapperProps
>(({ doesPostHaveCoverImage = false, className, children }, containerRef) => {
  const isSmScreen = useSmScreenMediaQuery();
  return (
    <div
      className={classnames(
        "post__user !items-start",
        {
          "!mb-0 !px-5 !pt-5 md:!mb-2 md:!mt-1":
            isSmScreen && doesPostHaveCoverImage,
          "!mt-0 !px-5 !pt-5": isSmScreen && !doesPostHaveCoverImage,
        },
        className,
      )}
      ref={containerRef}
    >
      {children}
    </div>
  );
});

PostViewListItemWrapper.displayName = "PostViewListItemWrapper";
