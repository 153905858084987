import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { ShareableLink } from "../ShareableLink";

export const CopyRoomUrlLink = ({ event }) => {
  const liveStreamSlug = event?.event_setting_attributes?.live_stream_slug;
  const isLimitUrlSharingEnabled =
    event?.event_setting_attributes?.live_stream_room_setting_attributes
      ?.limit_url_sharing;

  const roomPath = liveStreamPaths.room({ roomSlug: liveStreamSlug });
  const host = window.location.origin;

  const { limitUrlSharingEnabled: limitUrlSharingEnabledFlag } =
    useEventsFeatureFlags();

  if (!liveStreamSlug) {
    return null;
  }

  return (
    <div className="flex">
      {limitUrlSharingEnabledFlag && isLimitUrlSharingEnabled ? null : (
        <ShareableLink
          label={t("events.edit_modal.overview.copy_live_room_url")}
          link={host + roomPath}
        />
      )}
      {isPublicStream(event) && (
        <div className="ml-3">
          <ShareableLink
            label={t("events.edit_modal.overview.copy_live_stream_url")}
            link={
              host + liveStreamPaths.publicRoom({ roomSlug: liveStreamSlug })
            }
          />
        </div>
      )}
    </div>
  );
};

CopyRoomUrlLink.propTypes = {
  event: PropTypes.object.isRequired,
};
