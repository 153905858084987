/* eslint-disable react-hooks/rules-of-hooks
 -- Disabled to set CI to fail on this issue on new files, PR #5195 */
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { AutoExpandingTextInput } from "@circle-react-uikit/AutoExpandingTextInput";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import {
  showCoverImageSelector,
  showTitleInput,
} from "../../../helpers/postHelpers";
import { useFocusOnLoad } from "../../../hooks/useFocusOnLoad";

export const MainSection = ({ space, showAddCoverBtn }) => {
  const { watch } = useFormContext();
  const postId = watch("id");

  // There is some focus related issue on edit, hence disabling auto focus on edit.
  const inputRef = !postId ? useFocusOnLoad() : undefined;

  return (
    <div className="quickpost-modal__post-form">
      {showCoverImageSelector(space) && (
        <div className="quickpost-modal__cover-image-container">
          <CoverImageInput
            name="cover_image"
            alt=""
            showAddCoverBtn={showAddCoverBtn}
          />
        </div>
      )}
      {showTitleInput(space) && (
        <div className="form-section__title-input">
          <AutoExpandingTextInput
            ref={inputRef}
            name="name"
            autoComplete="title"
            placeholder={t("quick_post_v2.title_placeholder")}
            maxLength="255"
            size="255"
            type="text"
            id="post_name"
            tabIndex={0}
          />
        </div>
      )}
      <div className="trix-content trix-content--post">
        <RichTextInput
          ref={showTitleInput(space) ? undefined : inputRef}
          preventAccidentalLeave={false}
          id="post_body"
          name="body"
          is_private_space={false}
          space_id={space && space.id}
          placeholder={t("quick_post_v2.body_placeholder")}
          tabIndex={0}
        />
      </div>
    </div>
  );
};
