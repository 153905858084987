import { t } from "i18n-js";

export interface RepliesMessageLineProps {
  count: number;
}

export const RepliesMessageLine = ({ count }: RepliesMessageLineProps) => (
  <div
    id="replies-message-line"
    className="before:border-primary relative z-0 mx-5 flex items-center justify-start before:absolute before:bottom-0 before:left-0 before:top-1/2 before:z-[-1] before:m-0 before:w-full before:border-t before:border-solid before:content-['']"
  >
    <span className="bg-primary w-[68px] border-none py-0.5 pr-[10px] text-xs text-gray-400">
      {count === 1
        ? t("threads.one_reply")
        : t("threads.many_replies", { count })}
    </span>
  </div>
);
