import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useAllEvents } from "@circle-react/components/AllEvents/hooks/useAllEvents";
import { LOCATION_TYPES } from "@circle-react/components/Events/helpers/constants";
import { isRecurring } from "@circle-react/components/EventsV3/Modal/utils";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import type { Event } from "@circle-react/types/Event";
import { useDefaultValues } from "./useDefaultValues";
import { useEventFormActions } from "./useEventFormActions";
import { useValidateFormSections } from "./useValidateFormSections";

interface UseEventsV3Props {
  onClose: () => void;
  event: Event;
  spaceId: number;
  locationType: string;
  shouldClearEventStateOnHide?: boolean;
}

export const useEventsV3 = ({
  onClose,
  event: originalEvent,
  spaceId,
  locationType: initialLocationType,
  shouldClearEventStateOnHide = false,
}: UseEventsV3Props) => {
  const [event, setEvent] = useState<Event | null>(originalEvent);
  const isPastEvent =
    !!originalEvent &&
    dateIsPast(originalEvent.event_setting_attributes?.ends_at);
  const { onEventChange }: any = useAllEvents({
    params: { past_events: isPastEvent },
  });
  useEffect(() => {
    // Note: This is necessary because the modal remains rendered (hidden)
    // After saving an event, and trying to create a new one
    // the event state needs to be reset.
    setEvent(originalEvent);
  }, [originalEvent]);
  const isEditMode = !!event;
  const title = isEditMode ? event.name : t("events.create.title");
  const formDefaultValues = useDefaultValues(
    event,
    spaceId,
    initialLocationType,
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isStartsAtDirty, setStartsAtDirty] = useState(false);
  const [isPaywallAttributesDirty, setPaywallAttributesDirty] = useState(false);
  const [locationType, setLocationType] = useState(initialLocationType);
  const { triggerValidationRef, validateAndSetCurrentSection } =
    useValidateFormSections();

  const isLiveLocation =
    locationType === LOCATION_TYPES.LIVE_STREAM ||
    locationType === LOCATION_TYPES.LIVE_ROOM;

  const shouldHideAllEventOption =
    isRecurring(event) && isPaywallAttributesDirty;

  const {
    handleClose,
    onSubmit,
    onSave,
    onPublish,
    setValueRef,
    triggerFormSubmitRef,
    mutation,
    isCreating,
  } = useEventFormActions({
    onClose,
    event,
    isDirty,
    isEditMode,
    setEvent,
    shouldClearEventStateOnHide,
    onEventChange,
    isLiveLocation,
  });

  return {
    event,
    isEditMode,
    isCreating,
    isDirty,
    isStartsAtDirty,
    locationType,
    setLocationType,
    isLiveLocation,
    handleClose,
    onSubmit,
    onSave,
    onPublish,
    setValueRef,
    triggerFormSubmitRef,
    triggerValidationRef,
    validateAndSetCurrentSection,
    formDefaultValues,
    title,
    mutation,
    setIsDirty,
    setStartsAtDirty,
    shouldHideAllEventOption,
    setPaywallAttributesDirty,
  };
};
