import { t } from "i18n-js";

export const SECTIONS = {
  overview: t("events.edit_modal.overview.title"),
  people: t("events.edit_modal.people.title"),
  basicInfo: t("events.create.form.basic_info"),
  details: t("events.edit_modal.details.title"),
  notifications: t("events.edit_modal.notifications.title"),
  email: t("events.edit_modal.email.title"),
  advancedOptions: t("events.edit_modal.advanced_options.title"),
};

export const LOCATION_TYPES = {
  VIRTUAL: "virtual",
  IN_PERSON: "in_person",
  TBD: "tbd",
  LIVE_STREAM: "live_stream",
  LIVE_ROOM: "live_room",
};

export const EVENT_TYPES = {
  SINGLE: "single",
  RECURRING: "recurring",
};

export const TICKET_TYPES = {
  FREE: "free",
  PAID: "paid",
};

export const RECURRING_FREQUENCIES = {
  DAILY: "daily",
  WEEKDAY: "weekday",
  WEEKLY: "weekly",
  BI_WEEKLY: "bi_weekly",
  MONTHLY: "monthly",
  MONTHLY_WEEK_BASED: "monthly_weekday_based",
  ANNUALLY: "annually",
  YEARLY: "yearly",
};

export const RECURRING_ENDING = {
  ON: "on",
  NEVER: "never",
  AFTER: "after",
};

export const RECURRING_EDIT_MODE = {
  CURRENT: "current",
  REMAINING: "remaining",
  ALL: "all",
};

export const STATUS = {
  PUBLISHED: "published",
  DRAFT: "draft",
};

export const ATTENDEE_ROLES = {
  PARTICIPANT: "participant",
  COHOST: "cohost",
  HOST: "host",
};

export const PEOPLE_TABS = {
  ATTENDEES: "attendees",
  COHOSTS: "cohosts",
};
