import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const AttendeesWarningMessage = () => (
  <div className="mb-4">
    <Typography.LabelSm>
      {t("events.edit_modal.people.add_attendees_warning_message")}
    </Typography.LabelSm>
  </div>
);
