import { t } from "i18n-js";
import { URL_PATTERN } from "@circle-react/helpers/urlHelpers/constants";
import { Form } from "@circle-react-uikit/Form";
import { FormItem } from "../../../../Common";

export const VirtualLocation = () => (
  <>
    <FormItem
      name="event_setting_attributes.virtual_location_url"
      rules={{
        required: t(
          "events.create_modal.event_setting_attributes.virtual_location_required_error",
        ),
        pattern: {
          value: URL_PATTERN,
          message: t("events.create.form.virtual_location_url_error"),
        },
      }}
      translationRoot="events.create.form"
    >
      <Form.Input data-testid="url-event-location" />
    </FormItem>
    <FormItem
      name="event_setting_attributes.hide_location_from_non_attendees"
      inlineReverse
      translationRoot="events.create.form"
    >
      <Form.ToggleSwitch variant="small" />
    </FormItem>
  </>
);
