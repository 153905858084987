/* eslint-disable eslint-comments/no-restricted-disable, max-lines
-- Even after many refactors, the file stays too large because of its complexity */
import { useEffect, useRef, useState } from "react";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";
import fuzzysort from "fuzzysort";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { DropdownOptions } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/DropdownOptions";
import { TopicInput } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/Input";
import { SelectedTopic } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/SelectedTopic";
import { useCreateTopicMutation } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/useCreateTopic";
import { useTopics } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/useTopics";
import {
  sortAndFilterTopics,
  sortTopicIDs,
} from "@circle-react/components/QuickPostV2/Content/TopicsPicker/utils";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { usePunditUserContext } from "@circle-react/contexts";
import { t } from "@circle-react/custom_i18n";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import { popperSameWidthModifier } from "@circle-react-shared/uikit/Dropdown/utils";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { SortableSelectedTopic } from "./SortableSelectedTopic";
import "./styles.scss";

export const MAX_TOPICS_PER_SPACE = 20;
export const MAX_TOPICS_ALLOWED = 5;

export interface TopicsPickerProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  spaceId?: string;
  maxTopicsAllowed?: number;
  sortable?: boolean;
  creatable?: boolean;
  containerClassName?: string;
}

export const TopicsPicker = ({
  value = [],
  onChange,
  spaceId,
  maxTopicsAllowed = MAX_TOPICS_ALLOWED,
  sortable = false,
  creatable = false,
  containerClassName,
}: TopicsPickerProps) => {
  const [activeTopicIDs, setActiveTopicIDs] = useState<number[]>(value);
  const [filter, setFilter] = useState<string>("");
  const [activeId, setActiveId] = useState<number | null>(null);
  const { currentCommunityMember } = usePunditUserContext();
  const targetElement = useRef<HTMLInputElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const hasTopicsSelected = activeTopicIDs?.length > 0;
  const hasMaxTopicsSelected = activeTopicIDs?.length >= maxTopicsAllowed;
  const {
    data: topics,
    isLoading,
    refetch: refetchTopics,
  } = useTopics({ spaceId });
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const resetSelectedTopics = () =>
    setActiveTopicIDs(
      isCommunityAdmin(currentCommunityMember)
        ? []
        : activeTopicIDs.filter(id => {
            const topic = topics?.records.find(topic => topic.id === id);
            return topic?.admin_only;
          }),
    );

  const removeLastTopic = () =>
    setActiveTopicIDs(prev => {
      const sortedTopicIDs = sortable
        ? prev
        : sortTopicIDs({ activeTopicIDs: prev, topics });
      const lastTopic = topics?.records.find(
        topic => topic.id === sortedTopicIDs[sortedTopicIDs.length - 1],
      );
      return !isCommunityAdmin(currentCommunityMember) && lastTopic?.admin_only
        ? prev
        : prev.slice(0, -1);
    });

  useEffect(() => {
    if (topics) {
      setActiveTopicIDs(prev =>
        prev.filter(activeTopicID =>
          topics?.records.some(({ id }) => id === activeTopicID),
        ),
      );
    }
  }, [topics]);

  useEffect(() => {
    onChange?.(activeTopicIDs.filter(id => !isNaN(Number(id))));
  }, [onChange, activeTopicIDs]);

  const { styles, attributes, forceUpdate } = usePopper(
    targetElement.current,
    popperElement.current,
    {
      placement: "top-start",
      strategy: "absolute",
      modifiers: [
        popperSameWidthModifier,
        {
          name: "offset",
          options: {
            offset: [0, 2],
          },
        },
      ],
    },
  );

  const resetFilterInput = () => {
    setFilter("");
    if (targetElement.current) {
      targetElement.current.value = "";
    }
  };

  const createTopicMutation = useCreateTopicMutation({
    spaceId,
    onSuccess: (newTopic: any) => {
      resetFilterInput();
      void refetchTopics();
      forceUpdate?.();
      setActiveTopicIDs(prev => [...prev, newTopic.id]);
    },
  });

  const inactiveTopics = (topics?.records || [])
    .filter(
      topic =>
        !topic.admin_only ||
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember),
    )
    .filter(topic => !activeTopicIDs.includes(topic.id));

  const inactiveTopicNames = inactiveTopics.map(topic => topic.name);

  const filteredTopicNames = fuzzysort
    .go(filter, inactiveTopicNames, {
      all: true,
    })
    .map(res => res.target);

  const filteredTopics = inactiveTopics.filter(topic =>
    filteredTopicNames.includes(topic.name),
  );

  let shouldShowCreateTopic =
    creatable &&
    !hasMaxTopicsSelected &&
    filter.length > 0 &&
    !inactiveTopicNames.includes(filter);

  if (spaceId) {
    shouldShowCreateTopic =
      shouldShowCreateTopic && Number(topics?.count) < MAX_TOPICS_PER_SPACE;
  }

  const shouldShowDropdown =
    (isLoading || filteredTopics.length > 0 || shouldShowCreateTopic) &&
    activeTopicIDs.length < maxTopicsAllowed;

  useEffect(() => {
    forceUpdate?.();
  }, [forceUpdate, activeTopicIDs]);

  const handleDragStart = (event: any) => setActiveId(event.active.id);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setActiveTopicIDs(items =>
        arrayMove(items, items.indexOf(active.id), items.indexOf(over.id)),
      );
    }
    setActiveId(null);
  };

  const activeDragTopic = topics?.records.find(({ id }) => id === activeId);

  if (
    (!topics?.records || !topics?.records.length) &&
    !isCommunityAdmin(currentCommunityMember)
  ) {
    return null;
  }

  return (
    <Combobox
      value={activeTopicIDs}
      onChange={(value: number[]) => {
        setActiveTopicIDs(value);
        resetFilterInput();
      }}
      multiple
      nullable
    >
      {({ activeOption }) => (
        <div
          className={classNames(
            "text-dark flex items-start justify-between p-2",
            containerClassName,
          )}
        >
          <div id="quickpost-topics-picker-sortable" className="w-full">
            <Combobox.Button className="hidden" ref={buttonElement} />
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={activeTopicIDs}
                strategy={rectSortingStrategy}
                disabled={!sortable}
              >
                <div className="flex w-full flex-row flex-wrap items-center gap-2">
                  {sortAndFilterTopics({
                    activeTopicIDs,
                    topics,
                    skipSort: sortable,
                  }).map((topicRecord: any) => (
                    <SortableSelectedTopic
                      key={topicRecord.id}
                      topic={topicRecord}
                      isDraggable={sortable}
                      setActiveTopicIDs={setActiveTopicIDs}
                    />
                  ))}
                  {!hasMaxTopicsSelected && (
                    <TopicInput
                      buttonElement={buttonElement}
                      filter={filter}
                      setFilter={setFilter}
                      maxTopicsAllowed={maxTopicsAllowed}
                      targetElement={targetElement}
                      activeOption={activeOption}
                      createTopicMutation={createTopicMutation}
                      resetSelectedTopics={resetSelectedTopics}
                      removeLastTopic={removeLastTopic}
                    />
                  )}
                </div>
              </SortableContext>
              {createPortal(
                <DragOverlay zIndex={99999}>
                  {activeDragTopic ? (
                    <SelectedTopic isDragOverlay topic={activeDragTopic} />
                  ) : null}
                </DragOverlay>,
                document.body,
              )}
            </DndContext>
            <DropdownOptions
              shouldShowDropdown={shouldShowDropdown}
              popperElement={popperElement}
              styles={styles}
              filter={filter}
              attributes={attributes}
              isLoading={isLoading}
              filteredTopics={filteredTopics}
              shouldShowCreateTopic={shouldShowCreateTopic}
              createTopicMutation={createTopicMutation}
            />
          </div>
          {hasTopicsSelected && (
            <TippyV2
              content={t("quick_post_v2.topics.remove_all")}
              placement="top-end"
            >
              <IconButton
                className="h-7 w-7"
                iconClassName="!text-dark"
                onClick={resetSelectedTopics}
                name="16-trash-bin-2"
                ariaLabel={t("quick_post_v2.topics.remove_all")}
              />
            </TippyV2>
          )}
        </div>
      )}
    </Combobox>
  );
};
