import { UserImage } from "@circleco/ui-kit";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { DisplayTiptapContent } from "@circle-react/components/CommentsV3/Comment/DisplayTiptapContent";
import { t } from "@circle-react/custom_i18n";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { DisplayTrixContent } from "@circle-react-shared/uikit/DisplayTrixContent";

interface CommentProps {
  comment: {
    id: number;
    post_slug: string;
    space_slug: string;
    post_name?: string;
    post_truncated_content?: string;
    created_at: string;
    updated_at: string;
    tiptap_body: object;
    body_trix_content: string;
    editor: "trix" | "tiptap";
  };
  member: {
    id: number;
    name: string;
    avatar_url: string;
  };
  onClose: () => void;
}

export const Comment = ({ comment, onClose, member }: CommentProps) => {
  const dateString = format(new Date(comment.created_at), "MMM dd");
  return (
    <Link
      to={
        postsPath({
          slug: comment.post_slug,
          spaceSlug: comment.space_slug,
        }) + `#comment_wrapper_${comment.id}`
      }
      onClick={onClose}
      className="flex items-start space-x-3 py-5 first:pt-0"
    >
      <div className="mt-1">
        <UserImage
          isFullRounded
          name={member.name}
          src={member.avatar_url}
          size="10"
        />
      </div>
      <div>
        <div className="flex w-full items-center justify-between space-x-2">
          <div className="text-default w-full flex-1 truncate text-sm">
            {t("profile_drawer.comments.commented_on")}{" "}
            <span className="text-darkest font-medium">
              {comment.post_name || comment.post_truncated_content}
            </span>
          </div>
          <div>
            <TippyV2
              interactive={false}
              content={getDetailedTimestamp(comment.created_at)}
            >
              <span className="text-default text-sm">{dateString}</span>
            </TippyV2>
          </div>
        </div>
        {comment.editor === "tiptap" ? (
          <DisplayTiptapContent
            key={comment.updated_at}
            tiptapBody={comment.tiptap_body}
          />
        ) : (
          <DisplayTrixContent content={comment.body_trix_content} />
        )}
      </div>
    </Link>
  );
};
