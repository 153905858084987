import type { Key, ReactNode } from "react";
import classNames from "classnames";
import Linkify from "react-linkify";
import { messageTextClasses } from "./messageTextClasses";

const componentDecorator = (href: string, text: ReactNode, key: Key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export interface PlainMessageBodyProps {
  body: ReactNode;
  isPinnedMessageAtTop?: boolean;
}

export const PlainMessageBody = ({
  body,
  isPinnedMessageAtTop = false,
}: PlainMessageBodyProps) => (
  <div
    className={classNames(messageTextClasses, {
      "pinned-message-body": isPinnedMessageAtTop,
    })}
    data-testid="message-text"
  >
    <Linkify componentDecorator={componentDecorator}>{body}</Linkify>
  </div>
);
