import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { RESULTS_PER_PAGE } from "../constants";

const REQUEST_KEY = ["settings", "posts"];

export const usePostsApi = ({ page = 1, filter, sort = undefined }) => {
  const fetchPosts = async () =>
    reactQueryGet(
      internalApi.settings.posts.index({
        params: {
          filter,
          page,
          per_page: RESULTS_PER_PAGE,
          sort,
        },
      }),
    );

  const {
    data,
    isLoading: isLoadingPosts,
    refetch: refetchPosts,
  } = useQuery([...REQUEST_KEY, filter, page, sort], fetchPosts, {
    keepPreviousData: true,
  });

  return {
    posts: data?.records ?? [],
    postsCount: data?.count ?? 0,
    isLoadingPosts,
    refetchPosts,
  };
};

export const useInvalidatePostsApiQueries = () => {
  const queryClient = useQueryClient();

  const invalidatePostsApiQueries = () =>
    queryClient.invalidateQueries(REQUEST_KEY);

  return { invalidatePostsApiQueries };
};
