export const SidebarLoader = () => (
  <div className="border-primary col-span-3 mb-9 hidden overflow-auto rounded-2xl border p-6 md:block">
    <div className="bg-tertiary mb-2 h-[28px] w-[124px] animate-pulse rounded-full duration-300" />
    <div className="bg-tertiary h-4 w-32 animate-pulse rounded-md duration-300" />
    <div className="border-primary mt-6 h-px border-b" />
    <div className="space-y-8 py-4">
      <div className="space-y-3">
        <div className="bg-tertiary h-5 w-[105px] animate-pulse rounded duration-300" />
        <div className="bg-tertiary h-5 w-[160px] animate-pulse rounded duration-300" />
        <div className="bg-tertiary h-5 w-[120px] animate-pulse rounded duration-300" />
      </div>
      <div className="space-y-3">
        <div className="bg-tertiary h-5 w-[105px] animate-pulse rounded duration-300" />
        <div className="bg-tertiary h-[30px] w-[110px] animate-pulse rounded-full duration-300" />
      </div>
      <div className="space-y-3">
        <div className="bg-tertiary h-5 w-[105px] animate-pulse rounded duration-300" />
        <div className="bg-tertiary h-5 w-10 animate-pulse rounded duration-300" />
      </div>
      <div className="space-y-3">
        <div className="bg-tertiary h-5 w-[105px] animate-pulse rounded duration-300" />
        <div className="bg-tertiary h-5 w-10 animate-pulse rounded duration-300" />
      </div>
    </div>
  </div>
);
