import { useState } from "react";
import { t } from "i18n-js";
import { PEOPLE_TABS } from "../../../utils";

export const useFormSections = isRoom => {
  const tabs = [
    {
      label: t("events.edit_modal.people.attendees"),
      key: PEOPLE_TABS.ATTENDEES,
    },
  ];
  if (!isRoom) {
    tabs.push({
      label: t("events.edit_modal.people.cohosts"),
      key: PEOPLE_TABS.COHOSTS,
    });
  }
  const defaultTab = tabs.find(
    tab => tab.label === t("events.edit_modal.people.attendees"),
  );
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const onTabClick = async tabLabel => {
    const tab = tabs.find(tab => tab.label === tabLabel);
    setCurrentTab(tab);
  };

  return {
    tabs,
    currentTab,
    onTabClick,
  };
};
