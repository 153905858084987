import { useEffect } from "react";
import classNames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useLocation } from "react-router-dom";
import { isEventPost, isImagePost } from "@/react/helpers/postHelpers";
import { posts } from "@/react/helpers/urlHelpers";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useBackendProps } from "@circle-react/providers";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import type { LineClampLevel } from "@circle-react-uikit/SeeMoreLess";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { KnowledgeBaseFeedbackSection } from "../KnowledgeBaseFeedbackSection";
import { useNavigateToPost } from "../PostModal/hooks/useNavigateToPost";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";

export const PostBody = ({ enableTruncation = false, noPadding = false }) => {
  const {
    post,
    isSharedPostWithTeaserLayout,
    isSharedPostViewWithMinimalLayout,
    isTiptapPost,
    isTrixPost,
    isBasicPost,
    isPostBodyEmpty,
  } = useCurrentPostHelpers();
  const location = useLocation();
  const { navigateToPost } = useNavigateToPost();
  const { params, hasNextPage } = usePostNavigationContext();
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const { usedIn, isUsedInMinimalTipTapViewModal } = useCurrentPostContext();

  const isKnowledgeBasePost =
    ["help", "help-en"].includes(post.community_slug) && isBasicPost;

  const isDisabled = !enableTruncation;

  const isMinimalTeaser =
    isSharedPostViewWithMinimalLayout && isSharedPostWithTeaserLayout;

  const shouldHideToggle = isSharedPostWithTeaserLayout;
  const { isV3Enabled } = useIsV3();
  const shouldRenderCustomSeeMoreButton =
    isV3Enabled &&
    !isUsedInMinimalTipTapViewModal &&
    isEventPost(post) &&
    !isDisabled;

  const {
    body_trix_content: bodyTrixContent,
    tiptap_body: tiptapBody,
    internal_custom_html: html,
    pinned_at_top_of_space: isPinned,
    updated_at: updatedAt,
  } = post;

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  if (isPostBodyEmpty) {
    return null;
  }

  let lines: LineClampLevel = 8;
  if (isPinned) {
    lines = 2;
  } else if (isMinimalTeaser) {
    lines = 4;
  }

  return (
    <div
      key={updatedAt}
      className={classNames("post__body", {
        "!p-0": noPadding,
        "px-5 pb-5 md:px-6":
          isImagePost(post) && !isUsedInMinimalTipTapViewModal,
        "mt-5": usedIn === "minimal-post-modal",
      })}
      data-testid="post-body"
    >
      <div
        className="post__inside trix-v2 w-full"
        data-testid="post-body-inside"
      >
        <SeeMoreLess
          lines={lines}
          disabled={isDisabled}
          hideToggle={shouldHideToggle || shouldRenderCustomSeeMoreButton}
        >
          {isTrixPost && bodyTrixContent && (
            <DisplayTrixContent
              content={bodyTrixContent}
              contentParentPath={posts.show({
                slug: post.slug,
                spaceSlug: post.space_slug,
              })}
            />
          )}
          {isTiptapPost && tiptapBody && (
            <TipTapBlockEditor
              readOnly
              disableMentionClicks={isMinimalLayout}
              value={tiptapBody.body}
              sgidToObjectMap={tiptapBody.sgids_to_object_map}
              inlineAttachments={tiptapBody.inline_attachments}
            />
          )}
          {html && <InnerHTML key="internal_custom_html" html={html} />}
          {isKnowledgeBasePost && <KnowledgeBaseFeedbackSection post={post} />}
        </SeeMoreLess>
        {shouldRenderCustomSeeMoreButton && (
          <button
            type="button"
            className="text-default hover:text-dark mt-2 text-base"
            onClick={() => {
              navigateToPost({
                newPost: post,
                params,
                hasNextPage,
                prevLocation: location,
              });
            }}
          >
            {t("see_more")}
          </button>
        )}
      </div>
    </div>
  );
};
