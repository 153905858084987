import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { RadioButtonGroup } from "@circle-react/components/shared/RadioButtonGroup";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { RECURRING_EDIT_MODE } from "../../Events/helpers/constants";
import { useRecurringUnRsvp } from "./hooks/useRecurringUnRsvp";

export interface RecurringUnRsvpModalProps {
  event: any;
  onChange: (event: any) => void;
}

const RecurringUnRsvpModalComponent = ({
  event,
  onChange,
}: RecurringUnRsvpModalProps) => {
  const translationRoot = "events.un_rsvp.recurring_modal";
  const drawer = useModal();
  const { setEditMode, onUnRsvpConfirm } = useRecurringUnRsvp({
    event,
    onChange,
  });

  const onConfirmHandler = async () => {
    await onUnRsvpConfirm();
    await drawer.hide();
  };

  return (
    <ConfirmationModal
      title={t(`${translationRoot}.not_going`)}
      isOpen={drawer.visible}
      confirmText={t(`${translationRoot}.save`)}
      cancelText={t(`${translationRoot}.cancel`)}
      onClose={drawer.hide}
      onCancel={drawer.hide}
      onConfirm={onConfirmHandler}
      size="sm"
    >
      <div className="mt-6">
        <RadioButtonGroup
          defaultChecked={RECURRING_EDIT_MODE.CURRENT}
          name="recurring_event_settings.frequency_edit"
          options={[
            {
              label: t(`events.edit.recurring_event_modal.this_event`),
              value: RECURRING_EDIT_MODE.CURRENT,
            },
            {
              label: t(`events.edit.recurring_event_modal.this_and_following`),
              value: RECURRING_EDIT_MODE.REMAINING,
            },
            {
              label: t(`events.edit.recurring_event_modal.all_events`),
              value: RECURRING_EDIT_MODE.ALL,
            },
          ]}
          size="sm"
          onChange={value => setEditMode(value)}
        />
      </div>
    </ConfirmationModal>
  );
};

const RecurringUnRsvpModal = ModalManager.create(
  (props: RecurringUnRsvpModalProps) => (
    <RecurringUnRsvpModalComponent {...props} />
  ),
);

export const useRecurringUnRsvpModal = () => useModal(RecurringUnRsvpModal);
