import { t } from "i18n-js";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface ViewEventLinkProps {
  event: Event;
}

export const ViewEventLink = ({ event }: ViewEventLinkProps) => {
  const { slug: eventSlug, space_slug: spaceSlug } = event || {};

  const handleButtonClick = () => {
    window.open(
      postsPath({
        spaceSlug,
        slug: eventSlug,
      }),
      "_blank",
    );
  };

  return (
    <button
      type="button"
      className="flex items-center"
      onClick={handleButtonClick}
    >
      <Typography.LabelSm weight="medium">
        {t("events.edit_modal.overview.view_event")}
      </Typography.LabelSm>
      <Icon type="16-arrow-top-right" size={16} aria-hidden />
    </button>
  );
};
