import { t } from "i18n-js";
import { useWatch } from "react-hook-form";
import {
  CommunityMemberPicker,
  VARIANTS,
} from "@circle-react/components/shared/CommunityMemberPicker";
import { FormItem } from "../../../Common";
import { MembersPreview } from "../MembersPreview";

export const Attendees = () => {
  const invitedEntityIds = useWatch({ name: "invited_entities_ids" }) || {};
  const {
    spaces_ids: spaceIds,
    space_groups_ids: spaceGroupIds,
    member_tags_ids: memberTagIds,
    members_ids: memberIds,
  } = invitedEntityIds;
  const areInvitedEntitiesSelected = Object.values(invitedEntityIds).some(
    value => !!value?.length,
  );
  const buttonLabel = areInvitedEntitiesSelected ? t("manage") : t("invite");

  return (
    <>
      <FormItem name="invited_entities_ids" className="z-20 mb-6" hideLabel>
        <CommunityMemberPicker
          variant={VARIANTS.MODAL}
          countLabel={t("live_streams.form.members_invited_label")}
          tooltipText={t("live_streams.form.members_invited_tooltip")}
          selectionLabel={t(
            "live_streams.form.members_invited_selection_label",
          )}
          searchPlaceholder={t("live_streams.form.members_invited_placeholder")}
          buttonLabel={buttonLabel}
          includeAllResultTypes
        />
      </FormItem>
      <MembersPreview
        spaceIds={spaceIds}
        spaceGroupIds={spaceGroupIds}
        memberTagIds={memberTagIds}
        memberIds={memberIds}
      />
    </>
  );
};
