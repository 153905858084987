import { useEffect, useState } from "react";
import { flatMap } from "lodash";
import reject from "lodash/reject";
import uniqBy from "lodash/uniqBy";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const DEFAULT_PAGE_SIZE = 10;
const COMMENTS_LIST_QUERY_KEY = "comments_list";

/**
 * @deprecated
 * Please use the new getCommentsListKey method from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const getCommentsListKey = ({ postId, parentCommentId }) =>
  [COMMENTS_LIST_QUERY_KEY, postId, parentCommentId].filter(Boolean);

/**
 * @deprecated
 * Please use the new useCommentsList hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useCommentsList = ({
  postId,
  parentCommentId,
  preloaded = false,
  preloadedComments = [],
  preloadedCommentsTotalCount = 0,
  postCommentsClosed,
  //, refetch eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
}) => {
  const [comments, setComments] = useState(preloadedComments || []);
  const [areCommentsClosed, setAreCommentsClosed] =
    useState(postCommentsClosed);

  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, remove } =
    useInfiniteQuery(
      getCommentsListKey({ postId, parentCommentId }),
      ({ pageParam = 1 }) => {
        const pageParams = { per_page: DEFAULT_PAGE_SIZE, page: pageParam };
        const params = parentCommentId
          ? { parent_comment_id: parentCommentId, ...pageParams }
          : pageParams;

        return reactQueryGet(
          internalApi.comments.index({
            postId: postId,
            params,
          }),
        );
      },
      {
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onSuccess: ({ pages }) => {
          setComments(
            uniqBy(
              flatMap(pages, page => page.records),
              "id",
            ),
          );
        },
        initialData: () => {
          if (preloaded) {
            return {
              pages: [
                {
                  records: preloadedComments,
                  page: 0,
                  has_next_page:
                    preloadedCommentsTotalCount > preloadedComments.length,
                },
              ],
              pageParams: [],
            };
          }
        },
        cacheTime: 0,
        staleTime: Infinity,
        notifyOnChangeProps: "tracked",
      },
    );

  // Fully reload comments on closing/opening comments list. This ensures that
  // permissions for replies are correctly updated.
  useEffect(() => {
    if (!preloaded && areCommentsClosed !== postCommentsClosed) {
      setAreCommentsClosed(postCommentsClosed);
      setComments([]);
      remove();
    }
  }, [preloaded, areCommentsClosed, postCommentsClosed, remove]);

  const addCommentToList = comment =>
    setComments(prevComments => [...prevComments, comment]);
  const updateCommentInList = comment =>
    setComments(prevComments =>
      prevComments.map(prevComment =>
        prevComment.id === comment.id ? comment : prevComment,
      ),
    );
  const removeCommentFromList = ({ id }) =>
    setComments(prevComments => reject(prevComments, ["id", id]));

  return {
    comments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    addCommentToList,
    updateCommentInList,
    removeCommentFromList,
  };
};
