import { t } from "i18n-js";
import { isEmpty, isNull, last } from "lodash";
import { DIRECT_CHAT_ROOM } from "@/react/components/constants";
import { chatRoomPath, chatSpacePath } from "@circle-react/helpers/urlHelpers";
import { BOTTOM_NAV_HEIGHT, HEADER_HEIGHT } from "@circle-react-uikit/Rail";

export const SCROLL_WRAPPER_DEFAULT_HEIGHT = HEADER_HEIGHT + BOTTOM_NAV_HEIGHT;
export const LIVE_STEAM_HEADER_HEIGHT = 96;
export const COURSES_HEADER_HEIGHT = 112;
export const APP_HEADER_HEIGHT = 64;

export const MAX_MESSAGES_THRESHOLD = 80;
export const MESSAGES_THRESHOLD = 60;

export const getParticipantNames = (participants: any[]) => {
  let participantNames = "";

  participants.forEach((participant, index) => {
    if (index == participants.length - 1) {
      participantNames = participantNames + participant.name + ".";
    } else if (index == participants.length - 2) {
      participantNames = participantNames + participant.name + " and ";
    } else {
      participantNames = participantNames + participant.name + ", ";
    }
  });

  return participantNames;
};

export const isPersistedMessage = (message: any) => !!message.id;

export const messageWithSameUUIDExists = (messages: any[] = [], message: any) =>
  messages.some(
    currentMessage =>
      currentMessage.creation_uuid === message.creation_uuid &&
      (!message.id || !currentMessage.id),
  );

export const scrollToMessage = (id: string, callback?: () => void) => {
  const messageElement = document.getElementById(id);
  const scrollTimeOut = setTimeout(() => {
    messageElement?.scrollIntoView({ behavior: "smooth" });
    callback?.();
  }, 100);
  return () => clearTimeout(scrollTimeOut);
};

export const getRole = (
  communityMemberId: string,
  roles: Record<string, any>,
) => {
  for (const [key, value] of Object.entries(roles || [])) {
    if (Array.isArray(value) && value.includes(communityMemberId)) {
      return key;
    }
  }
  return undefined;
};

export const scrollToMessageViewBottom = () => {
  const element = document.getElementById("message-scroll-view");
  if (!element) return;
  element.scrollTop = element.scrollHeight;
};

export const isMobileBottomNavVisible = () => {
  const mobileBottomNav = document.getElementById("mobile-bottom-toolbar");
  return Boolean(mobileBottomNav);
};

export const isBookmarksBarVisible = () => {
  const bookmarksBar = document.getElementById("bookmarks-bar");
  return Boolean(bookmarksBar);
};

export const getCoursesHeaderHeight = () => {
  const helloBar = document.getElementById("lesson-hello-bar");
  return helloBar ? COURSES_HEADER_HEIGHT : HEADER_HEIGHT;
};

export const getHeightWhenMessagingModalIsOpen = (height: number) =>
  height < 20 ? 100 : height;

const isMessageSent = (message: any, currentParticipant: any) =>
  message.sender.id === currentParticipant.id;

const isLastMessageSame = (lastMessageInArr?: any, lastMessage?: any) =>
  lastMessageInArr &&
  !!lastMessageInArr.id &&
  lastMessageInArr?.creation_uuid === lastMessage?.creation_uuid;

interface AppendLastMessageToChatMessagesProps {
  calculateScrollInfo: (event: any) => any;
  chatRoomMessages: any[];
  currentParticipant: any;
  isInitialPageLoaded?: boolean;
  lastMessage: any;
  setChatRoomMessages: (messages: any[]) => void;
  setFirstMessageId: (messageId: number | null) => void;
  setMessagesHasPrevPage: (hasPrevPage: boolean) => void;
  setScrollInfo: (scrollInfo: any) => void;
}

export const appendLastMessageToChatMessages = ({
  calculateScrollInfo,
  chatRoomMessages,
  currentParticipant,
  isInitialPageLoaded = true,
  lastMessage,
  setChatRoomMessages,
  setFirstMessageId,
  setMessagesHasPrevPage,
  setScrollInfo,
}: // eslint-disable-next-line sonarjs/cognitive-complexity -- Already existed before maximum complexity value was reduced
AppendLastMessageToChatMessagesProps) => {
  if (lastMessage == null || !isInitialPageLoaded) {
    return undefined;
  }

  let messages = [...chatRoomMessages];
  // Memory Optimization
  if (messages.length > MAX_MESSAGES_THRESHOLD) {
    messages = messages.slice(-MESSAGES_THRESHOLD);
    setChatRoomMessages(messages);
    setFirstMessageId(messages[0].id);
    setMessagesHasPrevPage(true);
  }

  const lastMessageInArr = last(messages);
  const isNewMessageReached = lastMessageInArr?.id != lastMessage.id;

  if (
    isNewMessageReached &&
    !isLastMessageSame(lastMessageInArr, lastMessage)
  ) {
    setScrollInfo(
      calculateScrollInfo({
        event: isMessageSent(lastMessage, currentParticipant)
          ? "messageSent"
          : "messageReceived",
      }),
    );

    let newChatRoomMessages = [...messages];

    if (messageWithSameUUIDExists(newChatRoomMessages, lastMessage)) {
      newChatRoomMessages = newChatRoomMessages.map(message => {
        const isSameUUID = message.creation_uuid === lastMessage.creation_uuid;

        if (isSameUUID && !isPersistedMessage(message)) {
          return lastMessage;
        }

        return message;
      });
    } else {
      newChatRoomMessages.push(lastMessage);
    }

    return newChatRoomMessages;
  }

  return undefined;
};

export const getMessageHashFromUrl = () =>
  (window.location.hash || "").split("#message_")[1];

export const showDateChangeLine = (
  displayDayChange: boolean,
  dayChange: boolean,
) => displayDayChange && dayChange;

export interface ShowUnreadMessageLineProps {
  id?: string;
  isPinned?: boolean;
  disableUnreadMessageLine?: boolean;
  unreadMessageId?: string;
}

export const showUnreadMessageLine = ({
  id,
  isPinned,
  disableUnreadMessageLine,
  unreadMessageId,
}: ShowUnreadMessageLineProps) =>
  !isNull(id) &&
  !isPinned &&
  !disableUnreadMessageLine &&
  unreadMessageId === id;

interface ShouldHightlightMessageProps {
  id?: string;
  messageHashId?: string;
  messageHighlightId?: string;
}

export const shouldHightlightMessage = ({
  id,
  messageHashId,
  messageHighlightId,
}: ShouldHightlightMessageProps) =>
  (messageHashId && id == messageHashId) ||
  (messageHighlightId && id == messageHighlightId);

interface ShouldHightlightThreadMessageProps {
  id?: string;
  messageHighlightId?: string;
  isParentMessage?: boolean;
}

export const shouldHightlightThreadMessage = ({
  id,
  messageHighlightId,
  isParentMessage,
}: ShouldHightlightThreadMessageProps) =>
  !isParentMessage && messageHighlightId && id == messageHighlightId;

export const getDeleteConfirmationMessageText = (repliesCount = 0) => {
  if (repliesCount > 0) {
    return `${t("messaging.delete_message.description")} ${t(
      "messaging.delete_message.threads",
    )}`;
  }
  return t("messaging.delete_message.description");
};

export const getChatRoomUrl = (chatRoom: any, message: any) => {
  if (isEmpty(chatRoom)) return "";
  const { embedded_space, uuid } = chatRoom;
  const { slug } = embedded_space || {};
  const { embedded } = message;

  return embedded ? chatSpacePath({ spaceSlug: slug }) : chatRoomPath({ uuid });
};

export const getChatRoomMessageRedirectionHash = (message: any) =>
  `message_${message.id}`;

export const getChatThreadMessageRedirectionHash = (message: any) =>
  `message_${message.parent_message_id}`;

export const getChatRoomMessageRedirectionUrl = (
  chatRoom: any,
  message: any,
) => {
  const chatRoomUrl = getChatRoomUrl(chatRoom, message);
  return `${chatRoomUrl}#message_${message.id}`;
};

export const findMissingParticipantIds = (
  messages: any[],
  participants: any[],
) => {
  const missingParticipantIds: any[] = [];

  messages.map(message => {
    const { chat_room_participant_id: participantId } = message;

    const sender = participants.find(
      participant => participant?.id === participantId,
    );

    if (!sender && !missingParticipantIds.includes(participantId))
      missingParticipantIds.push(participantId);
  });

  return missingParticipantIds;
};

export const isDirectChatRoom = (chatRoom: any) =>
  Boolean(chatRoom?.chat_room_kind === DIRECT_CHAT_ROOM);

export const isChatRoomAdminOrModerator = (chatRoom: any) => {
  const { current_participant: currentParticipant = {} } = chatRoom || {};
  return Boolean(currentParticipant?.admin || currentParticipant?.moderator);
};

export const getPortalTargetId = (
  isMessagingPage: boolean,
  isRenderedOnHeader: boolean,
) => {
  let portalTargetId: string | null = null;

  if (isMessagingPage) {
    portalTargetId = "fullscreen-modal-body";
  } else if (isRenderedOnHeader) {
    portalTargetId = "chat-threads-header";
  }

  return portalTargetId;
};
