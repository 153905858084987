import { HeaderLoader } from "@circle-react/components/ProfileModalV3/Loader/Header";
import { PostLoader } from "@circle-react/components/ProfileModalV3/Loader/Post";
import { SidebarLoader } from "@circle-react/components/ProfileModalV3/Loader/Sidebar";
import { TabBarLoader } from "@circle-react/components/ProfileModalV3/Loader/TabBar";

export const Loader = () => (
  <>
    <div className="col-span-12 flex flex-col overflow-auto pb-9 md:col-span-9">
      <HeaderLoader />
      <div className="border-primary mt-5 h-px border-b" />
      <TabBarLoader />
      <PostLoader />
    </div>
    <SidebarLoader />
  </>
);
