export const HeaderLoader = () => (
  <div className="flex items-center space-x-5">
    <div className="bg-tertiary h-[104px] w-[104px] animate-pulse rounded-full duration-300" />
    <div className="flex flex-1 items-start justify-between">
      <div className="flex flex-col">
        <div className="bg-tertiary h-6 w-32 animate-pulse rounded-md duration-300" />
        <div className="bg-tertiary mt-1 h-5 w-20 animate-pulse rounded-md duration-300" />
        <div className="mt-2 flex items-center space-x-2">
          <div className="bg-tertiary h-5 w-24 animate-pulse rounded-md duration-300" />
          <div className="bg-tertiary h-5 w-44 animate-pulse rounded-md duration-300" />
        </div>
      </div>
      <div className="flex items-center justify-between space-x-3">
        <div className="bg-tertiary h-[34px] w-24 animate-pulse rounded-full duration-300" />
        <div className="bg-tertiary h-[34px] w-[58px] animate-pulse rounded-full duration-300" />
      </div>
    </div>
  </div>
);
