import { useEffect } from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Comment } from "../Comment";
import { useJumpToComment } from "../useJumpToComment";
import { useCommentHierarchy } from "./useCommentHierarchy";

/**
 * @deprecated
 * Please use the new CommentHierarchy component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentHierarchy = ({
  space,
  post,
  id,
  showReplyForm = false,
  autofocusReplyForm = false,
  onCommentDelete,
  onReplyCreate,
  onReplyDelete,
}) => {
  const { comment, isLoading, updateQueryData } = useCommentHierarchy({
    postId: post.id,
    id: id,
    postCommentsClosed: post.is_comments_closed,
  });

  useEffect(() => {
    if (!isLoading) {
      // Ensures that tooltips work in the engagement menu, which is currently
      // rendered on the BE.
      window.initialiseTippy();
    }
  }, [isLoading]);

  useJumpToComment({ enabled: !isLoading });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (!comment) {
    return (
      <div className="post__comment-not-found alert alert-warning !ml-0">
        {I18n.t("comments.hierarchy.not_found")}
      </div>
    );
  }

  return (
    <Comment
      space={space}
      post={post}
      comment={comment}
      showReplyForm={showReplyForm}
      autofocusReplyForm={autofocusReplyForm}
      onUpdate={updateQueryData}
      onDelete={onCommentDelete}
      onReplyCreate={onReplyCreate}
      onReplyDelete={onReplyDelete}
    />
  );
};

CommentHierarchy.propTypes = {
  space: PropTypes.object,
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    space_slug: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    is_comments_closed: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  showReplyForm: PropTypes.bool,
  autofocusReplyForm: PropTypes.bool,
  onCommentDelete: PropTypes.func.isRequired,
  onReplyCreate: PropTypes.func.isRequired,
  onReplyDelete: PropTypes.func.isRequired,
};
