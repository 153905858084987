import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import type { CommunityMember } from "@circle-react/types";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export interface MemberRowProps {
  communityMember: CommunityMember;
  hideModal: () => void;
}

/**
 * @deprecated
 * Please use the new MemberRow component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const MemberRow = ({ communityMember, hideModal }: MemberRowProps) => {
  const { onClick, ...profileLinkProps } = useProfileLinkProps({
    public_uid: communityMember.public_uid ?? "",
  });

  return (
    <button
      type="button"
      className="flex w-full items-center gap-3 text-left"
      onClick={(event: any) => {
        hideModal();
        onClick?.(event);
      }}
      {...profileLinkProps}
    >
      <div className="mt-0">
        <UserImage
          name={communityMember.name ?? ""}
          src={communityMember.avatar_url}
          size="10"
        />
      </div>
      <Typography.LabelSm weight="medium">
        {communityMember.name}
      </Typography.LabelSm>
    </button>
  );
};
