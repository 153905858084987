import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";

export const ToggleItem = ({ name }: { name: string }) => (
  <Form.Item
    id={`post_${name}`}
    name={name}
    label={t(`post.${name}`)}
    hideBorder
    hideDescription
    inlineReverse
  >
    <Form.ToggleSwitch name={name} variant="small" />
  </Form.Item>
);
