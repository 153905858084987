import { useRef } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { truncate } from "lodash";
import timeAgo from "node-time-ago";
import type { Comment } from "@/react/types/Comment";
import type { Post } from "@/react/types/Post";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { postsPath } from "@circle-react/helpers/urlHelpers";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { COMMENT_ANCHOR_PREFIX } from "../constants";
import { Body } from "./Body";

export interface ContentProps {
  post: Post;
  comment: Comment;
}

/**
 * @deprecated
 * Please use the new Content component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const Content = ({ post, comment }: ContentProps) => {
  const {
    id,
    community_member,
    member_tags,
    flagged_for_approval_at,
    created_at,
  } = comment;

  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid ?? "",
    params: { show_back_link: true },
  });

  const isIframeEmbed = useIsIframeEmbed();
  const containerRef = useRef<HTMLDivElement>(null);
  const { currentCommunityMember } = usePunditUserContext();
  const isCommunityAdminUser = isCommunityAdmin(currentCommunityMember);
  const isAdmin = Boolean(community_member.admin);
  const visibleMemberTags = member_tags?.filter(
    memberTag => memberTag?.is_public && memberTag?.display_locations?.post_bio,
  );
  const hasMemberTags = visibleMemberTags?.length > 0 || isAdmin;
  const isSmScreen = useSmScreenMediaQuery();
  const shouldDisplayMemberTags = isSmScreen
    ? isCommunityAdminUser
    : hasMemberTags;

  const commentLinkProps = {
    href: postsPath({
      spaceSlug: post.space_slug,
      slug: post.slug,
      hash: `${COMMENT_ANCHOR_PREFIX}${id}`,
    }),
    ...(isIframeEmbed ? { target: "_blank", rel: "noreferrer" } : {}),
  };

  const truncatedHeadline = truncate(community_member.headline, {
    length: 160,
  });

  return (
    <div className={classnames("post__user !items-start")} ref={containerRef}>
      <div className="post__avatar">
        <a className="user__avatar" {...profileLinkProps}>
          <UserImage
            src={community_member.avatar_url || ""}
            name={community_member.name || ""}
            size="10"
          />
        </a>
      </div>
      <div className="post__bio">
        <div
          className={classnames("author", {
            "no-headline": !community_member.headline,
          })}
        >
          <div className="author__name">
            <a {...profileLinkProps}>{community_member.name}</a>
          </div>
          {hasMemberTags && shouldDisplayMemberTags && (
            <div className="author__tags member-tags">
              <MemberTags memberTags={visibleMemberTags} isAdmin={isAdmin} />
            </div>
          )}
          <div className="author__time">
            <a {...commentLinkProps}>{timeAgo(created_at)}</a>
          </div>
          {flagged_for_approval_at && (
            <span
              className={classnames("author__in-review", {
                "!ml-0 !mt-1 md:!ml-2": !hasMemberTags,
              })}
            >
              {t("comments.in_review")}
            </span>
          )}
        </div>
        {truncatedHeadline && (
          <div className="post__meta">
            <div className="author__credentials text-dark">
              {truncatedHeadline}
            </div>
          </div>
        )}
        <Body comment={comment} />
      </div>
    </div>
  );
};
