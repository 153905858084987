import PropTypes from "prop-types";
import classNames from "classnames";

export const ChatBottomBanner = ({ children, className = "", ...props }) => (
  <div
    {...props}
    className={classNames(
      "bg-secondary text-dark mx-6 mb-6 rounded-lg p-4 text-center text-sm",
      className,
    )}
  >
    {children}
  </div>
);

ChatBottomBanner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
