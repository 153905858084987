import { getLocale } from "@circle-react/helpers/localeHelpers";
import type { PaywallCurrency } from "@circle-react/types";
import { paywallCurrencyDirections } from "@circle-react/types";

export interface FormatPaywallCurrencyAmountOptions {
  amountInCents: boolean;
  includeCurrencyCode: boolean;
  includeCurrencySymbol: boolean;
  forceMaxFractionDigits: boolean;
}

// IMPORTANT: Maintain feature parity with `app/models/currency.rb#format_amount`.
export const formatPaywallCurrencyAmount = (
  currency: PaywallCurrency,
  amount: number,
  options: Partial<FormatPaywallCurrencyAmountOptions> = {},
) => {
  const selectedLocale = getLocale(window?.locale || "en");
  const defaultOpts = {
    amountInCents: true,
    includeCurrencyCode: true,
    includeCurrencySymbol: true,
    forceMaxFractionDigits: false,
  };
  const opts = { ...defaultOpts, ...options };

  const amountValue = opts.amountInCents ? amount / 100 : amount;

  const precision =
    opts.forceMaxFractionDigits || amountValue % 1 !== 0 ? 2 : 0;
  const formattedAmountValue = new Intl.NumberFormat(selectedLocale, {
    style: "decimal",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(amountValue);

  const code = opts.includeCurrencyCode ? currency.code.toUpperCase() : "";
  const unit = opts.includeCurrencySymbol ? currency.symbol : "";

  let amountStr: string;
  if (currency.direction === paywallCurrencyDirections.rtl) {
    amountStr = `${code} ${formattedAmountValue}${unit}`;
  } else {
    amountStr = `${unit}${formattedAmountValue} ${code}`;
  }

  return amountStr.trim();
};
