import classnames from "classnames";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { useIsOrCanBeCommunityMember } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";

/**
 * @deprecated
 * Please use the new LikeButton component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const LikeButton = () => {
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const { post, ...postHelpers } = useCurrentPostHelpers();

  const {
    is_liking_enabled: isLikingEnabled,
    is_liked_by_current_user: isLikedByCurrentUser,
    post_details_loaded,
  } = post;

  if (!isLikingEnabled || !isOrCanBeCommunityMember) return null;

  return (
    <button
      type="button"
      aria-label={t("like_post_aria_label", {
        name: post?.name ?? "",
      })}
      onClick={event => {
        if (isFunction(postHelpers?.onLikeToggle)) {
          void postHelpers.onLikeToggle(event);
        }
      }}
      className={classnames(
        "action-link post__actions--like hover:!text-dark focus-visible:!text-dark transition-colors duration-150 ease-in-out",
        {
          liked: isLikedByCurrentUser,
          "cursor-not-allowed": !post_details_loaded,
          "text-default": !isLikedByCurrentUser,
        },
      )}
    >
      <span className="action__icon">
        <Icon useWithFillCurrentColor type="like" />
      </span>
      <span
        aria-checked={isLikedByCurrentUser}
        className={classnames("action__label")}
      >
        {t(isLikedByCurrentUser ? "liked" : "like")}
      </span>
    </button>
  );
};
