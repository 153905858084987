import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useImagePostModalStore } from "@/react/components/Spaces/ImageSpace/ImagePostModal/store";
import type { ImagePost } from "@/react/types";
import type { Space } from "@/react/types/Space";
import { FormTipTapBlockEditor } from "@circle-react-uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { Typography } from "@circle-react-uikit/Typography";
import { CaptionToolbar } from "./CaptionToolbar";

const DISABLED_EXTENSIONS = [
  "blockquote",
  "poll",
  "embeds",
  "slash_commands",
  "file",
  "image",
  "horizontal_rule",
  "code_block",
  "headings",
  "cta",
];

interface CaptionEditorProps {
  space?: Space;
  post: ImagePost;
}
export const CaptionEditor = ({ space, post }: CaptionEditorProps) => {
  const { setIsFormDirty } = useImagePostModalStore();
  const {
    formState: { isDirty: isFormDirty },
  } = useFormContext();

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [isFormDirty, setIsFormDirty]);

  return (
    <div className="border-b-primary border-y p-6 pb-4 lg:border-t-0">
      <Typography.LabelMd weight="semibold">
        {t("image_space.post_modal.steps.create_post.caption_label")}
      </Typography.LabelMd>
      <FormTipTapBlockEditor
        placeholder={t(
          "image_space.post_modal.steps.create_post.caption_placeholder",
        )}
        name="tiptap_body.body"
        sgidToObjectMap={post?.tiptap_body?.sgids_to_object_map || {}}
        spaceId={space?.id}
        editorClassName="min-w-full h-40 overflow-y-scroll mt-3"
        disabledExtensions={DISABLED_EXTENSIONS}
        shouldShowTextModifiersMenu={false}
        toolbars={[
          {
            portalId: "create-image-post-toolbar",
            component: CaptionToolbar,
          },
        ]}
      />
      <div id="create-image-post-toolbar" />
    </div>
  );
};
