import { Portal } from "@material-ui/core";
import classNames from "classnames";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { Actions } from "@circle-react/components/QuickPostV2/Content/Footer/Actions";
import { Icon } from "@circle-react/components/shared/Icon";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import { useSpacesContext } from "@circle-react/contexts";
import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-uikit/Typography";

export const hasSpaceContextHelpers = (
  context: any,
): context is {
  helpers: {
    basicSpacesWithPostCreationAllowed: () => any;
  };
} => !!context?.helpers?.basicSpacesWithPostCreationAllowed;

export const Footer = () => {
  const isMd = useMdScreenMediaQuery();
  const spacesContext = useSpacesContext();
  const spaces = hasSpaceContextHelpers(spacesContext)
    ? spacesContext.helpers.basicSpacesWithPostCreationAllowed()
    : [];
  return (
    <footer
      className="border-primary flex items-center justify-between border-t px-5 py-4"
      data-testid="quick-post-footer"
    >
      <nav
        aria-label={t("quick_post_v2.footer_menu_aria_label")}
        id="quick-post-v2-footer-toolbar"
      />
      <div className="flex items-center space-x-2">
        <Portal
          disablePortal={isMd}
          container={document.getElementById("space-select-for-mobile")}
        >
          <SpaceSelect
            dropdownClassName={isMd ? "bottom-14" : "bottom-32 right-1"}
            spaces={spaces}
            containerClassname={classNames("!max-w-full", {
              "border-t border-primary": !isMd,
            })}
            name="space_id"
            customButton={({ toggleDropdown, selectedSpace, isOpen }: any) => (
              <button
                type="button"
                className={classNames(
                  "hover:bg-secondary focus-visible:bg-secondary flex w-full cursor-pointer items-center rounded-md",
                  {
                    "bg-secondary": isOpen,
                    "px-5 py-4": !isMd,
                    "px-3 py-2": isMd,
                  },
                )}
                onClick={toggleDropdown}
              >
                <div
                  className={classNames("flex items-center space-x-1", {
                    "flex-1": !isMd,
                  })}
                >
                  {selectedSpace?.name ? (
                    <>
                      <Typography.LabelSm weight="medium" color="text-default">
                        {t("quick_post_v2.posting_in")}{" "}
                      </Typography.LabelSm>
                      <Typography.LabelSm weight="medium" color="text-default">
                        <span className="text-dark" title={selectedSpace.name}>
                          {truncate(selectedSpace.name, {
                            length: isMd ? 24 : 36,
                          })}
                        </span>
                      </Typography.LabelSm>
                    </>
                  ) : (
                    <Typography.LabelSm weight="medium" color="text-default">
                      <span className="text-light">
                        {t("quick_post_v2.choose_a_space")}
                      </span>
                    </Typography.LabelSm>
                  )}
                </div>
                <Icon className="!text-dark" type="16-chevron-down" size={16} />
              </button>
            )}
          />
        </Portal>
        {/* TODO: This needs to be wired up properly */}
        <Actions />
      </div>
    </footer>
  );
};
