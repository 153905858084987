import { immer } from "zustand/middleware/immer";

interface ChatRoomStoreMap {
  [key: string]: ChatRoomState;
}

interface ChatRoomState {
  chatRoomMessages: any[];
  isLoading: boolean;
  isInitialPageLoaded: boolean;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  scrollInfo: any;
  firstMessageId: number | null;
  lastMessageId: number | null;
  shouldShowLatestMessageBtn: boolean;
  topMessageId: number | null;
  activeMessageId: string | null;
}

interface ChatRoomActions {
  setChatRoomMessages: (uuid: string, messages: any[]) => void;
  setIsLoading: (uuid: string, isLoading: boolean) => void;
  setIsInitialPageLoaded: (uuid: string, isLoading: boolean) => void;
  setHasNextPage: (uuid: string, hasNextPage: boolean) => void;
  setHasPrevPage: (uuid: string, hasPrevPage: boolean) => void;
  setScrollInfo: (uuid: string, scrollInfo: any) => void;
  setFirstMessageId: (uuid: string, id: number | null) => void;
  setLastMessageId: (uuid: string, id: number | null) => void;
  setShouldShowLatestMessageBtn: (
    uuid: string,
    shouldShowLatestMessageBtn: boolean,
  ) => void;
  setTopMessageId: (uuid: string, id: number | null) => void;
  setActiveMessageId: (uuid: string, id: string | null) => void;
  resetChatRoom: (uuid: string) => void;
}

export interface ChatRoomsStoreProps {
  chatRoomsMap: ChatRoomStoreMap;
  createNewChatRoom: (uuid: string) => ChatRoomState;
  roomActions: ChatRoomActions;
}

const initialChatRoomState: ChatRoomState = {
  chatRoomMessages: [],
  isLoading: false,
  hasNextPage: false,
  hasPrevPage: false,
  scrollInfo: {},
  firstMessageId: null,
  lastMessageId: null,
  shouldShowLatestMessageBtn: false,
  topMessageId: null,
  isInitialPageLoaded: false,
  activeMessageId: null,
};

export const createChatRoomsSlice = immer<ChatRoomsStoreProps>(set => ({
  chatRoomsMap: {},
  createNewChatRoom: uuid => {
    set(state => {
      state.chatRoomsMap[uuid] = initialChatRoomState;
    });
    return initialChatRoomState;
  },
  roomActions: {
    setChatRoomMessages: (uuid, messages) =>
      set((state: any) => {
        state.chatRoomsMap[uuid].chatRoomMessages = messages;
      }),
    setIsLoading: (uuid, isLoading) =>
      set(state => {
        state.chatRoomsMap[uuid].isLoading = isLoading;
      }),
    setIsInitialPageLoaded: (uuid, isLoaded) =>
      set(state => {
        state.chatRoomsMap[uuid].isInitialPageLoaded = isLoaded;
      }),
    setHasNextPage: (uuid, hasNextPage) =>
      set(state => {
        state.chatRoomsMap[uuid].hasNextPage = hasNextPage;
      }),
    setHasPrevPage: (uuid, hasPrevPage) =>
      set(state => {
        state.chatRoomsMap[uuid].hasPrevPage = hasPrevPage;
      }),
    setScrollInfo: (uuid, scrollInfo) =>
      set(state => {
        state.chatRoomsMap[uuid].scrollInfo = scrollInfo;
      }),
    setFirstMessageId: (uuid, id) =>
      set(state => {
        state.chatRoomsMap[uuid].firstMessageId = id;
      }),
    setLastMessageId: (uuid, id) =>
      set(state => {
        state.chatRoomsMap[uuid].lastMessageId = id;
      }),
    setShouldShowLatestMessageBtn: (uuid, shouldShowLatestMessageBtn) =>
      set(state => {
        state.chatRoomsMap[uuid].shouldShowLatestMessageBtn =
          shouldShowLatestMessageBtn;
      }),
    setTopMessageId: (uuid, id) =>
      set(state => {
        state.chatRoomsMap[uuid].topMessageId = id;
      }),
    setActiveMessageId: (uuid, id) =>
      set(state => {
        state.chatRoomsMap[uuid].activeMessageId = id;
      }),
    resetChatRoom: uuid =>
      set(state => {
        state.chatRoomsMap[uuid] = initialChatRoomState;
      }),
  },
}));
