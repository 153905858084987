import { useMemo } from "react";
import { generateJSON } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { addSecondsToDate } from "@circle-react/helpers/dateTimeHelpers";
import type { Event } from "@circle-react/types";
import type { ScheduledEmail } from "@circle-react-shared/ScheduledEmailModal";
import { ScheduledEmailModal } from "@circle-react-shared/ScheduledEmailModal";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { FormItem } from "../../Common/FormItem";
import { isPublished } from "../../utils";
import { ScheduledEmailList } from "./ScheduledEmailList";
import { useModal } from "./useModal";
import { useScheduledEmailContext } from "./useScheduledEmailContext";

const ONE_HOUR_IN_SECONDS = 3600;
const EMAIL_REMINDER_NAME = "event_setting_attributes.send_email_reminder";

export const ScheduledEmailSection = ({ event }: { event: Event }) => {
  const {
    isOpen: isEmailFormOpen,
    openModal: openEmailForm,
    closeModal: closeEmailForm,
  } = useModal();

  const { refetch, hasScheduledEmails, maxDate, minDate } =
    useScheduledEmailContext();
  const { getValues, setValue } = useFormContext();

  const DEFAULT_EMAIL_BODY = useMemo(
    () =>
      generateJSON(
        t("events.edit_modal.email.email_body_default_text", {
          event_name: event?.name,
        }).replace(/[{}]/g, match => `${match}${match}`),
        [Document, Paragraph, Text, Bold, Heading],
      ),
    [event?.name],
  );

  const oneHourBefore = addSecondsToDate(
    event?.event_setting_attributes?.starts_at?.toString(),
    -ONE_HOUR_IN_SECONDS,
  );

  const scheduledEmail: ScheduledEmail = {
    record_id: String(event?.id),
    scheduled_email_type: "event",
    scheduled_at: oneHourBefore,
    subject: t("events.edit_modal.email.default_subject").replace(
      /[{}]/g,
      match => `${match}${match}`,
    ),
    email_body: { body: DEFAULT_EMAIL_BODY },
    enabled: isPublished(event),
    metadata: {
      send_to: "going",
      email_type: "reminder",
    },
  };

  const onClose = async () => {
    closeEmailForm();

    const scheduledEmailResponse = await refetch();

    if (scheduledEmailResponse.data?.records?.length === 0)
      setValue(EMAIL_REMINDER_NAME, false, {
        shouldDirty: true,
      });
  };

  let isEmailReminderEnabled = getValues(EMAIL_REMINDER_NAME);

  const handleEmailReminderToggle = async () => {
    // await for the event loop to finish before checking the email reminder value
    // This is a hack to avoid getting the old value of the email reminder
    await new Promise(resolve => setTimeout(resolve, 0));
    isEmailReminderEnabled = getValues(EMAIL_REMINDER_NAME);
    if (!isEmailReminderEnabled) return;
    if (hasScheduledEmails()) return;

    openEmailForm();
  };

  return (
    <div className="mb-6 md:mb-12">
      <Typography.TitleXl>
        {t("events.edit_modal.email.long_title")}
      </Typography.TitleXl>
      <div className="md:my-3">
        <FormItem
          name="event_setting_attributes.send_in_app_notification_reminder"
          tooltipText={t(
            "events.edit_modal.event_setting_attributes.send_in_app_notification_reminder_tooltip",
          )}
          inlineReverse
          className="!pb-0"
        >
          <Form.ToggleSwitch
            name="event_setting_attributes.send_in_app_notification_reminder"
            variant="small"
          />
        </FormItem>
        <FormItem
          name={EMAIL_REMINDER_NAME}
          tooltipText={t(
            "events.edit_modal.event_setting_attributes.send_scheduled_email_tooltip",
          )}
          inlineReverse
          className="!pb-0 [&>p]:absolute [&>p]:left-0 [&>p]:top-full [&>p]:!mt-0"
          rules={{
            validate: {
              bounds: (value: boolean) => {
                if (value && !hasScheduledEmails()) {
                  return t("events.scheduled_email_error");
                }
                return true;
              },
            },
          }}
        >
          <Form.ToggleSwitch
            name={EMAIL_REMINDER_NAME}
            variant="small"
            onClick={handleEmailReminderToggle}
          />
        </FormItem>
      </div>

      <div className="mt-6 md:mb-3 md:mt-8">
        <Typography.TitleMd>
          {t("events.edit_modal.email.schedule")}
        </Typography.TitleMd>
      </div>

      <ScheduledEmailList />

      <Button
        type="button"
        variant="secondary"
        onClick={openEmailForm}
        className="mt-4 min-w-[96px]"
        disabled={!isEmailReminderEnabled}
      >
        {t("events.edit_modal.email.add_reminder")}
      </Button>
      <ScheduledEmailModal
        isOpen={isEmailFormOpen}
        onClose={onClose}
        scheduledEmail={scheduledEmail}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};
