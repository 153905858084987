import { t } from "i18n-js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCommentHierarchyKey } from "@circle-react/components/CommentsV3/CommentHierarchy/useCommentHierarchy";
import { getCommentsListKey } from "@circle-react/components/CommentsV3/CommentsList/useCommentsList";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { findMatchingQueryKeysForPostId } from "@circle-react/contexts/postsDetailsContext";
import type { FlaggedContent } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useModerationApi } from "./useModerationApi";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const useAproveRejectContent = (
  flaggedContentId: string,
  flaggedContentType: "post" | "comment",
) => {
  const { success, error } = useToast();
  const { get, approve, reject } = useModerationApi();
  const queryClient = useQueryClient();

  const { data: flaggedContent, isLoading } = useQuery<
    void,
    ApiError,
    FlaggedContent
  >(
    ["flagged-content", flaggedContentId, flaggedContentType],
    () => get(flaggedContentId, flaggedContentType),
    {
      enabled: !!flaggedContentId,
    },
  );

  const contentId = flaggedContent?.id;

  const refetchRecord = () => {
    if (flaggedContentType === "post") {
      const matchingQueryKeys = findMatchingQueryKeysForPostId({
        postId: flaggedContentId,
        queryClient,
      });
      matchingQueryKeys.forEach((key: any) => {
        void queryClient.invalidateQueries(key);
      });
    } else if (flaggedContentType === "comment") {
      void queryClient.refetchQueries(
        getCommentsListKey({
          postId: flaggedContent?.flagged_contentable?.post_id,
          parentCommentId:
            flaggedContent?.flagged_contentable?.parent_comment_id,
        }),
      );
      void queryClient.refetchQueries(
        getCommentHierarchyKey({
          postId: flaggedContent?.flagged_contentable?.post_id,
          id: flaggedContentId,
        }),
      );
    }
  };

  const { mutate: approveFlaggedContent, isLoading: isApproving } = useMutation<
    void,
    ApiError,
    void
  >({
    mutationFn: () => approve(String(contentId)),
    onSuccess: () => {
      refetchRecord();
      success(t([i18nRoot, "success_approving"]));
    },
    onError: () => error(t([i18nRoot, "error_approving"])),
  });

  const { mutate: rejectFlaggedContent, isLoading: isRejecting } = useMutation<
    void,
    ApiError,
    void
  >({
    mutationFn: () => reject(String(contentId)),
    onSuccess: () => {
      refetchRecord();
      success(t([i18nRoot, "success_rejecting"]));
    },
    onError: () => error(t([i18nRoot, "error_rejecting"])),
  });

  return {
    approveFlaggedContent,
    isApproving,
    rejectFlaggedContent,
    isRejecting,
    isLoading,
    flaggedContent,
  };
};
