import { delay } from "lodash";
import { useHistory } from "react-router-dom";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";

export const useThreads = () => {
  const history = useHistory();
  const { openThreadsRailbar, setMessageHighlightId, messageHighlightId } =
    useRailbar();
  const { message_id: messageThreadId } = useRouterQueryParams();
  const removeThreadIdUrlParam = () => {
    if (messageHighlightId) {
      history.replace({
        search: "",
        hash: window.location.hash,
      });
      setMessageHighlightId(null);
    }
  };

  const openThreadsRailbarOnThreadsNotification = ({
    messageHashId,
    chatRoomMessages,
    chatRoom,
  }) => {
    if (messageThreadId) {
      const parentMessage = chatRoomMessages.find(
        message => message.id === Number(messageHashId),
      );
      if (parentMessage) {
        delay(() => {
          setMessageHighlightId(Number(messageThreadId));
          openThreadsRailbar(parentMessage, chatRoom);
        }, 500);
      }
    }
  };

  return { removeThreadIdUrlParam, openThreadsRailbarOnThreadsNotification };
};
