import { memo, useMemo } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { t } from "i18n-js";
import { eventLocation } from "@circle-react/helpers/postHelpers";
import { Button } from "@circle-react-uikit/Button";
import "./styles.scss";

const TARGET_ZOOM = 15;
const libraries = ["places"];
const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  scrollWheel: false,
  fullscreenControl: false,
  panControl: false,
  rotateControl: false,
  gestureHandling: "none",
  clickableIcons: false,
  controlSize: 25,
  disableDoubleClickZoom: true,
  draggableCursor: "arrow",
  draggingCursor: "arrow",
  keyboardShortcuts: false,
};

const locationFromPlace = place => {
  if (place?.geometry) {
    return {
      lat: place?.geometry?.location?.lat,
      lng: place?.geometry?.location?.lng,
    };
  }
  return null;
};

const InPersonLocationComponent = ({ serializedLocation }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: window.GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const place = useMemo(
    () => eventLocation(serializedLocation),
    [serializedLocation],
  );
  const location = useMemo(() => locationFromPlace(place), [place]);
  const handleViewMapClick = () => {
    window.open(place.url, "_blank");
  };

  return isLoaded ? (
    <div className="in-person-location">
      {location && (
        <GoogleMap
          mapContainerClassName="in-person-location__map-container"
          zoom={TARGET_ZOOM}
          options={mapOptions}
          center={location}
        >
          <Marker cursor="arrow" position={location} />
        </GoogleMap>
      )}
      <div className="in-person-location__location">
        <div className="info">
          {place?.name && <p className="name">{place?.name}</p>}
          {place.formatted_address && <p>{place.formatted_address}</p>}
        </div>
        {place.url && (
          <div className="view-map-button">
            <Button variant="secondary" onClick={handleViewMapClick} full>
              {t("events.rsvp.view_map")}
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export const InPersonLocation = memo(InPersonLocationComponent);
