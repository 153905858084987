import { useCallback } from "react";
import { t } from "i18n-js";
import { useHistory, useLocation } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { memberProfileModalPathsWithState } from "@circle-react/helpers/urlHelpers";
import { useAdminAudienceEditModal } from "../useAdminAudienceEditModal";

export const useShowProfileEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const showAdminAudienceEditModal = useAdminAudienceEditModal();

  const showOwnProfileEdit = useCallback(() => {
    history.push(memberProfileModalPathsWithState.profile(location));
  }, [history, location]);

  const showMemberProfileEdit = useCallback(
    props => {
      console.assert(isAdmin, {
        message: t("show_member_profile_no_admin"),
        currentCommunityMember,
      });

      const { member, memberPublicId } = props;

      const getPublicUid = () => {
        if (memberPublicId) {
          return memberPublicId;
        }
        return member?.public_uid;
      };

      const publicId = getPublicUid();
      const audienceType = member?.type ?? "CommunityMember";

      return showAdminAudienceEditModal({ publicId, type: audienceType });
    },
    [currentCommunityMember, isAdmin, showAdminAudienceEditModal],
  );

  return {
    showOwnProfileEdit,
    showMemberProfileEdit,
  };
};
