import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { MINIMUM_REQUIRED_PLAN_TIER } from "@circle-react/components/SettingsApp/CommunityAi/constants";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { BigLoader } from "@circle-react-shared/BigLoader";
import { useFetchPlan } from "@circle-react-shared/UpgradePlan/hooks/useFetchPlan";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ACTIVITY_SCORES_FEATURE } from "./constants";
import type { FeatureType } from "./constants";

interface CommunityAiUpgradeModalComponentProps {
  afterClose?: () => void;
  feature?: FeatureType;
  source?: string;
  onSuccess?: () => void;
}

export const CommunityAiUpgradeModalComponent = ({
  afterClose,
  feature = ACTIVITY_SCORES_FEATURE,
  source,
  onSuccess,
}: CommunityAiUpgradeModalComponentProps) => {
  const { changePlanModalParams } = usePlanStatus();
  const modal = useModal();
  const planTierOrId = changePlanModalParams(MINIMUM_REQUIRED_PLAN_TIER);
  const { planTier, planId } = planTierOrId;

  const { plan, isPlanLoading } = useFetchPlan({
    planTier,
    planId,
  });

  const handleClose = async () => {
    afterClose && afterClose();
    await modal.hide();
    modal.remove();
  };

  return (
    <Modal
      title={`${t("settings.community_ai.marketing_modal.title.unlock")} ${t(
        "settings.community_ai.marketing_modal.title.community_ai",
      )}`}
      isOpen={modal.visible}
      onClose={handleClose}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        {isPlanLoading ? (
          <div className="my-28">
            <BigLoader />
          </div>
        ) : (
          <>
            <ModalHeader handleClose={handleClose} />
            <ModalBody feature={feature} />
            <ModalFooter
              handleClose={handleClose}
              plan={plan}
              source={source}
              onSuccess={onSuccess}
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};
