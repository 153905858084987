import { useLocation } from "react-router-dom";
import { COMMENT_ANCHOR_PREFIX } from "./constants";

/**
 * @deprecated
 * Please use the new useCommentFromLocationHash hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useCommentFromLocationHash = () => {
  const { hash } = useLocation();
  const commentIdFromHash = hash.split(COMMENT_ANCHOR_PREFIX)[1];
  const commentId =
    (commentIdFromHash && parseInt(commentIdFromHash, 10)) || null;

  return { commentId };
};
