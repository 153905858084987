import { Icon } from "@circle-react/components/shared/Icon";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import type { Event } from "@circle-react/types/Event";
import { AddToCalendar } from "@circle-react-uikit/AddToCalendar";
import { Typography } from "@circle-react-uikit/Typography";
import { addToCalendarHelper } from "../../helpers/addToCalendarHelper";

interface ConfirmationDateProps {
  event: Event;
  label: string;
}

export const ConfirmationDate = ({ event, label }: ConfirmationDateProps) => {
  const { selfUpdateCalendarEnabled } = useEventsFeatureFlags();
  const { event_setting_attributes: eventData } = event || {};

  const {
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone: timeZone,
  } = eventData || {};

  const { dayOfMonth, shortMonthString } = dateComponents(startsAt, timeZone);
  const startTime = dateToTimeString(startsAt, timeZone);
  const endTime = dateToTimeString(endsAt, timeZone);

  return (
    <>
      {!selfUpdateCalendarEnabled && (
        <div className="bg-primary border-primary flex w-full rounded-md border shadow">
          <div className="event-confirmation__date-picker">
            <Icon type="calendar" className="icon" />
            {`${dayOfMonth} ${shortMonthString}, ${startTime} - ${endTime}`}
          </div>
          <div className="event-confirmation__date-button">
            <AddToCalendar {...addToCalendarHelper(event, label)} />
          </div>
        </div>
      )}
      {selfUpdateCalendarEnabled && (
        <div className="bg-tertiary flex w-fit items-center justify-center rounded-md">
          <div className="flex px-4 py-2">
            <Icon
              type="16-calendar"
              className="text-default mr-2 h-4 !w-4 font-medium"
              size={16}
            />
            <Typography.LabelSm weight="medium" color="text-default">
              {`${dayOfMonth} ${shortMonthString}, ${startTime} - ${endTime}`}
            </Typography.LabelSm>
          </div>
        </div>
      )}
    </>
  );
};
