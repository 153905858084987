import { useState } from "react";
import { t } from "i18n-js";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { WarningNotice } from "@circle-react-uikit/Notice/WarningNotice";
import { Typography } from "@circle-react-uikit/Typography";
import { ModerationInfoModal } from "./ModerationInfoModal";

interface ModerationInfoProps {
  record: { unresolved_flagged_reports_count: number; id: string };
  contentType: "post" | "comment";
}

export const ModerationInfo = ({
  record,
  contentType,
}: ModerationInfoProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { unresolved_flagged_reports_count } = record;

  if (!unresolved_flagged_reports_count) return null;

  const contentTranslation =
    contentType === "post"
      ? t("flagged_contents.moderation_info.post")
      : t("flagged_contents.moderation_info.comment");

  return (
    <>
      <WarningNotice
        className="mb-4 !py-2"
        iconEl={<Icon className="text-v2-warning" type="20-info" size={20} />}
      >
        <div className="flex w-full items-center justify-between">
          <Typography.LabelSm>
            {t("flagged_contents.moderation_info.flagged_report_content", {
              content: contentTranslation,
            })}
            <SanitizeHTMLString
              content={t(
                "flagged_contents.moderation_info.flagged_report_count",
                {
                  count: unresolved_flagged_reports_count,
                },
              )}
              as="span"
            />
          </Typography.LabelSm>
          <button
            type="button"
            onClick={openModal}
            className="hover:bg-v2-warning-100 dark:hover:bg-primary rounded-full border border-black bg-transparent px-4 py-1 transition dark:border-white"
          >
            <Typography.LabelSm weight="semibold">
              {t("flagged_contents.moderation_info.review_button")}
            </Typography.LabelSm>
          </button>
        </div>
      </WarningNotice>
      {isModalOpen && (
        <ModerationInfoModal
          flaggedContentId={record.id}
          flaggedContentType={contentType}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </>
  );
};
