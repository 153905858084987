import { t } from "i18n-js";
import { PLATFORMS } from "./communityHelpers";
import { PaymentMethodType } from "./paywallCheckoutHelpers";

export const STATUS = {
  INCOMPLETE: "incomplete",
  PROCESSING: "processing",
  PAID: "paid",
  CANCELED: "canceled",
  REFUNDED: "refunded",
  FAILED: "failed",

  // This is not a official status and it is used on Admin Paywall Billing
  PARTIAL_REFUNDED: "partial_refunded",
};

export const STATUS_PRESENTER = {
  PARTIAL_REFUND: t(
    "community_member_charge_presenter.display_status.partial_refund",
  ),
  INCOMPLETE: t("community_member_charge_presenter.display_status.incomplete"),
  PROCESSING: t("community_member_charge_presenter.display_status.processing"),
  PAID: t("community_member_charge_presenter.display_status.paid"),
  CANCELED: t("community_member_charge_presenter.display_status.canceled"),
  REFUNDED: t("community_member_charge_presenter.display_status.refunded"),
  FAILED: t("community_member_charge_presenter.display_status.failed"),
};

export const SEARCHABLE_STATUS = [
  STATUS.PAID,
  STATUS.PARTIAL_REFUNDED,
  STATUS.REFUNDED,
  STATUS.FAILED,
];

export const isRefundedOrPartialRefunded = charge =>
  charge?.status === STATUS.REFUNDED || isPartialRefund(charge);

export const isPaid = charge => charge?.status === STATUS.PAID;

export const isPartialRefund = charge =>
  charge?.status !== STATUS.REFUNDED && charge?.amount_refunded_raw > 0;

export const isFreeCharge = charge => charge?.amount_raw === 0;

export const isPaymentMethodTypePix = charge =>
  charge?.payment_method_type === PaymentMethodType.PIX;
export const isPaymentMethodTypeCard = charge =>
  charge?.payment_method_type === PaymentMethodType.CARD;
export const isPaymentMethodTypeGooglePay = charge =>
  charge?.payment_method_type === PaymentMethodType.GOOGLE_PAY;
export const isPaymentMethodTypeApplePay = charge =>
  charge?.payment_method_type === PaymentMethodType.APPLE_PAY;
export const isPaymentMethodTypeLink = charge =>
  charge?.payment_method_type === PaymentMethodType.LINK;

export const isPaymentMethodTypeNull = charge =>
  charge?.payment_method_type === null;

export const doesBelongToMobilePlatform = charge =>
  [PLATFORMS.APP_STORE, PLATFORMS.PLAY_STORE].includes(charge?.platform);
