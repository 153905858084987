import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateToPost } from "../PostModal/hooks/useNavigateToPost";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import type {
  MinimalTipTapContentResult,
  UseMinimalTipTapContentProps,
} from "./types";
import { isContentEmpty } from "./utils/nodeUtils";
import { truncateContentWithMediaHandling } from "./utils/truncateContentWithMediaHandling";

export const useMinimalTipTapContent = ({
  params = {},
  hasNextPage = false,
}: UseMinimalTipTapContentProps): MinimalTipTapContentResult => {
  const { post } = useCurrentPostHelpers();
  const { navigateToPost } = useNavigateToPost();
  const location = useLocation();

  const {
    truncatedContent,
    mediaContent,
    hasCharacterLimitReached,
    hasMediaAttachmentsBeforeTruncation,
  } = useMemo(
    () => truncateContentWithMediaHandling(post?.tiptap_body?.body?.content),
    [post?.tiptap_body?.body?.content],
  );

  const truncatedContentJSON = useMemo(
    () => ({ type: "doc", content: truncatedContent }),
    [truncatedContent],
  );
  const mediaContentJSON = useMemo(
    () => (mediaContent ? { type: "doc", content: [mediaContent] } : null),
    [mediaContent],
  );

  const isMediaContentEmpty = useMemo(
    () => isContentEmpty(mediaContentJSON),
    [mediaContentJSON],
  );
  const isTruncatedContentEmpty = useMemo(
    () => isContentEmpty(truncatedContentJSON),
    [truncatedContentJSON],
  );

  const shouldShowSeeMoreButton =
    hasCharacterLimitReached && truncatedContent && !isTruncatedContentEmpty;

  const shouldRenderTruncatedContent =
    truncatedContent && !isTruncatedContentEmpty;

  const shouldRenderMediaContent =
    !hasMediaAttachmentsBeforeTruncation &&
    !!mediaContent &&
    !isMediaContentEmpty;

  const redirectToPostModal = useCallback(() => {
    navigateToPost({
      newPost: post,
      prevLocation: location,
      params,
      hasNextPage,
    });
  }, [post, location, params, hasNextPage, navigateToPost]);

  return {
    truncatedContent: truncatedContentJSON,
    mediaContent: mediaContentJSON,
    redirectToPostModal,
    tiptapBody: post?.tiptap_body,
    shouldShowSeeMoreButton,
    shouldRenderTruncatedContent,
    shouldRenderMediaContent,
  };
};
