import { Combobox } from "@headlessui/react";
import { t } from "i18n-js";

export const TopicInput = ({
  targetElement,
  filter,
  maxTopicsAllowed,
  setFilter,
  buttonElement,
  activeOption,
  createTopicMutation,
  resetSelectedTopics,
  removeLastTopic,
}: any) => (
  <Combobox.Input
    className="min-w-[200px] flex-1 border-none bg-transparent p-0 px-1 py-[5px] text-sm focus:ring-0"
    ref={targetElement}
    value={filter}
    placeholder={t("quick_post_v2.topics.search", {
      count: maxTopicsAllowed,
    })}
    onChange={event => setFilter(event.target.value)}
    onFocus={() => buttonElement?.current?.click()}
    onKeyDown={(event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "Backspace") {
        resetSelectedTopics();
      }
      if (event.key === "Backspace" && filter?.length === 0) {
        removeLastTopic();
      }
      if (
        event.key === "Enter" &&
        filter?.trim() &&
        activeOption === "create"
      ) {
        event.preventDefault();
        if (!createTopicMutation.isLoading) {
          createTopicMutation.mutate(filter);
        }
      }
    }}
  />
);
