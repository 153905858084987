import classNames from "classnames";
import { useLevelColors } from "@circle-react/components/Leaderboard/colorHelper";
import { t } from "@circle-react/custom_i18n";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LevelBadge = ({
  level,
  levelName,
}: {
  level: number;
  levelName?: string;
}) => {
  const levelColors = useLevelColors(level);
  return (
    <div
      className={classNames(
        "mb-2 inline-flex items-center space-x-3 rounded-full px-3 py-1",
        levelColors.bg,
        levelColors.text,
      )}
    >
      <Typography.LabelSm weight="semibold" color="text-inherit">
        <span className="mr-2">{t("leaderboard.trophy_emoji")}</span>
        {t("leaderboard.level_number_only", {
          level,
        })}
      </Typography.LabelSm>
      <div className={classNames("h-3 w-px rounded-md", levelColors.divider)} />
      <Typography.LabelSm weight="semibold" color="text-inherit">
        {levelName ||
          t("leaderboard.level_n", {
            level,
          })}
      </Typography.LabelSm>
    </div>
  );
};
