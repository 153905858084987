import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useCurrentPostContext } from "@circle-react/contexts";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Comment } from "../Comment";
import { useJumpToComment } from "../useJumpToComment";
import { useCommentHierarchy } from "./useCommentHierarchy";

export const CommentHierarchy = ({
  space,
  post,
  id,
  showReplyForm = false,
  autofocusReplyForm = false,
  onCommentDelete,
  onReplyCreate,
  onReplyDelete,
}) => {
  const { usedIn } = useCurrentPostContext();
  const { comment, isLoading, updateQueryData } = useCommentHierarchy({
    postId: post.id,
    id: id,
    postCommentsClosed: post.is_comments_closed,
    postLikingDisabled: post.is_liking_disabled,
    usedIn,
  });

  useEffect(() => {
    if (!isLoading) {
      // Ensures that tooltips work in the engagement menu, which is currently
      // rendered on the BE.
      window.initialiseTippy();
    }
  }, [isLoading]);

  useJumpToComment({ enabled: !isLoading });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (!comment) {
    return (
      <div className="post__comment-not-found border-primary text-dark dark:text-lightest bg-v2-warning-100 rounded-b-lg border-t p-2.5 py-4 text-base md:p-5">
        {t("comments.hierarchy.not_found")}
      </div>
    );
  }

  return (
    <Comment
      space={space}
      post={post}
      comment={comment}
      showReplyForm={showReplyForm}
      autofocusReplyForm={autofocusReplyForm}
      onUpdate={updateQueryData}
      onDelete={onCommentDelete}
      onReplyCreate={onReplyCreate}
      onReplyDelete={onReplyDelete}
    />
  );
};

CommentHierarchy.propTypes = {
  space: PropTypes.object,
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    space_slug: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    is_comments_closed: PropTypes.bool.isRequired,
    is_liking_disabled: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  showReplyForm: PropTypes.bool,
  autofocusReplyForm: PropTypes.bool,
  onCommentDelete: PropTypes.func.isRequired,
  onReplyCreate: PropTypes.func.isRequired,
  onReplyDelete: PropTypes.func.isRequired,
};
