import type { ReactNode } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { PostShareDropdown } from "@/react/components/PostsV3/PostViewListItem/PostShareDropdown";
import { useCurrentPostHelpers } from "@/react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import type { Post } from "@circle-react/types/Post";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

export interface PostShareOptionsProps {
  onChange?: (data: Post) => void;
  button?: ReactNode;
  shouldRenderCustomMenuButton?: boolean;
  menuButtonEl?: "button" | "div";
  appendTo?: Element;
}

export const PostShareOptions = ({
  onChange,
  button,
  shouldRenderCustomMenuButton,
  menuButtonEl = "button",
  appendTo,
}: PostShareOptionsProps) => {
  const { showShareOnWebOptions, post, getSharePostLink, sharePostData } =
    useCurrentPostHelpers({ onChangeSharePost: onChange });

  const { data: space } = useCurrentSpaceContext();

  const Component: ReactNode = button ?? <Icon type="16-share" size={20} />;

  if (!showShareOnWebOptions(space)) {
    return null;
  }

  return (
    <Dropdown
      size="extra-large"
      direction="bottom-end"
      menuButtonEl={menuButtonEl}
      className="w-full"
      appendTo={appendTo}
      buttonClassName={classnames(
        "w-full focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1",
        {
          "p-1 hover:bg-tertiary rounded group flex items-center":
            !shouldRenderCustomMenuButton,
        },
      )}
      button={
        shouldRenderCustomMenuButton ? (
          Component
        ) : (
          <Typography.LabelSm
            weight="medium"
            color="text-default group-hover:text-dark"
          >
            {t("post.share.title")}
          </Typography.LabelSm>
        )
      }
    >
      <PostShareDropdown
        post={post}
        getSharePostLink={getSharePostLink}
        sharePostData={sharePostData}
      />
    </Dropdown>
  );
};
