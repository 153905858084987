import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import CommentsV2 from "@circle-react/components/CommentsV2";
import { useCommentFromLocationHash } from "@circle-react/components/CommentsV2/useCommentFromLocationHash";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { toLocaleString } from "@circle-react/helpers/number";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { CommentsClosedBanner } from "./CommentsClosedBanner";
import { GatedComments } from "./GatedComments";
import { LeaveCommentButton } from "./LeaveCommentButton";

/**
 * @deprecated
 * Please use the new CommentsSection component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentsSection = ({ autofocusReplyForm, commentsFormRef }) => {
  const {
    post,
    showGatedCommentSectionAtBottom,
    showGatedCommentSectionAtTop,
    shouldShowLeaveCommentButton,
    shouldHideCommentsListForSpace,
    refetchPostDetails,
    updatePostDetailsQueryCache,
  } = useCurrentPostHelpers();
  const { commentId } = useCommentFromLocationHash();
  const [linkedCommentId, setLinkedCommentId] = useState(commentId);
  const [showCommentHierarchy, setShowCommentHierarchy] = useState(
    commentId && post.comments_count > 10,
  );
  const { data: space } = useCurrentSpaceContext();
  const { currentCommunityMember } = usePunditUserContext();
  const { is_comments_closed: isCommentsClosed } = post;
  const onChange = () => {
    refetchPostDetails && refetchPostDetails();
  };

  const incrementCommentsCount = () => {
    updatePostDetailsQueryCache &&
      updatePostDetailsQueryCache({
        comments_count: post.comments_count + 1,
      });
  };
  const navigateToAllComments = () => {
    setShowCommentHierarchy(false);
    setLinkedCommentId(null);

    // TODO: Handle in a react/router way
    window.location.hash = "#all_comments";
  };

  const triggerOnChangeAndNavigateToAllComments = () => {
    onChange();
    navigateToAllComments();
  };

  const handleShowAllCommentsClick = event => {
    event.preventDefault();
    navigateToAllComments();
  };

  const shouldHideCommentsList = shouldHideCommentsListForSpace({ space });

  useEffect(() => {
    setLinkedCommentId(commentId);
  }, [commentId]);

  const shouldShowGatedComments = !(
    shouldShowLeaveCommentButton || isEventPost(post)
  );

  return (
    <div
      className="post__comments trix-v2"
      data-controller="comments"
      id="all_comments"
      data-post-id={post.id}
    >
      {isCommentsClosed && <CommentsClosedBanner className="m-4" />}
      {shouldShowLeaveCommentButton && <LeaveCommentButton post={post} />}
      {shouldShowGatedComments && showGatedCommentSectionAtTop && (
        <GatedComments post={post} space={space} />
      )}
      {!shouldHideCommentsList && showCommentHierarchy && (
        <>
          <div className="show-all-comments__wrapper mx-[26px] mb-4 mt-[26px] !p-0">
            <a
              className="show-all-comments__link btn btn-secondary"
              href="#all_comments"
              onClick={handleShowAllCommentsClick}
            >
              <Icon type="arrow-solid-top-left" />
              {t("post.comments_section.show_all_count_comments", {
                count: post.comments_count,
                formatted_count: toLocaleString(post.comments_count),
              })}
            </a>
          </div>
          <CommentsV2.CommentHierarchy
            communityMember={currentCommunityMember}
            space={space}
            post={post}
            id={linkedCommentId}
            showReplyForm={!isCommentsClosed}
            autofocusReplyForm={autofocusReplyForm}
            onCommentDelete={triggerOnChangeAndNavigateToAllComments}
            onReplyCreate={incrementCommentsCount}
            onReplyDelete={onChange}
          />
        </>
      )}
      {!shouldHideCommentsList && !showCommentHierarchy && (
        <CommentsV2.CommentsList
          communityMember={currentCommunityMember}
          space={space}
          post={post}
          showReplyForm={!isCommentsClosed}
          autofocusReplyForm={autofocusReplyForm}
          formRef={commentsFormRef}
          onCommentCreate={incrementCommentsCount}
          onCommentDelete={onChange}
        />
      )}
      {shouldShowGatedComments && showGatedCommentSectionAtBottom && (
        <GatedComments post={post} space={space} />
      )}
    </div>
  );
};

CommentsSection.propTypes = {
  autofocusReplyForm: PropTypes.bool,
  commentsFormRef: PropTypes.node,
  onChange: PropTypes.func,
};
