import { startOfToday, startOfYesterday } from "date-fns";
import { t } from "i18n-js";
import { formattedDateTime } from "./formattedDateTime";

export const formattedMessageSentAt = (sent_at: string) => {
  const sentAtDateTime = new Date(sent_at);
  const currentTime = new Date();
  const todayMidnightDate = startOfToday();
  const yesterdayMidnightDate = startOfYesterday();

  let result = "";

  if (sentAtDateTime.getFullYear() === currentTime.getFullYear()) {
    if (sentAtDateTime > todayMidnightDate) {
      result = t("today");
    } else if (sentAtDateTime > yesterdayMidnightDate) {
      result = t("yesterday");
    } else {
      result = formattedDateTime({
        dateTime: sentAtDateTime,
        format: "short_month_and_date",
      });
    }
  } else {
    result = formattedDateTime({
      dateTime: sentAtDateTime,
      format: "short_date",
    });
  }

  return {
    day: result,
    time: formattedDateTime({
      dateTime: sentAtDateTime,
      format: "short_time",
    }),
  };
};
