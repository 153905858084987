import { useState } from "react";
import { t } from "i18n-js";
import { find } from "lodash";
import { useFormContext } from "react-hook-form";
import { DatePicker } from "@/react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import { useImagePostModalStore } from "@/react/components/Spaces/ImageSpace/ImagePostModal/store";
import { useTailwindLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { PostSubmitButton } from "@circle-react/components/QuickPostV2/Content/Footer/PostSubmitButton";
import { ScheduledBar } from "@circle-react/components/QuickPostV2/Content/Footer/ScheduledBar";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPublishedPost } from "@circle-react/helpers/postHelpers";
import { userIsAdminOrModeratorOfSpace } from "@circle-react/helpers/spaceHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ActionSelectorButton } from "@circle-react-uikit/ActionSelectorButton";
import type { Option } from "@circle-react-uikit/ActionSelectorButton";

export const Actions = ({ post }: any) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isTopicsEnabled = currentCommunitySettings?.topics_enabled;
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const shouldUseTwoLinesVariant = useTailwindLgScreenMediaQuery();
  const { watch, setValue } = useFormContext();
  const { isSubmitting, uploadedImages, isScheduledBarPortalRendered } =
    useImagePostModalStore();
  const { data: space } = useCurrentSpaceContext();
  const { published_at: publishedAt, status, topics } = watch();
  const isTopicSelectionRequired =
    isTopicsEnabled &&
    space?.require_topic_selection &&
    Number(topics?.length) <= 0;
  const isSubmitEnabled =
    uploadedImages.length > 0 && !isSubmitting && !isTopicSelectionRequired;
  const isEdit = !!post?.id;
  const isPublished = isPublishedPost(post);

  const translationKey = `quick_post.scheduled_post_ui.${
    isSubmitting ? "submitting_button" : "submit_button"
  }`;

  const options: Option[] = [
    {
      icon: "16-download",
      label: t(`${translationKey}.save_draft`),
      value: "draft",
    },
    {
      icon: "16-calendar",
      label: t(`${translationKey}.schedule_post`),
      value: "scheduled",
    },
    {
      icon: "16-publish",
      label: t(`${translationKey}.publish`),
      value: "published",
    },
  ];

  const selectedAction = find(options, { value: status || "published" });

  const setSelectedAction = (action: any) => {
    if (action.value === "scheduled") {
      setIsDatePickerVisible(true);
    } else {
      setValue("status", action.value);
      setIsDatePickerVisible(false);
      updatePublishedAt(null);
    }
  };

  const updatePublishedAt = (date: any) => {
    setValue("published_at", date);
  };

  const onScheduleDateChange = (date: any) => {
    updatePublishedAt(date);
    setValue("status", "scheduled");
    setIsDatePickerVisible(false);
  };

  const onCancelClick = (event: any) => {
    event && event.preventDefault();
    setIsDatePickerVisible(false);
    if (status === "scheduled") {
      revertToDraftState();
    }
  };

  const revertToDraftState = () => {
    setSelectedAction(options[0]);
  };

  const shouldShowActionSelector =
    space && userIsAdminOrModeratorOfSpace(space) && !isPublished;

  return (
    <>
      {isScheduledBarPortalRendered && (
        <ScheduledBar
          publishedAt={publishedAt}
          revertToDraftState={revertToDraftState}
          status={status}
          updatePublishedAt={updatePublishedAt}
          variant={shouldUseTwoLinesVariant ? "2-lines" : "default"}
        />
      )}
      <DatePicker
        datePickerVisible={isDatePickerVisible}
        onCancel={onCancelClick}
        value={publishedAt}
        onSave={onScheduleDateChange}
        placement="bottom-end"
        saveButtonVariant="circle"
      >
        {shouldShowActionSelector ? (
          <TippyV2
            disabled={!isTopicSelectionRequired}
            content={t("quick_post_v2.error_messages.no_topics")}
            trigger="mouseenter click focus focusin"
            placement="bottom"
          >
            <ActionSelectorButton
              type="submit"
              disabled={!isSubmitEnabled}
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
              options={options}
              variant="circle"
            />
          </TippyV2>
        ) : (
          <TippyV2
            disabled={!isTopicSelectionRequired}
            content={t("quick_post_v2.error_messages.no_topics")}
            trigger="mouseenter click focus focusin"
            placement="bottom"
          >
            <PostSubmitButton isEdit={isEdit} isDisabled={!isSubmitEnabled} />
          </TippyV2>
        )}
      </DatePicker>
    </>
  );
};
