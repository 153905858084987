import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";

export const RsvpLimit = () => (
  <div className="w-[100px]">
    <Form.Item
      name="event_setting_attributes.rsvp_limit"
      hideLabel
      hideBorder
      placeholder={t(
        "events.edit_modal.event_setting_attributes.rsvp_limit_placeholder",
      )}
      rules={{
        required: t(
          "events.edit_modal.event_setting_attributes.rsvp_limit_required",
        ),
        min: {
          value: 1,
          message: t(
            "events.edit_modal.event_setting_attributes.rsvp_limit_error",
          ),
        },
      }}
    >
      <Form.Input />
    </Form.Item>
  </div>
);
