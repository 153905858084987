import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { RSVP_STATUS } from "@circle-react/components/Events/helpers/constants";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { Button } from "@circle-react-uikit/Button";
import { CheckIcon } from "./RsvpIcons";

export const RsvpButton = ({
  confirmed,
  full,
  toggleRsvp,
  declineInvite,
  rsvpStatus,
  loading,
  paidEvent,
  variant = "primary",
  isRsvpDisabled = false,
  detailsPage = false,
}) => {
  const isAttendeeInvited = rsvpStatus == RSVP_STATUS.INVITED;
  const { selfUpdateCalendarEnabled } = useEventsFeatureFlags();
  return (
    <>
      {isAttendeeInvited && (
        <div
          className={classNames("flex gap-x-2 gap-y-3", {
            "flex-wrap": detailsPage,
          })}
        >
          <Button
            variant="primary"
            full={full}
            onClick={toggleRsvp}
            disabled={loading}
            className={classNames("!font-semibold", {
              "!rounded-full": selfUpdateCalendarEnabled,
            })}
          >
            {t("events.rsvp.invite.accept")}
          </Button>
          <Button
            variant="secondary"
            full={full}
            onClick={declineInvite}
            disabled={loading}
            className={classNames("!ml-0 !font-semibold", {
              "!border-primary": variant === "secondary",
              "!rounded-full": selfUpdateCalendarEnabled,
            })}
          >
            {t("events.rsvp.invite.decline")}
          </Button>
        </div>
      )}
      {!confirmed && !isAttendeeInvited && (
        <Button
          variant={variant}
          full={full}
          onClick={toggleRsvp}
          disabled={loading || isRsvpDisabled}
          className={classNames("!font-semibold", {
            "!border-primary": variant === "secondary",
          })}
        >
          {paidEvent ? t("events.rsvp.buy_ticket") : t("events.rsvp.rsvp")}
        </Button>
      )}
      {confirmed && <CheckIcon />}
    </>
  );
};

RsvpButton.propTypes = {
  confirmed: PropTypes.bool,
  full: PropTypes.bool,
  toggleRsvp: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};
