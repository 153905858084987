import { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useBackendProps } from "@circle-react/providers";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { useMinimalTipTapContent } from "./useMinimalTipTapContent";

export const MinimalTiptapContent = () => {
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const { post } = useCurrentPostHelpers();
  const { params, hasNextPage } = usePostNavigationContext();

  const { internal_custom_html: html } = post;

  const {
    mediaContent,
    truncatedContent,
    shouldRenderTruncatedContent,
    shouldRenderMediaContent,
    tiptapBody,
    redirectToPostModal,
    shouldShowSeeMoreButton,
  } = useMinimalTipTapContent({
    params,
    hasNextPage,
  });

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  return (
    <div className="flex w-full flex-col gap-2">
      {shouldRenderTruncatedContent && (
        <TipTapBlockEditor
          readOnly
          disableMentionClicks={isMinimalLayout}
          value={truncatedContent}
          shouldRefetch
          sgidToObjectMap={tiptapBody.sgids_to_object_map}
          inlineAttachments={tiptapBody.inline_attachments}
        />
      )}
      {html && <InnerHTML key="internal_custom_html" html={html} />}
      {shouldShowSeeMoreButton && (
        <button
          type="button"
          className="text-light hover:text-dark focus-visible:text-dark inline-flex text-base"
          data-testid="see-more-post"
          onClick={redirectToPostModal}
        >
          {t("see_more")}
        </button>
      )}
      {shouldRenderMediaContent && (
        <TipTapBlockEditor
          readOnly
          disableMentionClicks={isMinimalLayout}
          sgidToObjectMap={tiptapBody.sgids_to_object_map}
          inlineAttachments={tiptapBody.inline_attachments}
          value={mediaContent}
          shouldRefetch
          className="w-full"
        />
      )}
    </div>
  );
};
