import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { PROFILE_MODAL_CONTAINER_ID } from "@circle-react/components/ProfileModalV3/Body";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { useProfileCommentsList } from "@circle-react/hooks/profile/useProfileCommentsList";
import EmptyState from "../EmptyState";
import { Comment } from "./Comment";

export const ProfileComments = () => {
  const { member, onClose, isFullPageView } = useProfileContext();
  const { fetchNextPage, comments, hasNextPage, isLoading } =
    useProfileCommentsList({
      memberPublicId: member.public_uid,
    });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (comments.length < 1)
    return (
      <EmptyState
        title={t("profile_drawer.comments.empty_state.title")}
        subtitle={t("profile_drawer.comments.empty_state.subtitle")}
      />
    );

  return (
    <InfiniteScroll
      style={{ overflow: "initial" }}
      scrollThreshold={0.5}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<LoaderRow />}
      dataLength={comments.length}
      scrollableTarget={isFullPageView ? "panel" : PROFILE_MODAL_CONTAINER_ID}
      className="divide-primary space-y-2 divide-y"
    >
      {comments.map(comment => (
        <Comment
          key={comment.id}
          onClose={() => onClose?.()}
          comment={comment}
          member={member}
        />
      ))}
    </InfiniteScroll>
  );
};
