import PropTypes from "prop-types";
import { t } from "i18n-js";
import { noop } from "lodash";
import { Icon } from "@circle-react/components/shared/Icon";
import {
  useSmOrMdOrLgScreenMediaQuery,
  useSmScreenMediaQuery,
} from "@circle-react/hooks/useMediaQuery";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = ({
  description,
  shouldHideMembersCount,
  memberCount = 0,
  onClose = noop,
}) => {
  const isSmOrMdScreen = useSmOrMdOrLgScreenMediaQuery();
  const isSmScreen = useSmScreenMediaQuery();

  if (isSmScreen) {
    return (
      <>
        <div className="border-primary border-b px-6 py-4">
          <IconButton name="16-arrow-left" onClick={onClose} />
        </div>
        <div className="px-6 py-4">
          <div className="flex items-center justify-between">
            <Typography.TitleSm as="h2" weight="semibold">
              {t("chat_space.details")}
            </Typography.TitleSm>
            {!shouldHideMembersCount && (
              <div className="flex items-center">
                <div className="text-default mr-2">
                  <Typography.LabelSm weight="medium">
                    {memberCount}
                  </Typography.LabelSm>
                </div>
                <Icon
                  type="20-members"
                  size={20}
                  className="text-dark"
                  useWithFillCurrentColor
                />
              </div>
            )}
          </div>
          {description && (
            <div className="text-default mt-4 max-h-[200px] overflow-y-auto whitespace-pre-line">
              <Typography.BodySm>{description}</Typography.BodySm>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full items-center justify-between">
        <Typography.TitleSm as="h2" weight="semibold">
          {t("chat_space.details")}
        </Typography.TitleSm>
        {isSmOrMdScreen ? (
          <button type="submit" onClick={onClose}>
            <Icon
              type="close-v2"
              className="text-dark mr-1 mt-2.5 cursor-pointer"
              useWithFillCurrentColor
            />
          </button>
        ) : (
          <>
            {!shouldHideMembersCount && (
              <div className="flex">
                <div className="text-dark mr-2">
                  <Typography.LabelSm weight="medium">
                    {memberCount}
                  </Typography.LabelSm>
                </div>
                <Icon type="space-chat-members" className="mt-1" />
              </div>
            )}
          </>
        )}
      </div>
      {description && (
        <div className="text-dark max-h-[200px] overflow-y-auto whitespace-pre-line">
          <Typography.BodySm>{description}</Typography.BodySm>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  shouldHideMembersCount: PropTypes.bool.isRequired,
  memberCount: PropTypes.number.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func,
};
