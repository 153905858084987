import { t } from "i18n-js";
import { memberPaths } from "@circle-react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-uikit/Button";
import { Icon } from "../shared/Icon";

interface EditProfileButtonProps {
  toggleDrawer: () => void;
  isFullPageProfile?: boolean;
  memberId: number;
}

export const EditProfileButton = ({
  toggleDrawer,
  isFullPageProfile,
  memberId,
}: EditProfileButtonProps) => {
  const handleClick = () => {
    if (isFullPageProfile) {
      window.location.href = memberPaths.edit({ memberId });
    } else {
      toggleDrawer();
    }
  };

  return (
    <TippyV2
      placement="bottom"
      content={t("post.share.edit_profile")}
      interactive={false}
    >
      <Button
        variant="secondary"
        onClick={handleClick}
        aria-label={t("post.share.edit_profile")}
      >
        <Icon type="pencil" useWithFillCurrentColor size={16} aria-hidden />
      </Button>
    </TippyV2>
  );
};
