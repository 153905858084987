import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptySearch = () => (
  <div className="bg-primary m-auto text-center">
    <div
      className="m-auto flex h-32 max-w-md flex-col items-center justify-center gap-4 p-8"
      data-testid="empty-search"
    >
      <Icon type="32-search-v2" className="text-default !h-8 !w-8" size={32} />
      <div className="flex flex-col gap-2">
        <Typography.BodySm color="text-default">
          {t("view_only_masquerading.empty_search_placeholder")}
        </Typography.BodySm>
      </div>
    </div>
  </div>
);
