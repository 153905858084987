import { useEffect } from "react";
import classNames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { isFunction } from "lodash";
import { isImagePost } from "@/react/helpers/postHelpers";
import { posts } from "@/react/helpers/urlHelpers";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { isSafari } from "@circle-react/helpers/browserHelpers";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import type { LineClampLevel } from "@circle-react-uikit/SeeMoreLess";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { KnowledgeBaseFeedbackSection } from "../KnowledgeBaseFeedbackSection";

/**
 * @deprecated
 * Please use the new PostBody component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostBody = ({
  enableTruncation = false,
  noPadding = false,
  className = "",
}) => {
  const {
    post,
    isSharedPostWithTeaserLayout,
    isSharedPostViewWithMinimalLayout,
    isTiptapPost,
    isTrixPost,
    isBasicPost,
    isPostBodyEmpty,
  } = useCurrentPostHelpers();

  const isKnowledgeBasePost =
    ["help", "help-en"].includes(post.community_slug) && isBasicPost;

  const isDisabled = !enableTruncation;

  const isMinimalTeaser =
    isSharedPostViewWithMinimalLayout && isSharedPostWithTeaserLayout;

  const shouldHideToggle = isSharedPostWithTeaserLayout;

  const {
    body_trix_content: bodyTrixContent,
    tiptap_body: tiptapBody,
    internal_custom_html: html,
    pinned_at_top_of_space: isPinned,
    updated_at: updatedAt,
  } = post;

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  if (isPostBodyEmpty) {
    return null;
  }

  let lines: LineClampLevel = 8;
  if (isPinned) {
    lines = 2;
  } else if (isMinimalTeaser) {
    lines = 4;
  }

  return (
    <div
      key={updatedAt}
      className={classNames(
        "post__body",
        {
          "!p-0": noPadding,
          "!pt-5": isSharedPostViewWithMinimalLayout && isImagePost(post),
        },
        className,
      )}
      data-testid="post-body"
    >
      <div
        className={classNames("post__inside trix-v2 !line-clamp-none w-full", {
          "!overflow-auto": isSafari(),
        })}
        data-testid="post-body-inside"
      >
        <SeeMoreLess
          lines={lines}
          disabled={isDisabled}
          hideToggle={shouldHideToggle}
        >
          {isTrixPost && bodyTrixContent && (
            <DisplayTrixContent
              content={bodyTrixContent}
              contentParentPath={posts.show({
                slug: post.slug,
                spaceSlug: post.space_slug,
              })}
            />
          )}
          {isTiptapPost && tiptapBody && (
            <TipTapBlockEditor
              readOnly
              value={tiptapBody.body}
              sgidToObjectMap={tiptapBody.sgids_to_object_map}
              inlineAttachments={tiptapBody.inline_attachments}
            />
          )}
          {html && <InnerHTML key="internal_custom_html" html={html} />}
          {isKnowledgeBasePost && <KnowledgeBaseFeedbackSection post={post} />}
        </SeeMoreLess>
      </div>
    </div>
  );
};
