import classnames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { useIsOrCanBeCommunityMember } from "@circle-react/contexts";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { CommentButton } from "./CommentButton";
import { LikeButton } from "./LikeButton";

export interface EngagementActionsProps {
  type?: "list" | "card" | "image-card";
  wrapperClassName?: string;
  commentButtonClassName?: string;
  commentIconClassName?: any;
  likeButtonClassName?: string;
  likeIconClassName?: any;
  likedSpanClassName?: string;
  showCommentsCount?: boolean;
  showLikesCount?: boolean;
  onCommentsClick?: () => void;
}

// TODO: Refactor the actions out, we are going to just always refetch the post so don't have to do any complex logic.
/**
 * @deprecated
 * Please use the new EngagementActions component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */
export const EngagementActions = ({
  type = "list",
  wrapperClassName,
  commentButtonClassName,
  commentIconClassName,
  likeButtonClassName,
  likeIconClassName,
  likedSpanClassName,
  showCommentsCount = true,
  showLikesCount = true,
  onCommentsClick,
}: EngagementActionsProps) => {
  const {
    post,
    showEngagementActions: shouldShowEngagementActions,
    onLikeToggle,
  } = useCurrentPostHelpers();
  const {
    user_likes_count,
    is_liking_enabled,
    is_comments_enabled,
    is_liked_by_current_user,
    comments_count,
    post_details_loaded,
    display_title,
  } = post;

  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();

  if (
    !shouldShowEngagementActions ||
    (!is_liking_enabled && !is_comments_enabled)
  ) {
    return null;
  }

  if (!post_details_loaded) {
    return (
      <div
        className={classnames(
          `post--${type}__actions post--${type}__actions-visible`,
          wrapperClassName,
        )}
      >
        <div
          className={classnames(
            "action-item action-link action-like post__actions--like",
            likeButtonClassName,
          )}
        >
          <span className="action__icon">
            <SkeletonLoader variant="row" lg short />
          </span>
        </div>
        <div
          className={classnames(
            "action-item action-comment",
            commentButtonClassName,
          )}
        >
          <span className="action__icon">
            <SkeletonLoader variant="row" lg short />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames(
        `post--${type}__actions post--${type}__actions-visible`,
        wrapperClassName,
      )}
    >
      <BlockInViewOnlyMode>
        {is_liking_enabled && (
          <LikeButton
            name={display_title}
            onLikeToggle={() => {
              void onLikeToggle?.(undefined);
            }}
            isOrCanBeCommunityMember={isOrCanBeCommunityMember}
            user_likes_count={user_likes_count}
            is_liked_by_current_user={is_liked_by_current_user}
            className={likeButtonClassName}
            iconClassName={likeIconClassName}
            likedSpanClassName={likedSpanClassName}
            showCount={showLikesCount}
          />
        )}
      </BlockInViewOnlyMode>

      {is_comments_enabled && (
        <CommentButton
          comments_count={comments_count}
          post={post}
          className={commentButtonClassName}
          iconClassName={commentIconClassName}
          showCount={showCommentsCount}
          onCommentsClick={onCommentsClick}
        />
      )}
    </div>
  );
};
