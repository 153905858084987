import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { flaggedContent } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";

/**
 * @deprecated
 * Please use the new ActionMenu component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const ActionMenu = ({
  comment,
  canReport,
  canReview,
  canEdit,
  canDestroy,
  canBookmark,
  isReply,
  onReportClick,
  onEditClick,
  onDeleteClick,
  onUpdate,
}) => {
  const isIframeEmbed = useIsIframeEmbed();
  const menuItems = [];

  const handleBookmarkSuccess = newBookmarkId => {
    const updatedComment = {
      ...comment,
      bookmark_id: newBookmarkId,
    };
    onUpdate(updatedComment);
  };

  if (canReport) {
    menuItems.push({
      key: "report",
      label: t("comments.action_menu.report.label"),
      href: "#",
      onClick: () => {
        onReportClick();
      },
    });
  }

  if (canReview) {
    menuItems.push({
      key: "review",
      label: t("comments.action_menu.review.label"),
      to: flaggedContent.index(),
      ...(isIframeEmbed ? { target: "_blank", rel: "noreferrer" } : {}),
    });
  }

  if (canEdit) {
    menuItems.push({
      key: "edit",
      label: t("comments.action_menu.edit.label"),
      onClick: () => {
        onEditClick();
      },
    });
  }

  if (canDestroy) {
    menuItems.push({
      key: "delete",
      label: t("comments.action_menu.delete.label"),
      hasDanger: true,
      onClick: () => {
        onDeleteClick();
      },
    });
  }

  if (menuItems.length === 0 && !canBookmark) {
    return null;
  }

  return (
    <div
      className={classnames("post__actions--comment", {
        "post__actions--reply": isReply,
      })}
      data-testid="post-comment-actions"
    >
      <Dropdown
        button={
          <IconButton dataTestId="action-more" name="16-menu-dots-horizontal" />
        }
      >
        <BookmarkButton
          variant="dropdown"
          bookmarkType="comment"
          record={comment}
          onSuccess={handleBookmarkSuccess}
        />
        {menuItems.map(item => (
          <Dropdown.ItemWithLink key={item.key} {...item}>
            {item.label}
          </Dropdown.ItemWithLink>
        ))}
      </Dropdown>
    </div>
  );
};

ActionMenu.propTypes = {
  canReport: PropTypes.bool.isRequired,
  canReview: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDestroy: PropTypes.bool.isRequired,
  isReply: PropTypes.bool.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
