import { useState } from "react";
import { t } from "i18n-js";
import type { Option } from "@circle-react-shared/uikit/Dropdown/Option";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { IconWithBadgeList } from "@circle-react-uikit/Dropdown/IconWithBadgeList";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { AdjustImages } from "../../AdjustImages";
import { useHandleFileSelection } from "../../UploadImages/hooks/useHandleFileSelection";
import type { UploadedImage } from "../../store";
import { useRemoveImage } from "../hooks/useRemoveImage";

const urlToFile = async ({ url, fileName, contentType }: any) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, {
    type: contentType,
    lastModified: new Date().getTime(),
  });
};

interface ImageOptionsDropdownProps {
  currentImage: UploadedImage;
}

export const ImageOptionsDropdown = ({
  currentImage,
}: ImageOptionsDropdownProps) => {
  const [isAdjustImagesOpen, setIsAdjustImagesOpen] = useState(false);
  const { removeImage } = useRemoveImage();
  const { handleFileSelection } = useHandleFileSelection(() =>
    setIsAdjustImagesOpen(true),
  );

  const translationBase =
    "image_space.post_modal.steps.create_post.image_options";

  const options: Option[] = [
    {
      value: t(`${translationBase}.crop_image`),
      label: t(`${translationBase}.crop_image`),
      icon: "16-crop",
      onClick: async () => {
        const file = await urlToFile({
          url: currentImage.original_url,
          fileName: currentImage.filename,
          contentType: "image/jpeg",
        });
        handleFileSelection([file]);
      },
    },
    {
      value: t(`${translationBase}.remove`),
      label: t(`${translationBase}.remove`),
      icon: "16-trash-bin",
      onClick: () => {
        removeImage(currentImage);
      },
    },
  ];

  return (
    <>
      {isAdjustImagesOpen && (
        <AdjustImages
          onClose={() => setIsAdjustImagesOpen(false)}
          isOnTop
          editingImageSignedId={currentImage.signed_id}
        />
      )}
      <Dropdown
        direction="bottom-end"
        className="absolute right-4 top-4"
        button={
          <IconButton
            name="16-menu-dots-horizontal"
            iconClassName="!text-dark"
            className="bg-primary border-primary rounded-full border"
            ariaLabel={t(`${translationBase}.label`)}
          />
        }
      >
        <IconWithBadgeList options={options} linkType="button" />
      </Dropdown>
    </>
  );
};
