import classNames from "classnames";
import { ProfileAbout } from "@circle-react/components/ProfileModalV3/Body/About";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";

export const Sidebar = () => {
  const { isLoading, isFullPageView } = useProfileContext();
  if (isLoading) return null;
  return (
    <div
      className={classNames(
        "border-primary bg-primary mb-9 hidden overflow-auto rounded-2xl border md:block",
        isFullPageView ? "w-1/3" : "col-span-3",
      )}
    >
      <ProfileAbout />
    </div>
  );
};
