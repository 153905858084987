import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import {
  getPortalTargetId,
  isChatRoomAdminOrModerator,
  isDirectChatRoom,
} from "../helpers";

export interface RichTextUpdateBoxProps {
  chatRoom: any;
  isRenderedOnHeader?: boolean;
  message: any;
  onDelete?: () => void;
  onSubmit?: (value: any) => void | Promise<void>;
}

export const RichTextUpdateBox = ({
  chatRoom,
  isRenderedOnHeader = false,
  message,
  onDelete,
  onSubmit,
}: RichTextUpdateBoxProps) => {
  const { pathname } = useLocation();
  const rich_text_body = message.rich_text_body || {
    body: message.body,
  };
  const isDirectMessaging = isDirectChatRoom(chatRoom);
  const isChatAdminOrModerator = isChatRoomAdminOrModerator(chatRoom);

  const isMessagingPage = pathname.includes("/messages");
  const portalTargetId = getPortalTargetId(isMessagingPage, isRenderedOnHeader);

  return (
    <div
      className="chat-message-word-wrap text-dark mb-1 whitespace-pre-line text-sm font-normal"
      data-testid="message-text"
    >
      <TiptapEditor
        autofocus
        editable
        rich_text_body={rich_text_body}
        onSubmit={onSubmit}
        onDelete={onDelete}
        isUpdate
        chatProps={{
          isThread: !!message.parent_message_id,
          isDirectMessaging: isDirectMessaging,
          isChatAdminOrModerator: isChatAdminOrModerator,
        }}
        portalTargetId={portalTargetId}
      />
      <div className="-mt-5 text-xs">
        {t("messaging.esc_to")}{" "}
        <span className="font-semibold">{t("cancel")}</span>{" "}
        {t("messaging.bullet")} {t("messaging.enter_to")}{" "}
        <span className="font-semibold">{t("save")}</span>
      </div>
    </div>
  );
};
