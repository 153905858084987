import { useEffect } from "react";
import { t } from "i18n-js";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const SharePrivateMessageConfirmationModal = ({
  shouldShowPrivateMessageConfirmationModal,
  togglePrivateMessageConfirmationModal,
  handleSubmit,
  shouldShowPrivateMessageConfirmationOnSubmit,
  setShouldShowPrivateMessageConfirmationOnSubmit,
}: any) => {
  useEffect(() => {
    if (!shouldShowPrivateMessageConfirmationOnSubmit) {
      handleSubmit(true);
      togglePrivateMessageConfirmationModal();
    }
  }, [
    shouldShowPrivateMessageConfirmationOnSubmit,
    handleSubmit,
    togglePrivateMessageConfirmationModal,
  ]);

  return (
    <ConfirmationModal
      isOpen={shouldShowPrivateMessageConfirmationModal}
      onClose={togglePrivateMessageConfirmationModal}
      onConfirm={() => {
        setShouldShowPrivateMessageConfirmationOnSubmit(false);
      }}
      confirmText={t("send")}
      title={t("messaging.share_private_message.title")}
      cancelText={t("messaging.edit_message")}
    >
      <div className="mb-6">
        {t("messaging.share_private_message.description")}
      </div>
    </ConfirmationModal>
  );
};
