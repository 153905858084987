import { t } from "i18n-js";
import type { Event } from "@circle-react/types/Event";
import { UserGroupIcon } from "@circle-react-shared/UserGroupIcon";
import { Typography } from "@circle-react-uikit/Typography";
import { useEventAttendeesApi } from "../../../hooks/useEventAttendeesApi";
import { ATTENDEE_ROLES } from "../../../utils";

const PREVIEW_COUNT = 3;

interface CohostsPreviewProps {
  event: Event;
  onClick: () => void;
}

export const CohostsPreview = ({ event, onClick }: CohostsPreviewProps) => {
  const { records, totalRecordCount: count } = useEventAttendeesApi(
    event,
    ATTENDEE_ROLES.COHOST,
    PREVIEW_COUNT,
  );
  const countLabel = count || t("events.edit_modal.overview.zero");

  return (
    <button
      type="button"
      className="border-primary flex h-full w-full cursor-pointer flex-col justify-between rounded-lg border p-4"
      onClick={onClick}
      aria-label={t("events.edit_modal.overview.cohosts")}
    >
      <div className="mb-2 flex w-full items-start justify-between">
        <Typography.LabelMd weight="medium" color="text-light">
          {t("events.edit_modal.overview.cohosts")}
        </Typography.LabelMd>
        <UserGroupIcon users={records} totalCount={count} size="md" />
      </div>
      <Typography.TitleMd>{countLabel}</Typography.TitleMd>
    </button>
  );
};
