/* eslint-disable react-hooks/rules-of-hooks
 -- Disabled to set CI to fail on this issue on new files, PR #5195 */
import { useCallback, useEffect, useRef, useState } from "react";
import type { Editor as TipTapEditor } from "@tiptap/react";
import classNames from "classnames";
import { useRouteMatch } from "react-router";
import { Carousel } from "@/react/components/Spaces/ImageSpace/FeedView/Carousel";
import { Sidebar } from "@circle-react/components/Events/Sidebar";
import { CommentsSection } from "@circle-react/components/PostsV2/PostViewListItem/CommentsSection";
import { EngagementActions } from "@circle-react/components/PostsV2/PostViewListItem/EngagementActions";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { isEventPost, isImagePost } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { TrixEditorRefObject as TrixEditor } from "@circle-react-shared/TrixEditor";
import { isTrixEditorRefObject } from "@circle-react-shared/TrixEditor";
import { TranscriptsPlayer } from "@circle-react-uikit/TranscriptsPlayer";
import { PostActions } from "./PostActions";
import { PostBody } from "./PostBody";
import { PostCoverImage } from "./PostCoverImage";
import { PostMetaInfo } from "./PostMetaInfo";
import { PostName } from "./PostName";

export interface MainContentProps {
  autofocusReplyForm?: boolean;
  className?: string;
  defaultShowComment?: boolean;
  hideAuthorHeadline?: boolean;
  hideBookmarkButton?: boolean;
  hidePinnedToTopIcon?: boolean;
  hideShareLink?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  metaInfoWrapperClassName?: string;
  onPostChange: (post: any, options?: any) => void;
  onPostDestroy: (post: any) => void;
  pageSupportsContentTruncation?: boolean;
  refetchPosts?: () => void;
  shouldOpenCommentPage?: boolean;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
}

/**
 * @deprecated
 * Please use the new MainContent component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const MainContent = ({
  autofocusReplyForm = false,
  className,
  defaultShowComment = false,
  hideAuthorHeadline = false,
  hideBookmarkButton = false,
  hidePinnedToTopIcon = false,
  hideShareLink = false,
  isRenderedOutsideTheSpace = false,
  metaInfoWrapperClassName,
  onPostChange,
  onPostDestroy,
  pageSupportsContentTruncation = true,
  refetchPosts,
  shouldOpenCommentPage = false,
  tagsLimit,
  tagsShowMoreLabelVariant,
}: MainContentProps) => {
  const commentsFormRef = useRef<TrixEditor | TipTapEditor>(null);
  const { record: post, usedIn } = useCurrentPostContext();
  const { isPostBodyEmpty, isPostMetaHidden } = useCurrentPostHelpers();
  const { data: space } = useCurrentSpaceContext();
  const [shouldShowComment, setShouldShowComment] =
    useState(defaultShowComment);
  const [shouldAutofocusReplyForm, setShouldAutofocusReplyForm] =
    useState(autofocusReplyForm);
  const isSmScreen = useSmScreenMediaQuery();
  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;

  const {
    name,
    is_truncation_disabled,
    is_comments_enabled,
    flagged_for_approval_at,
    hide_meta_info,
    pinned_at_top_of_space,
    post_details_loaded,
    event_setting_attributes: event_setting,
  } = post;

  const liveRoomSettingAttributes =
    event_setting?.live_stream_room_setting_attributes;
  const recordingUrl = liveRoomSettingAttributes?.recording_url;
  const recordingTranscriptId =
    liveRoomSettingAttributes?.recording_transcript_id;
  const recordingThumbnailUrl = liveRoomSettingAttributes?.recording_thumbnail;
  const postingRecording =
    liveRoomSettingAttributes?.auto_post_recording_enabled;
  const doesPostHaveCoverImage = post?.cover_image_url;

  const isPostTruncationEnabled = !is_truncation_disabled;
  const { currentUser, currentCommunitySettings } = usePunditUserContext();
  const isReactifiedCommentsEnabled =
    currentCommunitySettings?.reactified_comments_enabled;

  const { isSharedPostView, isSharedPostWithTeaserLayout } =
    useCurrentPostHelpers();

  const hasContentTruncation =
    (pageSupportsContentTruncation && isPostTruncationEnabled) ||
    isSharedPostWithTeaserLayout;

  const shouldAddBottomPaddingForPostsWithEmptyBody =
    isPostBodyEmpty &&
    !isHomePage &&
    !isFeedPage &&
    !isPostMetaHidden &&
    !isImagePost(post);

  if (!post || !post.id) {
    return null;
  }

  const focusNewCommentForm = () => {
    setTimeout(() => {
      const element = document.querySelector(
        `[input="comment-body-${post.id}"]`,
      );
      if (element && element instanceof HTMLElement) {
        element.focus();
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);
  };

  const focusCommentsForm = useCallback(
    () =>
      isTrixEditorRefObject(commentsFormRef.current) &&
      commentsFormRef.current.focus(),
    [commentsFormRef],
  );

  const toggleComment = () => {
    setShouldAutofocusReplyForm(false);
    setShouldShowComment(prev => !prev);
  };

  useEffect(() => {
    if (shouldAutofocusReplyForm) {
      focusCommentsForm();
    }
  }, [focusCommentsForm]);

  const containerClassName = classNames(
    "post post--parent !bg-primary !border-primary",
    {
      "post--shared": isSharedPostView,
      "post--shared__truncated": isSharedPostWithTeaserLayout && !currentUser,
      "is-truncation-disabled": !hasContentTruncation,
      "flagged-for-approval": flagged_for_approval_at,
      "post-name--empty": !name,
      "post-meta--hidden": hide_meta_info,
      "is-pinned": pinned_at_top_of_space,
      "!shadow-none !rounded-none !border-0 !border-b":
        usedIn === "full-post-modal",
    },
    className,
  );
  // TODO: get member tags dynamically

  const onChange = (updates = {}, options = {}) => {
    onPostChange({ ...post, ...updates }, options);
  };

  const showComments = useCallback(() => {
    setShouldShowComment(true);

    if (isReactifiedCommentsEnabled) {
      setShouldAutofocusReplyForm(true);

      // Focus the reply box if its already open
      if (commentsFormRef.current) {
        if (!isTrixEditorRefObject(commentsFormRef.current)) {
          commentsFormRef.current.commands?.focus?.("end");
        } else if (isTrixEditorRefObject(commentsFormRef.current)) {
          commentsFormRef.current.focus();
        }
      }
    } else {
      focusNewCommentForm();
    }
  }, [isReactifiedCommentsEnabled, focusNewCommentForm]);

  const isEventPostType = isEventPost(post);
  return (
    <div className={containerClassName}>
      <PostCoverImage post={post} />
      <div
        className={classNames("post__post", {
          "!border-x-0": isSharedPostView,
          "!border-b": !currentUser && isSharedPostView && isEventPostType,
          "!border-primary !rounded-t-lg":
            isSharedPostView && !post?.cover_image_url,
        })}
      >
        <PostActions
          post={post}
          onChange={onChange}
          refetchPosts={refetchPosts}
          onPostDestroy={onPostDestroy}
          hideShareLink={hideShareLink}
          hidePinnedToTopIcon={hidePinnedToTopIcon}
          hideBookmarkButton={hideBookmarkButton}
        />
        {/*
            We add a bottom padding of 20px to post content if the post body is empty, however
            - On the home page, the post title has a bottom padding of 20px, hence we don't need to add it there
            - If the post meta is hidden, then we add a bottom padding of 20px to the title, hence we don't need to add it there
        */}
        <div
          className={classNames("post__content text-dark", {
            "pb-5": shouldAddBottomPaddingForPostsWithEmptyBody,
          })}
        >
          {isRenderedOutsideTheSpace || isSmScreen ? (
            <>
              <PostMetaInfo
                isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
                space={space}
                doesPostHaveCoverImage={doesPostHaveCoverImage}
              />
              <PostName />
            </>
          ) : (
            <>
              <PostName />
              <PostMetaInfo
                isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
                doesPostHaveCoverImage={doesPostHaveCoverImage}
                space={space}
                tagsLimit={tagsLimit}
                tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
                wrapperClassName={metaInfoWrapperClassName}
                hideAuthorHeadline={hideAuthorHeadline}
              />
            </>
          )}
          {isEventPostType && <Sidebar onChange={onChange} insidePost />}
          {recordingUrl && postingRecording && (
            <div className="px-6 py-4">
              <TranscriptsPlayer
                src={recordingUrl}
                type="video/mp4"
                mediaTranscriptId={recordingTranscriptId}
                poster={recordingThumbnailUrl}
              />
            </div>
          )}
          {isImagePost(post) && usedIn !== "full-post-modal" && (
            <Carousel
              onPostChange={onPostChange}
              onPostDestroy={onPostDestroy}
            />
          )}
          <PostBody enableTruncation={hasContentTruncation} />
        </div>
      </div>
      <div className="mt-auto">
        <EngagementActions
          toggleComment={toggleComment}
          showCommentAndFocusCommentForm={showComments}
          shouldOpenCommentPage={shouldOpenCommentPage}
        />
      </div>
      {post_details_loaded && is_comments_enabled && shouldShowComment && (
        <CommentsSection
          commentsFormRef={commentsFormRef}
          autofocusReplyForm={shouldAutofocusReplyForm}
        />
      )}
    </div>
  );
};
