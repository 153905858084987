import { useEffect, useState } from "react";
import copyToClipboard from "copy-to-clipboard";
import { t } from "i18n-js";
import { postsPath } from "@/react/helpers/urlHelpers";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePostShareOptions = ({
  post,
  getSharePostLink,
  sharePostData,
}: any) => {
  const { success } = useToast();
  const { currentCommunity } = usePunditUserContext();
  const { data: currentSpace } = useCurrentSpaceContext();

  const [formData, setFormData] = useState({
    post: {
      show_post_teaser: !!post?.show_post_teaser,
      share_to_web: !!post?.share_post_enabled,
      share_post_enabled: !!post?.share_post_enabled,
      minimal_layout: !!post?.minimal_layout,
    },
  });

  const postHref = postsPath({ spaceSlug: post?.space_slug, slug: post?.slug });

  const onChange = (sharePostDataToUpdate: any) => {
    setFormData(prev => {
      const prevPostData = {
        post: {
          ...prev.post,
          ...sharePostDataToUpdate,
        },
      };
      getSharePostLink({ postId: post?.id, body: prevPostData });
      return prevPostData;
    });
  };

  useEffect(() => {
    if (post.share_post_enabled && !sharePostData) {
      getSharePostLink({ postId: post?.id, body: formData });
    }
  }, [
    post?.share_post_enabled,
    sharePostData,
    formData,
    getSharePostLink,
    post?.id,
  ]);

  const onClickCopyLink = (link: null | string) => {
    copyToClipboard(link || window.location.origin + postHref);
    success(t("post.share.link_copied"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };

  return {
    onClickCopyLink,
    onChange,
    formData,
    currentCommunity,
    currentSpace,
  };
};
