import { immer } from "zustand/middleware/immer";

interface ChatThreadsState {
  chatMessages: any[];
  isLoading: boolean;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  scrollInfo: any;
  firstMessageId: number | null;
  lastMessageId: number | null;
  totalMessagesCount: number;
  metaData: any;
}

interface ChatThreadsActions {
  setChatMessages: (messages: any[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setHasNextPage: (hasNextPage: boolean) => void;
  setHasPrevPage: (hasPrevPage: boolean) => void;
  setScrollInfo: (scrollInfo: any) => void;
  setFirstMessageId: (id: number | null) => void;
  setLastMessageId: (id: number | null) => void;
  setTotalMessagesCount: (totalMessagesCount: number) => void;
  setMetaData: (metaData: any) => void;
}

export interface ChatThreadsStore {
  threads: ChatThreadsState & ChatThreadsActions;
}

const initialChatThreadsState: ChatThreadsState = {
  chatMessages: [],
  isLoading: false,
  hasNextPage: false,
  hasPrevPage: false,
  scrollInfo: {},
  firstMessageId: null,
  lastMessageId: null,
  totalMessagesCount: 0,
  metaData: {},
};

export const createThreadsSlice = immer<ChatThreadsStore>(set => ({
  threads: {
    ...initialChatThreadsState,
    setChatMessages: messages =>
      set(state => {
        state.threads.chatMessages = messages;
      }),
    setIsLoading: isLoading =>
      set(state => {
        state.threads.isLoading = isLoading;
      }),
    setHasNextPage: hasNextPage =>
      set(state => {
        state.threads.hasNextPage = hasNextPage;
      }),
    setHasPrevPage: hasPrevPage =>
      set(state => {
        state.threads.hasPrevPage = hasPrevPage;
      }),
    setScrollInfo: scrollInfo =>
      set(state => {
        state.threads.scrollInfo = scrollInfo;
      }),
    setFirstMessageId: id =>
      set(state => {
        state.threads.firstMessageId = id;
      }),
    setLastMessageId: id =>
      set(state => {
        state.threads.lastMessageId = id;
      }),
    setTotalMessagesCount: totalMessagesCount =>
      set(state => {
        state.threads.totalMessagesCount = totalMessagesCount;
      }),
    setMetaData: metaData =>
      set(state => {
        state.threads.metaData = metaData;
      }),
  },
}));
