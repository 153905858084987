import { t } from "i18n-js";
import { toLocaleString } from "@circle-react/helpers/number";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";

interface LikedByMessageProps {
  likesCount: number;
  isLoading: boolean;
}

export const LikedByMessageV3 = ({
  likesCount,
  isLoading,
}: LikedByMessageProps) => {
  if (isLoading) {
    return <SkeletonLoader variant="row" lg extraLong />;
  }

  if (likesCount === 0) {
    return null;
  }

  return (
    <span className="overflow-anywhere text-default group-hover:text-dark text-sm font-medium">
      {t("likes_count", {
        localeCount: toLocaleString(likesCount),
        count: likesCount,
      })}
    </span>
  );
};
