import { t } from "i18n-js";
import { addSecondsToDate } from "@circle-react/helpers/dateTimeHelpers";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { Event } from "@circle-react/types/Event";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { Icon } from "@circle-react-shared/Icon";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Typography } from "@circle-react-uikit/Typography";
import { MobileEventActionsDropdown } from "./MobileEventActionsDropdown";
import { SECTIONS } from "./utils";
import { isPublished } from "./utils";

interface MobilEventNavigationProps {
  validateAndSetCurrentSection: (
    section: string,
    setCurrentSection: (section: string) => void,
  ) => void;
  event: Event;
  isEditMode: boolean;
  title: string;
}

const ONE_HOUR_IN_SECONDS = 3600;

export const MobilEventNavigation = ({
  validateAndSetCurrentSection,
  event,
  isEditMode,
  title,
}: MobilEventNavigationProps) => {
  const { sections, setCurrentSection, currentSection } = useCustomizeUIData();

  const filteredRadioOptions = sections
    .filter(value => value !== SECTIONS.overview)
    .map(value => ({
      value,
      label: value,
    }));

  const handleSelectRadioOnChange = (section: any) => {
    validateAndSetCurrentSection(section, setCurrentSection);
  };

  const badgeLabel = isPublished(event)
    ? t("events.badge_status.published")
    : t("events.badge_status.draft");
  const startsAt = event?.event_setting_attributes?.starts_at;
  const endsAt = addSecondsToDate(
    startsAt,
    event?.event_setting_attributes?.duration_in_seconds || ONE_HOUR_IN_SECONDS,
  );

  const shouldRenderMobileTabNavigation = currentSection === SECTIONS.overview;

  const { dayOfMonth, shortMonthString } = dateComponents(
    startsAt,
    event?.event_setting_attributes?.time_zone,
  );

  const timeZoneAbbreviations: string | undefined =
    event?.event_setting_attributes?.time_zone_abbreviations;

  const eventTimestamp = `${dayOfMonth?.toString() ?? ""} ${
    shortMonthString ?? ""
  } ${dateToTimeString(
    startsAt ?? new Date().toISOString(),
    event?.event_setting_attributes?.time_zone ?? "",
  )} - ${dateToTimeString(
    endsAt.toISOString() ?? new Date().toISOString(),
    event?.event_setting_attributes?.time_zone ?? "",
  )},${timeZoneAbbreviations ?? ""}`;

  if (!shouldRenderMobileTabNavigation) return null;

  return (
    <>
      <div className="mb-4 mt-5 flex flex-col gap-2 px-5">
        <div className="flex w-full items-center justify-between">
          {isEditMode && <BadgeV2 label={badgeLabel} />}
          <MobileEventActionsDropdown event={event} />
        </div>
        <Typography.TitleSm truncate weight="semibold">
          {title}
        </Typography.TitleSm>
        <Typography.LabelSm weight="font-semibold">
          {eventTimestamp}
        </Typography.LabelSm>
      </div>
      <div className="flex flex-col px-5">
        {filteredRadioOptions.map(option => (
          <button
            type="button"
            className="border-b-primary text-default flex items-center justify-between border-b py-3 text-sm"
            key={option.label}
            onClick={() => handleSelectRadioOnChange(option.value)}
          >
            {option.label}
            <Icon
              size={16}
              type="16-chevron-right"
              className="text-default h-4 w-4"
            />
          </button>
        ))}
      </div>
    </>
  );
};
