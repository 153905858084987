import { t } from "i18n-js";
import type { ActivityScoreDetail } from "@circle-react-shared/ActivityScore/utils";

export const getDisabledScoreDetail = (
  shortDisabledLabel: any,
): ActivityScoreDetail => ({
  activityScore: {
    label: shortDisabledLabel
      ? t("activity_score.view")
      : t("activity_score.view_score"),
    activity: "disabled",
  },
});
