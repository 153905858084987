import PropTypes from "prop-types";

export const MessageIndicatorButton = ({ label, onClick }) => (
  <button
    type="submit"
    className="bg-brand rounded-2xl px-2.5 py-1 text-xs font-semibold leading-5 text-white hover:opacity-90"
    onClick={onClick}
  >
    {label}
  </button>
);

MessageIndicatorButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
