import { useEventSpaces } from "@circle-react/components/Events/hooks/useEventSpaces";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  addSecondsToDate,
  roundToNextHour,
} from "@circle-react/helpers/dateTimeHelpers";
import {
  ROOM_ACCESS_TYPES,
  ROOM_TYPES,
  ROOM_VIEW_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { EVENT_TYPES, RECURRING_ENDING, STATUS, TICKET_TYPES } from "../utils";

const ONE_HOUR_IN_SECONDS = 3600;

export const useDefaultValues = (event, spaceId, locationType) => {
  const { currentUser, currentCommunity } = usePunditUserContext();
  const { spaces } = useEventSpaces();
  const space =
    spaces.find(space => space.id === (spaceId || event?.space_id)) || null;
  const isPrivateSpace = ["private", "secret"].includes(space?.visibility);
  const roundedDateTime = roundToNextHour(new Date());
  const nextHourDateTime = addSecondsToDate(
    roundedDateTime,
    ONE_HOUR_IN_SECONDS,
  );

  return event
    ? {
        ...event,
        body: event.body_for_editor,
        attendee_invite_attributes: {
          invited_entities_ids: null,
        },
      }
    : {
        user_id: currentUser?.id,
        space_id: spaceId,
        event_type: EVENT_TYPES.SINGLE,
        status: STATUS.DRAFT,
        event_setting_attributes: {
          location_type: locationType,
          starts_at: roundedDateTime.toUTCString(),
          ends_at: nextHourDateTime.toUTCString(),
          duration_in_seconds: 3600,
          live_stream_room_setting_attributes: {
            access_type: isPrivateSpace
              ? ROOM_ACCESS_TYPES.SECRET
              : ROOM_ACCESS_TYPES.OPEN,
            view_type: ROOM_VIEW_TYPES.GRID,
            recording_enabled: currentCommunity.live_stream_recording_enabled,
            auto_post_recording_enabled:
              currentCommunity.live_stream_auto_post_recording_enabled,
            room_type: ROOM_TYPES.STREAM,
            mute_on_join: false,
            hide_participants_list: false,
            limit_url_sharing: false,
          },
          send_email_confirmation: true,
          send_in_app_notification_confirmation: true,
          send_email_reminder: true,
          send_in_app_notification_reminder: true,
          send_publish_email: true,
          ticket_type: TICKET_TYPES.FREE,
        },
        recurring_setting_attributes: {
          range_type: RECURRING_ENDING.ON,
          occurrences: null,
          ends_at: nextHourDateTime.toUTCString(),
        },
      };
};
