import { isEmpty } from "lodash";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";

export const useCanSendMessage = chatRoom => {
  const { current_participant, chat_room_kind } = chatRoom;
  const isDirectMessaging = chat_room_kind === DIRECT_CHAT_ROOM;
  const canParticipantsMessage = current_participant?.can_send_message_in_room;
  const currentParticipantExists =
    !isEmpty(current_participant) && !current_participant.deleted_at;

  const canSendMessage = isDirectMessaging
    ? canParticipantsMessage
    : currentParticipantExists;

  return {
    canSendMessage,
    canParticipantsMessage,
  };
};
