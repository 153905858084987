import { t } from "i18n-js";
import {
  communitySignupUrl,
  shouldShowSignupLink,
} from "@circle-react/helpers/communityHelpers";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { Typography } from "@circle-react-uikit/Typography";

interface CommentsBanner {
  isIframeEmbed?: boolean;
  containerClassNames: string;
  currentCommunity: any;
}

/**
 * @deprecated
 * Please use the new CommentsBanner component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const CommentsBanner = ({
  isIframeEmbed,
  containerClassNames,
  currentCommunity,
}: CommentsBanner) => {
  const blankTarget = isIframeEmbed
    ? { target: "_blank", rel: "noreferrer" }
    : {};

  const showSignupLink = shouldShowSignupLink(currentCommunity);

  return (
    <div className={containerClassNames}>
      <div className="mb-3">
        <Typography.LabelLg weight="semibold">
          {t("login_to_comment")}
        </Typography.LabelLg>
      </div>
      <div className="flex items-center justify-center gap-4">
        <a
          className="btn btn-secondary"
          href={newSessionPath({
            params: { post_login_redirect: window.location.href },
          })}
          {...blankTarget}
        >
          {t("log_in")}
        </a>
        {showSignupLink && (
          <a
            className="btn btn-primary signup-link"
            href={communitySignupUrl(currentCommunity)}
            {...blankTarget}
          >
            {t("sign_up")}
          </a>
        )}
      </div>
    </div>
  );
};
