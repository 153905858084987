import I18n from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { useProfileCommentsList } from "@circle-react/hooks/profile/useProfileCommentsList";
import EmptyState from "../EmptyState";
import Comment from "./Comment";

export const ProfileComments = ({
  memberPublicId,
  member,
  onClose,
  shouldOpenInNewTab,
  scrollableTarget = "panel",
}: any) => {
  const { fetchNextPage, comments, hasNextPage, isLoading } =
    useProfileCommentsList({
      memberPublicId,
    });

  const defaultScrollableTarget = "profile-comments-scrollable-target";

  if (isLoading) {
    return <LoaderRow />;
  }

  if (comments.length < 1)
    return (
      <EmptyState
        title={I18n.t("profile_drawer.comments.empty_state.title")}
        subtitle={I18n.t("profile_drawer.comments.empty_state.subtitle")}
      />
    );
  return (
    <div
      className="profile-comments bg-tertiary h-full overflow-auto"
      id="profile-comments-scrollable-target"
    >
      <InfiniteScroll
        scrollThreshold={0.5}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<LoaderRow />}
        dataLength={comments.length}
        scrollableTarget={scrollableTarget || defaultScrollableTarget}
        className="flex flex-col space-y-2"
      >
        {comments.map(comment => (
          <Comment
            key={comment.id}
            onClose={onClose}
            comment={comment}
            member={member}
            shouldOpenInNewTab={shouldOpenInNewTab}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
