import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import { usePreventAccidentalModalClose } from "@circle-react/components/QuickPostV2/usePreventAccidentalModalClose";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import {
  useCurrentPostContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { canManageSpace } from "@circle-react/helpers/spaceHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const QUICK_POST_THUMBNAIL_BUTTON_PORTAL_ID =
  "quick-post-v2-thumbnail-button";

export const Header = () => {
  const { isFullscreen, toggleFullscreen, isDirty } = useQuickPostModalStore();
  const conditionalClose = usePreventAccidentalModalClose({
    shouldPrevent: isDirty,
  });
  const history = useHistory();
  const { record: post } = useCurrentPostContext();
  const spacesContext = useSpacesContext();
  const { watch } = useFormContext();
  const isEdit = !!post?.id;
  const spaceId = watch("space_id");
  const space = spacesContext.helpers.findById(spaceId);
  const isSmScreen = useSmScreenMediaQuery();

  const goToSettings = () => history.push("/settings");
  return (
    <header
      className="border-primary flex items-center justify-between border-b px-5 py-4"
      data-testid="quick-post-header"
    >
      <Typography.TitleSm weight="semibold">
        {t(isEdit ? "quick_post_v2.edit_title" : "quick_post_v2.create_title")}
      </Typography.TitleSm>
      <ul className="text-darkest flex list-none items-center space-x-2">
        <li id={QUICK_POST_THUMBNAIL_BUTTON_PORTAL_ID} />
        {space && canManageSpace(space) && (
          <li>
            <TippyV2
              placement="bottom"
              content={t("quick_post_v2.header.settings")}
            >
              <IconButton
                variant="secondary"
                iconSize={20}
                iconClassName="!text-dark"
                name="20-settings"
                onClick={goToSettings}
                ariaLabel={t("quick_post_v2.header.settings")}
              />
            </TippyV2>
          </li>
        )}
        {!isSmScreen && (
          <li>
            <TippyV2
              placement="bottom"
              content={t("quick_post_v2.header.fullscreen")}
            >
              <IconButton
                variant="secondary"
                iconSize={20}
                className="hidden sm:flex"
                onClick={toggleFullscreen}
                iconClassName="!text-dark"
                name={isFullscreen ? "20-fullscreen-exit" : "20-fullscreen"}
                ariaLabel={t("quick_post_v2.header.fullscreen")}
              />
            </TippyV2>
          </li>
        )}
        <li>
          <TippyV2 placement="bottom" content={t("quick_post_v2.header.close")}>
            <IconButton
              variant="secondary"
              iconSize={20}
              onClick={conditionalClose}
              iconClassName="!text-dark"
              name="20-close"
              ariaLabel={t("quick_post_v2.header.close")}
            />
          </TippyV2>
        </li>
      </ul>
    </header>
  );
};
