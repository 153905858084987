import type { MainContentProps } from "./MainContent";
import { MainContent } from "./MainContent";
import "./styles.scss";

/**
 * @deprecated
 * Please use the new PostViewListItem component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const PostViewListItem = (props: MainContentProps) => (
  <MainContent {...props} />
);
