import { t } from "i18n-js";
import { isFunction, noop } from "lodash";
import { useMutation } from "react-query";
import { postApi } from "@circle-react/api";
import { Dropdown } from "@circle-react/components/shared/uikit/Dropdown";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";

export const PinToTopAction = ({
  post,
  onChange,
  refetchPosts,
  handleClose = noop,
}) => {
  const { success, error } = useToast();
  const { pinned_at_top_of_space: isPinned } = post;

  const handleOnClick = useMutation(
    () =>
      postApi.pin({
        post,
        formData: { is_pinned: !isPinned, pin_to_top: true },
      }),
    {
      onSuccess: async post => {
        onChange(post, { refetch: false });
        if (isFunction(refetchPosts)) {
          await refetchPosts();
        }
        success(I18n.t(isPinned ? "post_unpinned" : "post_pinned"));
      },
      onError: () => {
        error(I18n.t(isPinned ? "post_unpinned_error" : "post_pinned_error"));
      },
      onSettled: handleClose,
    },
  );

  return (
    <Dropdown.ItemLink onClick={handleOnClick.mutate}>
      <ToggleSwitchWithLabel
        label={t("pin_to_top")}
        field="pin_to_top"
        value={isPinned}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
