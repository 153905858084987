import { t } from "i18n-js";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { spaces } from "@/react/helpers/urlHelpers";
import { PostCoverImage } from "@circle-react/components/PostsV3/PostViewListItem/PostCoverImage";
import { ShowPageLink } from "@circle-react/components/PostsV3/PostViewListItem/ShowPageLink";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export const Header = ({
  shouldOpenInNewTab = false,
}: {
  shouldOpenInNewTab?: boolean;
}) => {
  const { hide: closeMemberProfileDrawer } = useProfileDrawer();
  const { record: post } = useCurrentPostContext();

  const hideMemberProfileDrawer = (e: React.MouseEvent<HTMLAnchorElement>) =>
    isIntendedOnNewTab(e) ? false : closeMemberProfileDrawer();

  const {
    name,
    created_at: createdAt,
    space_name: spaceName,
    space_slug: spaceSlug,
  } = post;

  return (
    <>
      <PostCoverImage
        onClick={hideMemberProfileDrawer}
        post={post}
        className="!rounded-lg"
        wrapperClassName="!p-0"
      />
      <div className="profile-posts__post__meta w-full space-x-2">
        <span className="profile-posts__post__author text-default text-sm">
          {t("profile_tabs.post.posted_in")}{" "}
          <a
            className="focus-visible:outline-secondary focus-visible:text-dark text-dark hover:text-darkest font-medium focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            href={spaces.show({
              spaceSlug,
            })}
          >
            {spaceName}
          </a>
        </span>
        <span className="bg-badge inline-block h-1 w-1 rounded-full align-middle" />
        <TippyV2 interactive={false} content={getDetailedTimestamp(createdAt)}>
          <ShowPageLink
            onClick={hideMemberProfileDrawer}
            post={post}
            target={shouldOpenInNewTab ? "_blank" : "_self"}
            className="text-dark focus-visible:outline-secondary focus-visible:text-dark group inline-flex focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <span className="profile-posts__post__time text-default group-hover:text-dark text-sm">
              {dateStringToTimeAgo(createdAt, {
                format: dateToTimeAgoFormats.short,
              })}
            </span>
          </ShowPageLink>
        </TippyV2>
      </div>
      {!!name && (
        <ShowPageLink
          onClick={hideMemberProfileDrawer}
          post={post}
          target={shouldOpenInNewTab ? "_blank" : "_self"}
          className="text-dark focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <div className="profile-posts__post__title text-darkest break-all text-xl font-semibold leading-7">
            {name}
          </div>
        </ShowPageLink>
      )}
    </>
  );
};
