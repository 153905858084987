import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { SpaceSelect } from "@circle-react/components/shared/SpaceSelect";
import { useSpacesContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { afterSaveRedirectPath } from "@circle-react/helpers/postHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

/**
 * @deprecated
 * Please use the new DuplicatePostModal component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const DuplicatePostModal = ({
  spaceId,
  postId,
  postSlug,
  postTitle,
  hasNoteAboutExceptions = true,
  isOpen = false,
  onClose,
  onDuplicateSuccess,
}) => {
  const history = useHistory();
  const { success, error } = useToast();
  const {
    isLoading: isSpacesLoading,
    helpers: { basicSpacesWithPostCreationAllowed },
  } = useSpacesContext();
  const spaces = basicSpacesWithPostCreationAllowed();

  const { mutate: duplicate, isLoading: isDuplicating } = useMutation(
    data =>
      reactQueryPut(internalApi.posts.duplicate({ spaceId, postSlug }), {
        post: {
          post_id: postId,
          space_id: data.space_id,
          send_notifications: data.send_notifications,
        },
      }),
    {
      onSuccess: post => {
        success(t("duplicated_successfully"));
        onClose();

        history.push(afterSaveRedirectPath({ post, editMode: true }));

        onDuplicateSuccess && onDuplicateSuccess(post);
      },
      onError: () => {
        error(t("duplication_failed"));
      },
    },
  );

  if (isSpacesLoading) {
    return null;
  }

  return (
    <Modal
      overflowVisible
      title={t("duplicate_post_title", {
        post_title: postTitle || t("duplicate_post_title_untitled"),
      })}
      show={isOpen}
      onClose={onClose}
    >
      <Form
        onSubmit={duplicate}
        defaultValues={{ space_id: spaceId, send_notifications: false }}
      >
        <Form.Item
          name="space_id"
          hideBorder
          fullWidth
          label={t("space")}
          description={t("duplicate_post_to_space")}
          rules={{
            required: t("events.create.form.space_required_error"),
          }}
        >
          <SpaceSelect
            spaces={spaces}
            name="space_id"
            variant="form-input"
            defaultValue={spaceId}
            containerClassname="mt-2"
          />
        </Form.Item>
        {hasNoteAboutExceptions && (
          <>
            <Typography.LabelXs color="text-light">
              {t("comments_wont_be_duplicated")}
            </Typography.LabelXs>
            <Form.Item
              name="send_notifications"
              hideBorder
              fullWidth
              label={t("duplicate_post_send_notifications")}
              hideDescription
              inlineReverse
            >
              <Form.ToggleSwitch variant="small" name="send_notifications" />
            </Form.Item>
          </>
        )}
        <div className="mt-4">
          <Button type="submit" variant="primary" disabled={isDuplicating}>
            {isDuplicating ? t("duplicating") : t("duplicate")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

DuplicatePostModal.propTypes = {
  spaceId: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  postSlug: PropTypes.string.isRequired,
  postTitle: PropTypes.string,
  hasNoteAboutExceptions: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDuplicateSuccess: PropTypes.func,
};
