import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { customTextColorClass } from "./constants";

export const ModalHeader = ({ handleClose }: any) => (
  <Modal.Header>
    <Modal.Title size="md" className="w-full text-center">
      <div className="my-2 inline-block w-auto">
        <div className="relative">
          <Typography.TitleLg>
            {t("settings.community_ai.marketing_modal.title.unlock")}{" "}
            <span className={customTextColorClass}>
              {t("settings.community_ai.marketing_modal.title.community_ai")}
            </span>
          </Typography.TitleLg>
          <Icon
            type="16-artificial-intelligence"
            className="absolute -right-5 -top-1 text-blue-100"
          />
        </div>
      </div>
      <Typography.BodyMd color="text-default">
        {t("settings.community_ai.marketing_modal.subtitle")}
      </Typography.BodyMd>
    </Modal.Title>
    <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
      <Modal.CloseButton onClick={handleClose} />
    </Modal.HeaderActions>
  </Modal.Header>
);
