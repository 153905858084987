import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { CommentsForm } from "./CommentsForm";
import { CommentsLink } from "./CommentsLink";
import { LikeButton } from "./LikeButton";
import { PostLikedByMemberStack } from "./PostLikedByMemberStack";

interface EngagementActionsProps {
  toggleComment: () => void;
  showCommentAndFocusCommentForm: () => void;
  shouldOpenCommentPage?: boolean;
}

/**
 * @deprecated
 * Please use the new EngagementActions component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const EngagementActions = ({
  toggleComment,
  showCommentAndFocusCommentForm,
  shouldOpenCommentPage = false,
}: EngagementActionsProps) => {
  const { showEngagementActions: shouldShowActions } = useCurrentPostHelpers();

  if (!shouldShowActions) {
    return null;
  }

  return (
    <>
      <PostLikedByMemberStack />
      <div className="post__actions post__actions-visible !border-primary !border-t">
        <BlockInViewOnlyMode>
          <LikeButton />
        </BlockInViewOnlyMode>

        <CommentsForm
          shouldOpenCommentPage={shouldOpenCommentPage}
          showCommentAndFocusCommentForm={showCommentAndFocusCommentForm}
          toggleComment={toggleComment}
        />
        <CommentsLink
          shouldOpenCommentPage={shouldOpenCommentPage}
          toggleComment={toggleComment}
        />
      </div>
    </>
  );
};
