import PropTypes from "prop-types";
import { t } from "i18n-js";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useEventMenu } from "./hooks/useEventMenu";

export const PinEventToTopAction = ({ canEdit, event }) => {
  const isPinned = event.pinned_at_top_of_space;
  const { onPinToTop } = useEventMenu(event);
  if (!canEdit) {
    return null;
  }

  return (
    <Dropdown.ItemLink onClick={onPinToTop}>
      <ToggleSwitchWithLabel
        label={t("pin_to_space")}
        field="pin_to_top"
        value={isPinned}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};

PinEventToTopAction.propTypes = {
  event: PropTypes.shape({
    pinned_at_top_of_space: PropTypes.bool.isRequired,
  }).isRequired,
  canEdit: PropTypes.func.isRequired,
};
