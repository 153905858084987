import { t } from "i18n-js";
import { noop } from "lodash";
import { TiptapEdit } from "@circle-react/components/CommentsV3/Comment/EditModal/TiptapEdit";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Content } from "./Content";
import { useEditForm } from "./useEditForm";

const EditModalComponent = ({ space, comment, onUpdate }: any) => {
  const { id: spaceId, is_private: spaceIsPrivate } = space;
  const modal = useModal();
  const isTiptap = comment.editor === "tiptap";

  const { defaultValues, updateComment }: any = useEditForm({
    comment,
    onUpdate: (commentData: any) => {
      onUpdate(commentData);
      void modal.hide();
    },
  });

  return (
    <Modal
      title={t("comments.edit.form.title")}
      isOpen={modal.visible}
      onClose={noop}
    >
      {isTiptap ? (
        <TiptapEdit comment={comment} updateComment={updateComment} />
      ) : (
        <Form
          defaultValues={defaultValues}
          onSubmit={updateComment}
          className="h-full"
          formClassName="h-full"
        >
          <Modal.Overlay />
          <Content
            spaceId={spaceId}
            spaceIsPrivate={spaceIsPrivate}
            onClose={modal.hide}
          />
        </Form>
      )}
    </Modal>
  );
};

const EditModal = ModalManager.create(({ space, comment, onUpdate }: any) => (
  <EditModalComponent space={space} comment={comment} onUpdate={onUpdate} />
));

export const useEditModal = () => useModal(EditModal);
