import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Controller, useFormContext } from "react-hook-form";
import { useLiveStreamsConfig } from "@circle-react/components/LiveStreams/hooks";
import {
  ROOM_ACCESS_TYPES,
  ROOM_TYPES,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLiveStreamsFeatureFlags } from "@circle-react/hooks/useLiveStreamsFeatureFlags";
import { Icon } from "@circle-react-shared/Icon";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

export const AccessType = ({ name, roomType = ROOM_TYPES.STREAM }) => {
  const { config: liveStreamConfig } = useLiveStreamsConfig();
  const { publicLiveStreamsEnabled } = useLiveStreamsFeatureFlags();
  const liveRoomCapacity = liveStreamConfig?.live_room_participants_capacity;
  const { control } = useFormContext();
  const accessTypeOptions = [
    {
      value: ROOM_ACCESS_TYPES.OPEN,
      label: t("live_streams.form.access_type_community"),
      description: (
        <Typography.LabelSm>
          {t(`live_streams.form.${roomType}.access_type_community_label`, {
            maxCapacity: liveRoomCapacity,
          })}
        </Typography.LabelSm>
      ),
    },
    {
      value: ROOM_ACCESS_TYPES.SECRET,
      label: t("live_streams.form.access_type_secret"),
      description: (
        <Typography.LabelSm>
          {t(`live_streams.form.${roomType}.access_type_secret_label`)}
        </Typography.LabelSm>
      ),
    },
  ];

  if (publicLiveStreamsEnabled && roomType === ROOM_TYPES.STREAM) {
    accessTypeOptions.push({
      value: ROOM_ACCESS_TYPES.PUBLIC_STREAM,
      label: t("live_streams.form.access_type_public"),
      description: (
        <Typography.LabelSm>
          {t(`live_streams.form.${roomType}.access_type_public_label`)}
        </Typography.LabelSm>
      ),
    });
  }

  const getOption = value =>
    accessTypeOptions.find(option => option.value === value);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentOption = getOption(value);

        return (
          <Dropdown
            direction="bottom-start"
            linkType="button"
            button={
              <Dropdown.MenuButton className="form-control relative inline-block w-full text-left">
                <div className="flex justify-between">
                  <div className="content-start">
                    <Typography.LabelMd>
                      {currentOption?.label}
                    </Typography.LabelMd>
                  </div>
                  <div className="flex content-end">
                    <Icon type="cheveron-down" />
                  </div>
                </div>
              </Dropdown.MenuButton>
            }
            buttonClassName="w-full"
            className="relative w-full"
            size={DROPDOWN_SIZE.EXTRA_LARGE}
            onChange={onChange}
            options={accessTypeOptions}
            labelWeight="semibold"
          />
        );
      }}
    />
  );
};

AccessType.propTypes = {
  name: PropTypes.string,
  roomType: PropTypes.string,
};
