import { useState } from "react";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";

export const ACTIONS = {
  FIRST_LOAD: "FIRST_LOAD",
  PAGINATION: "PAGINATION",
  NEW_MESSAGE: "NEW_MESSAGE",
};

export const MESSAGE_CONTAINER_ID = "scrollableMessagesDiv";

export const useScroll = () => {
  const [firstMessage, setFirstMessage] = useState<null | { id: string }>(null);
  const { scrollAction, setScrollAction } = useCommunityInboxStore();

  const adjustScroller = () => {
    switch (scrollAction) {
      case ACTIONS.PAGINATION: {
        if (firstMessage) {
          const element = document.getElementById(firstMessage?.id);
          element?.scrollIntoView();
        }
        break;
      }
      case ACTIONS.FIRST_LOAD:
      case ACTIONS.NEW_MESSAGE: {
        const element = document.getElementById(MESSAGE_CONTAINER_ID);
        if (!element) return;
        element.scrollTop = element.scrollHeight;
        break;
      }
    }
  };

  return {
    setFirstMessage,
    setScrollAction,
    adjustScroller,
  };
};
