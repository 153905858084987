import { usePunditUserContext } from "@circle-react/contexts";

export const useLiveStreamsFeatureFlags = () => {
  const { currentCommunitySettings, isLoading } = usePunditUserContext();
  const {
    live_streams_hls_enabled: liveStreamsHlsEnabled,
    live_streams_limits_enabled: liveStreamsLimitsEnabled,
    livekit_enabled: livekitEnabled,
    live_mobile_web_enabled: liveMobileWebEnabled,
    live_mobile_webrtc_enabled: liveMobileWebRTCEnabled,
    public_live_streams_enabled: publicLiveStreamsEnabled,
    limit_url_sharing_enabled: limitUrlSharingEnabled,
    large_live_streams_enabled: isLargeLiveStreamsEnabled,
  } = currentCommunitySettings || {};

  return {
    isLoading,
    liveStreamsHlsEnabled,
    liveStreamsLimitsEnabled,
    livekitEnabled,
    liveMobileWebEnabled,
    liveMobileWebRTCEnabled,
    publicLiveStreamsEnabled,
    limitUrlSharingEnabled,
    isLargeLiveStreamsEnabled,
  };
};
