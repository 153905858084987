import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface GamificationMember {
  community_member_id: number;
  total_points: number;
  current_level: number;
  current_level_name: string;
  level_progress: number;
  points_to_next_level: number;
}

export const useGamificationMember = ({
  member_ids,
  enabled = true,
}: {
  member_ids: (number | string)[];
  enabled?: boolean;
}) =>
  useQuery<GamificationMember[]>(
    ["gamificationMember", member_ids],
    () =>
      reactQueryGet(
        internalApi.gamification.members({
          params: { member_ids },
        }),
      ),
    {
      cacheTime: 60 * 1000,
      enabled,
    },
  );
