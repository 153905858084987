import { t } from "i18n-js";
import { useMutation } from "react-query";
import { eventApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useDownloadCSV = event => {
  const { success, error } = useToast();
  const { mutate } = useMutation(() => eventApi.downloadCSV({ event }), {
    onSuccess: data => success(data?.message),
    onError: () => error(t("something_went_wrong")),
  });

  return e => {
    e.preventDefault();
    mutate();
  };
};
