import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import typingIndicatorGif from "./typing.gif";

export interface TypingIndicatorProps {
  typingUserNames: any[];
}

export const TypingIndicator = ({ typingUserNames }: TypingIndicatorProps) => {
  let typingText: React.ReactNode = "";

  if (typingUserNames.length === 1) {
    typingText = (
      <>
        <Typography.LabelXs as="span" weight="medium">
          {typingUserNames[0]}
        </Typography.LabelXs>{" "}
        <Typography.LabelXs as="span">
          {t("messaging.is_typing")}
        </Typography.LabelXs>
      </>
    );
  } else if (typingUserNames.length === 2) {
    typingText = (
      <>
        <Typography.LabelXs as="span" weight="medium">
          {typingUserNames[0]}
        </Typography.LabelXs>{" "}
        <Typography.LabelXs as="span">{t("and")}</Typography.LabelXs>{" "}
        <Typography.LabelXs as="span" weight="medium">
          {typingUserNames[1]}
        </Typography.LabelXs>{" "}
        <Typography.LabelXs as="span">
          {t("messaging.are_typing")}
        </Typography.LabelXs>
      </>
    );
  } else if (typingUserNames.length > 2) {
    typingText = (
      <>
        <Typography.LabelXs as="span" weight="medium">
          {typingUserNames[0]}
        </Typography.LabelXs>{" "}
        <Typography.LabelXs as="span">
          {t("and")} {typingUserNames.length - 1} {t("others")}{" "}
          {t("messaging.are_typing")}
        </Typography.LabelXs>
      </>
    );
  }

  return (
    <div className="flex items-center">
      {typingText && (
        <>
          <img
            loading="eager"
            src={typingIndicatorGif}
            className="mt-1.5 w-6"
            alt=""
          />
          <span>{typingText}</span>
        </>
      )}
    </div>
  );
};
