import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import I18n from "i18n-js";

export const FreeTrial = ({
  trialDays,
  hasSubscriptionPrices,
  label = t("checkbox"),
}) => {
  const hasTrialDays = trialDays > 0;
  const [enableTrialInput, setEnableTrialInput] = useState(hasTrialDays);

  const enableTrialInputHandler = event => {
    setEnableTrialInput(event.target.checked);
  };

  if (!hasSubscriptionPrices) {
    return (
      <p>
        {I18n.t("settings.paywalls.form.free_trial.need_subscription_price")}
      </p>
    );
  }

  return (
    <>
      <div className="form-group full-width">
        <div className="form-meta">
          <label>
            {I18n.t("settings.paywalls.form.free_trial.section_subtitle")}
          </label>
          <div className="field-description">
            {I18n.t("settings.paywalls.form.free_trial.section_description")}
          </div>
        </div>
        <div className="form-input">
          <div className="form-meta-button">
            <div className="simple-toggle">
              <input
                type="checkbox"
                value="true"
                id="checkbox-enable-trial"
                name="enable-trial"
                defaultChecked={enableTrialInput}
                onChange={enableTrialInputHandler}
              />
              <label htmlFor="checkbox-enable-trial">
                <span className="sr-only">{label}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {enableTrialInput && (
        <div className="input-container">
          <label>
            {I18n.t("settings.paywalls.form.free_trial.input_label")}
          </label>
          <div className="form-input-group">
            <input
              type="number"
              step="1"
              min="1"
              name="paywall[trial_days]"
              id="paywall_trial_days"
              placeholder={I18n.t(
                "settings.paywalls.form.free_trial.placeholder",
              )}
              defaultValue={trialDays || 1}
              className="form-control form-input-group--prefix"
            />
            <div className="form-input-group--suffix">
              {I18n.t("settings.paywalls.form.free_trial.days_label")}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

FreeTrial.propTypes = {
  trialDays: PropTypes.number,
  hasSubscriptionPrices: PropTypes.bool,
};
