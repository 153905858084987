import { useThemeContext } from "@circle-react/providers";

const BG_CLASSES = {
  light: {
    basic: "bg-[#F8EFDD] text-[#D69B29]",
    mid: "bg-[#EAEDFD] text-[#506CF0]",
    pro: "bg-[#F5ECFC] text-[#AC61E7]",
  },
  dark: {
    basic: "bg-[#443E32] text-[#E2AB40]",
    mid: "bg-[#31374E] text-[#7A91FD]",
    pro: "bg-[#3E354D] text-[#CF93FF]",
  },
};

const TEXT_CLASSES = {
  light: {
    basic: "text-[#D69B29]",
    mid: "text-[#506CF0]",
    pro: "text-[#AC61E7]",
  },
  dark: {
    basic: "text-[#E2AB40]",
    mid: "text-[#7A91FD]",
    pro: "text-[#CF93FF]",
  },
};

const DIVIDER_CLASSES = {
  light: {
    basic: "bg-[#F4E3C1]",
    mid: "bg-[#D1D8FB]",
    pro: "bg-[#E9D6F9]",
  },
  dark: {
    basic: "bg-[#6C624E]",
    mid: "bg-[#4D577B]",
    pro: "bg-[#5D4278]",
  },
};

const PROGRESS_COLORS = {
  light: {
    basic: "#D69B29",
    mid: "#506CF0",
    pro: "#AC61E7",
  },
  dark: {
    basic: "#E2AB40",
    mid: "#7A91FD",
    pro: "#CF93FF",
  },
};

export const getLevelType = (level?: number) => {
  if (!level) return "basic";
  if (level <= 3) return "basic";
  if (level <= 6) return "mid";
  return "pro";
};

export const useLevelColors = (level: number) => {
  const { isDarkAppearance } = useThemeContext();
  const levelType = getLevelType(level);
  const theme = isDarkAppearance ? "dark" : "light";
  return {
    bg: BG_CLASSES[theme][levelType],
    text: TEXT_CLASSES[theme][levelType],
    divider: DIVIDER_CLASSES[theme][levelType],
    progress: PROGRESS_COLORS[theme][levelType],
  };
};
