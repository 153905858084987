export const retrievePaywallCheckoutHostname = () =>
  `${window.location.origin}/checkout/`;

export const copyPaywallCheckoutUrlWithPrefix = (
  checkoutPath,
  clipboardFallbackSelector,
) => {
  const checkoutUrl = `${retrievePaywallCheckoutHostname()}${checkoutPath}`;

  if (navigator?.clipboard) {
    navigator.clipboard.writeText(checkoutUrl);
  } else {
    // clipboard fallback
    const tempInput = document.createElement("input");

    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = checkoutUrl;

    document.querySelector(clipboardFallbackSelector).appendChild(tempInput);

    tempInput.focus();
    tempInput.setSelectionRange(0, tempInput.value.length);

    document.execCommand("copy");
  }
};

export const PixLimit = {
  MINIMUM: 50,
  MAXIMUM: 1500000,
};

export const PaymentMethodType = {
  CARD: "card",
  PIX: "pix",
  GOOGLE_PAY: "google_pay",
  APPLE_PAY: "apple_pay",
  LINK: "link",
};

export const isPix = paymentMethodType =>
  paymentMethodType === PaymentMethodType.PIX;
export const isCard = paymentMethodType =>
  paymentMethodType === PaymentMethodType.CARD;
export const isGooglePay = paymentMethodType =>
  paymentMethodType === PaymentMethodType.GOOGLE_PAY;
export const isApplePay = paymentMethodType =>
  paymentMethodType === PaymentMethodType.APPLE_PAY;
export const isDigitalWallet = paymentMethodType =>
  isGooglePay(paymentMethodType) || isApplePay(paymentMethodType);

export const cannotPurchaseAgain = (hasAlreadyPurchased, paywall) =>
  hasAlreadyPurchased && !paywall?.can_be_repurchased;
