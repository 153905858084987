import classnames from "classnames";
import { t } from "i18n-js";

export const BooleanButton = ({
  value,
  handleFeedback,
  isFeedbackGiven = null,
}) => (
  <>
    <button
      className={classnames(
        "w-full rounded-lg border px-6 py-2.5 align-baseline font-medium",
        {
          "text-dark border-gray-300 dark:border-gray-500":
            value === null || value === false,
          "border-circle text-circle border-2": value === true,
          "!cursor-not-allowed": isFeedbackGiven,
          "hover:border-secondary dark:hover:border-secondary":
            !isFeedbackGiven,
        },
      )}
      onClick={() => value !== true && handleFeedback(true)}
      type="button"
    >
      {t("support_widget.feedback_yes")}
    </button>
    <button
      className={classnames(
        "w-full rounded-lg border px-6 py-2.5 align-baseline font-medium",
        {
          "text-dark border-gray-300 dark:border-gray-500":
            value === null || value === true,
          "border-circle text-circle border-2": value === false,
          "!cursor-not-allowed": isFeedbackGiven,
          "hover:border-secondary dark:hover:border-secondary":
            !isFeedbackGiven,
        },
      )}
      onClick={() => value !== false && handleFeedback(false)}
      type="button"
    >
      {t("support_widget.feedback_no")}
    </button>
  </>
);
