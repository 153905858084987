import { t } from "i18n-js";
import { useMutation } from "react-query";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { ToggleSwitchWithLabel } from "@circle-react/components/shared/uikit/ToggleSwitchWithLabel";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

export const CloseCommentsAction = ({ post, handleClose, onChange }) => {
  const { is_comments_closed: isCommentsClosed } = post;
  const translationRoot = isCommentsClosed
    ? "post.open_comments"
    : "post.close_comments";
  const { success, error } = useToast();

  const { mutate: toggleCommentsClosed } = useMutation(
    () =>
      reactQueryPatch(
        internalApi.posts.update({
          spaceId: post.space_id,
          postSlug: post.slug,
        }),
        {
          post: {
            space_id: post.space_id,
            is_comments_closed: !isCommentsClosed,
          },
        },
      ),
    {
      onSuccess: postData => {
        onChange(postData, { refetch: false });

        success(t(`${translationRoot}.alerts.success`));
      },
      onError: () => {
        error(t(`${translationRoot}.alerts.failure`));
      },
      onSettled: handleClose,
    },
  );

  return (
    <Dropdown.ItemLink onClick={toggleCommentsClosed}>
      <ToggleSwitchWithLabel
        label={t("post.action_menu.close_comments")}
        field="close_comments"
        value={isCommentsClosed}
        variant="small"
      />
    </Dropdown.ItemLink>
  );
};
