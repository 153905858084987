import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const REQUEST_KEY = "commentHierarchy";

/**
 * @deprecated
 * Please use the new getCommentHierarchyKey method from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const getCommentHierarchyKey = ({ postId, id }) => [
  REQUEST_KEY,
  postId,
  id,
];

/**
 * @deprecated
 * Please use the new useCommentHierarchy hook from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const useCommentHierarchy = ({ postId, id, postCommentsClosed }) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => getCommentHierarchyKey({ postId, id }),
    [postId, id],
  );

  useEffect(() => {
    queryClient.removeQueries(queryKey);
  }, [postCommentsClosed, queryClient, queryKey]);

  // `cacheTime: 0` ensures that comments are always fully reloaded on every
  // mount, i.e., whenever the comment section is opened. This is done to cope
  // with the current comment highlighting and anchor link behavior.
  const { data: comment, isLoading } = useQuery(
    queryKey,
    () =>
      reactQueryGet(
        internalApi.comments.commentHierarchy({
          postId: postId,
          id: id,
        }),
      ),
    {
      cacheTime: 0,
    },
  );

  const updateQueryData = commentData =>
    queryClient.setQueryData(queryKey, {
      ...commentData,
      replies: comment.replies,
    });

  return {
    comment,
    isLoading,
    updateQueryData,
  };
};
