import { t } from "i18n-js";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Tab } from "@circle-react-shared/uikit/TabV2";
import { Embed } from "./Embed";
import { Upload } from "./Upload";

const TABS: any = {
  upload: t("courses.edit.lesson.upload"),
  embed: t("courses.edit.lesson.embed"),
};

export const MediaPickerModalComponent = ({
  createEmbed,
  openFilePicker,
  getRootProps,
}: any) => {
  const modal = useModal();
  const onClose = modal.remove;
  return (
    <Modal isOpen={modal.visible} onClose={modal.remove}>
      <Modal.Overlay />
      <Modal.Content size="lg" className="h-64 py-3">
        <Tab.Group className="mt-3 overflow-hidden md:mt-0">
          <Tab.List className="border-b px-8">
            <Tab className="!text-sm md:!text-base">{TABS.upload}</Tab>
            <Tab className="!text-sm md:!text-base">{TABS.embed}</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <Upload
                getRootProps={getRootProps}
                openFilePicker={openFilePicker}
              />
            </Tab.Panel>
            <Tab.Panel className="py-[52px]">
              <Embed onClose={onClose} createEmbed={createEmbed} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Modal.Content>
    </Modal>
  );
};

const MediaPickerModal = ModalManager.create(props => (
  <MediaPickerModalComponent {...props} />
));

export const useMediaPickerModal = () => useModal(MediaPickerModal);
