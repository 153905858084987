import { immer } from "zustand/middleware/immer";
import { ACTIONS } from "@/react/components/SettingsApp/CommunityInbox/hooks/useScroll";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import type { Message } from "@circle-react/types/CommunityInbox/Message";

export interface CommunityInboxSliceProps {
  inbox: {
    currentChatId: number | null;
    setCurrentChatId: (chatId: number) => void;
    chats: Chat[];
    setChats: (chats: Chat[]) => void;
    appendChat: (chat: Chat) => void;
    updateChat: (chat: Chat) => void;
    destroyChat: (chatId: number) => void;
    messages: Message[];
    setMessages: (messages: Message[]) => void;
    appendMessage: (message: Message, viewingAs?: ViewingAsType) => void;
    updateMessage: (message: Message) => void;
    destroyMessage: (messageId: number) => void;
    resetMessages: () => void;
    scrollAction: string;
    setScrollAction: (action: string) => void;
  };
}

type ViewingAsType = "member" | "admin";

export const createCommunityInboxSlice = immer<CommunityInboxSliceProps>(
  (set, get) => ({
    inbox: {
      currentChatId: null,
      setCurrentChatId: (chatId: number) =>
        set(state => {
          state.inbox.currentChatId = chatId;
        }),
      chats: [],
      setChats: (chats: Chat[]) =>
        set(state => {
          state.inbox.chats = chats;
        }),
      appendChat: (chat: Chat) => {
        if (get().inbox.chats.some(c => c.id === chat.id)) {
          return;
        }
        set(state => {
          state.inbox.chats = [...state.inbox.chats, chat];
        });
      },
      updateChat: (chat: Chat) => {
        set(state => {
          const index = state.inbox.chats.findIndex(c => c.id === chat.id);
          if (index !== -1) {
            state.inbox.chats[index] = chat;
          }
        });
      },
      destroyChat: (chatId: number) => {
        set(state => {
          state.inbox.chats = state.inbox.chats.filter(c => c.id !== chatId);
        });
      },
      messages: [],
      setMessages: (messages: Message[]) =>
        set(state => {
          state.inbox.messages = messages;
        }),
      appendMessage: (
        message: Message,
        viewingAs: ViewingAsType = "member",
      ) => {
        if (
          message.community_inbox_chat_id !== Number(get().inbox.currentChatId)
        ) {
          // Set unread flag
          set(state => {
            const chatIndex = state.inbox.chats.findIndex(
              c => c.id === message.community_inbox_chat_id,
            );
            if (chatIndex === -1) {
              return;
            }
            if (viewingAs === "member") {
              state.inbox.chats[chatIndex].has_unread_messages_for_member =
                true;
            } else {
              state.inbox.chats[chatIndex].has_unread_messages_for_admin = true;
            }
          });
          return;
        }
        if (get().inbox.messages.some(m => m.id === message.id)) {
          return;
        }
        set(state => {
          state.inbox.messages = [...state.inbox.messages, message];
        });
      },
      updateMessage: (message: Message) => {
        if (
          message.community_inbox_chat_id !== Number(get().inbox.currentChatId)
        ) {
          return;
        }
        set(state => {
          const index = state.inbox.messages.findIndex(
            m => m.id === message.id,
          );
          if (index !== -1) {
            state.inbox.messages[index] = message;
          }
        });
      },
      destroyMessage: (messageId: number) => {
        set(state => {
          state.inbox.messages = state.inbox.messages.filter(
            m => m.id !== messageId,
          );
        });
      },
      resetMessages: () =>
        set(state => {
          state.inbox.messages = [];
        }),
      scrollAction: ACTIONS.FIRST_LOAD,
      setScrollAction: (action: string) => {
        set(state => {
          state.inbox.scrollAction = action;
        });
      },
    },
  }),
);
