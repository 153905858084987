import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPrivateCommunity } from "@circle-react/helpers/communityHelpers";
import { isPrivateSpace } from "@circle-react/helpers/spaceHelpers";
import { newSessionPath } from "@circle-react/helpers/urlHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useCurrentPostHelpers } from "../../useCurrentPostHelpers";
import { CommentsBanner } from "./CommentsBanner";
import { CustomCommentsBanner } from "./CustomCommentsBanner";

/**
 * @deprecated
 * Please use the new GatedComments component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const GatedComments = ({ post, space }) => {
  const { currentCommunity, currentUser } = usePunditUserContext();
  const {
    isSharedPostWithTeaserLayout,
    showGatedCommentSectionAtBottom,
    showGatedCommentSectionAtTop,
  } = useCurrentPostHelpers();
  const isIframeEmbed = useIsIframeEmbed();

  const lockedPostCtaContent = {
    heading: currentCommunity.locked_post_cta_heading || t("login_to_comment"),
    body: currentCommunity.locked_post_cta_body,
    buttonLabel: currentCommunity.locked_post_cta_button_text || t("log_in"),
    buttonLink:
      currentCommunity.locked_post_cta_button_url ||
      newSessionPath({
        params: {
          post_login_redirect: window.location.href,
        },
      }),
  };

  const isLoggedIn = !!currentUser;
  const showGradient = !isLoggedIn && isSharedPostWithTeaserLayout;

  const communityIsPrivate = isPrivateCommunity(currentCommunity);

  const containerClassNames = classNames("gated-post gated-post--custom-cta", {
    post__logged_out_comments: !communityIsPrivate,
    post__bottom_border: showGatedCommentSectionAtTop,
    post__top_border: showGatedCommentSectionAtBottom,
    "gated-post--standard-layout": !post.minimal_layout,
    "gated-post--no-gradient": !showGradient,
    "gated-post--gradient": showGradient,
  });

  if (
    (communityIsPrivate || isPrivateSpace(space)) &&
    currentCommunity.custom_cta_for_share_links
  ) {
    return (
      <CustomCommentsBanner
        containerClassNames={containerClassNames}
        lockedPostCtaContent={lockedPostCtaContent}
      />
    );
  }
  if (post.is_comments_enabled) {
    return (
      <CommentsBanner
        containerClassNames={containerClassNames}
        isIframeEmbed={isIframeEmbed}
        currentCommunity={currentCommunity}
      />
    );
  }
};

GatedComments.propTypes = {
  post: PropTypes.object,
  space: PropTypes.object,
};
