import { t } from "i18n-js";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { useChatUIStore } from "@/react/hooks/chatsV2/store";
import { CopyProfileLink } from "@circle-react/components/Profile/CopyProfileLink";
import { ViewOnlyMasqueradeButton } from "@circle-react/components/Profile/ViewOnlyMasqueradeButton";
import { useMemberProfileActions } from "@circle-react/components/Profile/useMemberProfileActions";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  chatRoomPath,
  getRouteWithPrevLocation,
} from "@circle-react/helpers/urlHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { DIRECT_CHAT_ROOM } from "../../../constants";

export const ProfileActions = () => {
  const { member } = useProfileContext();
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { findOrCreateChatRoom } = useChatRoom();
  const { setLiveChatRoomUuid } = useChatUIStore();
  const history = useHistory();
  const {
    shouldShowEditProfileButton,
    onClickOpenEditProfileDrawer,
    shouldShowMemberChatIcon,
    canViewOnlyMasquerade,
  } = useMemberProfileActions({
    memberPublicId: member.public_id,
    member,
    isFullPageProfile: false,
    shouldHideMessageButton: false,
  });

  const handleMessageMember = async () => {
    const chatRoomUuid = await findOrCreateChatRoom({
      kind: DIRECT_CHAT_ROOM,
      community_member_ids: [member.id],
    });

    const isLiveStream = history.location.pathname.includes("/live");
    if (isLiveStream) {
      setLiveChatRoomUuid(chatRoomUuid);
      return;
    }
    history.push(
      getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
    );
  };
  return (
    <BlockInViewOnlyMode>
      <div className="flex space-x-3">
        {shouldShowMemberChatIcon && (
          <Button
            onClick={isViewOnlyMasquerading ? noop : handleMessageMember}
            variant="circle"
            data-testid="chat-button"
          >
            {t("message")}
          </Button>
        )}
        {shouldShowEditProfileButton && (
          <Button
            variant="secondary"
            onClick={onClickOpenEditProfileDrawer}
            aria-label={t("post.share.edit_profile")}
          >
            <Icon type="pencil" useWithFillCurrentColor size={16} aria-hidden />
          </Button>
        )}
        <CopyProfileLink member={member} />
        {canViewOnlyMasquerade && <ViewOnlyMasqueradeButton member={member} />}
      </div>
    </BlockInViewOnlyMode>
  );
};
