import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import { communityPlanAddonApi } from "@circle-react/api";
import { planSettingsPath } from "@circle-react/helpers/urlHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";

export const useCommunityPlanAddon = (
  addonSlug,
  onPurchaseError,
  onPurchaseSuccess,
  { enabled } = { enabled: true },
) => {
  const { isCommunityOnTrial } = usePlanStatus();
  const {
    data: addOnData,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    internalApi.communityPlanAddons.show({
      addonId: addonSlug,
    }),
    {
      enabled,
    },
  );
  const {
    id,
    max_purchasable_quantity: maxPurchasableQuantity,
    name,
    quantity: currentQuantity,
    price,
    unit_size: unitSize,
  } = addOnData || {};
  const canPurchase =
    maxPurchasableQuantity === null || currentQuantity < maxPurchasableQuantity;

  const canPurchaseQuantity = quantity =>
    maxPurchasableQuantity === null ||
    currentQuantity + quantity <= maxPurchasableQuantity;

  const purchaseRequest = (quantity = 1) => {
    if (isCommunityOnTrial) {
      // TODO: use useRedirect hook once all communities have reactified settings
      return Promise.resolve().then(
        () => (window.location.href = planSettingsPath()),
      );
    }
    if (!canPurchaseQuantity(quantity)) {
      return Promise.reject(
        t("addons.community_plan_addon_hook.quantity_not_available_message"),
      );
    }
    return communityPlanAddonApi.create(
      { [id]: currentQuantity + quantity },
      { forReactQuery: true },
    );
  };

  const {
    mutate: purchase,
    mutateAsync: purchaseAsync,
    isLoading: isPurchasing,
  } = useMutation(quantity => purchaseRequest(quantity), {
    onSuccess: () => {
      refetch();
      onPurchaseSuccess?.();
    },
    onError: err => {
      onPurchaseError?.(err);
    },
  });

  return {
    id,
    currentQuantity,
    maxPurchasableQuantity,
    name,
    price,
    unitSize,
    canPurchase,
    canPurchaseQuantity,
    purchase,
    purchaseAsync,
    refetch,
    isLoading,
    isFetching,
    isPurchasing,
  };
};
