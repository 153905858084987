import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PointsInfoLevelItem } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/PointsInfoModal/LevelItem";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { useIsGamificationCustomPointAwardsEnabled } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/useIsGamificationCustomPointAwardsEnabled";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

const PointsInfoModalComponent = () => {
  const modal = useModal();
  const { data: gamificationSettings } = useGamificationSettings();
  const isGamificationCustomPointAwardsEnabled =
    useIsGamificationCustomPointAwardsEnabled();

  return (
    <Modal
      title={t("leaderboard.points_info.title")}
      isOpen={modal.visible}
      onClose={modal.hide}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.LabelLg weight="semibold">
              {t("leaderboard.points_info.title")}
            </Typography.LabelLg>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pb-6 pt-4">
          <div className="mb-[18px] space-y-1">
            <Typography.LabelMd weight="semibold">
              <span className="text-darkest">
                {t("leaderboard.points_info.like_to_point.title")}
              </span>
            </Typography.LabelMd>
            <Typography.BodyMd>
              <div className="text-very-dark">
                {t("leaderboard.points_info.like_to_point.description")}
              </div>
            </Typography.BodyMd>
          </div>
          {isGamificationCustomPointAwardsEnabled && (
            <div className="mb-[18px] space-y-1">
              <Typography.LabelMd weight="semibold">
                <span className="text-darkest">
                  {t("leaderboard.points_info.rewards.title")}
                </span>
              </Typography.LabelMd>
              <Typography.BodyMd>
                <div className="text-very-dark">
                  {t("leaderboard.points_info.rewards.description")}
                </div>
              </Typography.BodyMd>
            </div>
          )}
          <div className="mb-[27px] space-y-1">
            <Typography.LabelMd weight="semibold">
              <span className="text-darkest">
                {t("leaderboard.points_info.levels.title")}
              </span>
            </Typography.LabelMd>
            <Typography.BodyMd>
              <span className="text-very-dark">
                {t("leaderboard.points_info.levels.description")}
              </span>
            </Typography.BodyMd>
          </div>
          <div className="grid grid-flow-col-dense grid-rows-3 gap-5">
            {gamificationSettings?.levels?.map(
              ({ level, name, points_required }) => (
                <PointsInfoLevelItem
                  key={level}
                  level={level}
                  levelName={name}
                  pointsRequired={points_required}
                />
              ),
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
PointsInfoModalComponent.displayName = "PointsInfoModalComponent";

const PointsInfoModal = ModalManager.create(() => <PointsInfoModalComponent />);
PointsInfoModal.displayName = "PointsInfoModal";

export const usePointsInfoModal = () => useModal(PointsInfoModal);
