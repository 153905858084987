import { useCallback } from "react";
import { flatten, isFunction, uniqBy } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { useInfiniteQueryHelpers } from "@/react/hooks/reactQuery/useInfiniteQueryHelpers";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import {
  isBasicPostsSpace,
  isEventsSpace,
  isImagesSpace,
} from "@circle-react/helpers/spaceHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const REQUEST_KEY = "posts";

// eslint-disable-next-line sonarjs/cognitive-complexity -- Added before disable comments where obligatory
export const usePostList = (params = {}, isEnabled = true, onSuccess) => {
  const queryClient = useQueryClient();
  const { removeRecord, findAndReplaceRecord } = useInfiniteQueryHelpers();
  // Remove undefined params
  params = { ...params, per_page: params?.per_page || 12 }; // 12 is multiple of 4, 3, 2 and 1 hence we always will have full rows
  Object.keys(params).forEach(key => {
    if (typeof params[key] === "undefined") {
      delete params[key];
    }
  });
  const currentSpaceContext = useCurrentSpaceContext();
  const { data: space } = currentSpaceContext;
  const queryKey = [REQUEST_KEY, space?.id, params];

  const fetchPage = page =>
    reactQueryGet(
      internalApi.posts.index({
        spaceId: space.id,
        params: { page, ...params },
      }),
    );

  const isCalendarView = params?.used_on === "calendar";

  const { data, hasNextPage, isLoading, refetch, fetchNextPage } =
    useInfiniteQuery(queryKey, ({ pageParam = 1 }) => fetchPage(pageParam), {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      keepPreviousData: isCalendarView,
      enabled:
        isEnabled &&
        !!space?.id &&
        (isBasicPostsSpace(space) ||
          isEventsSpace(space) ||
          isImagesSpace(space)),
      onSuccess: data => {
        if (isFunction(onSuccess)) {
          onSuccess(data);
        }
      },
    });

  const onPostChange = useCallback(
    async (post, { refetch = true } = {}) => {
      let fetchedPost = post;
      if (refetch) {
        try {
          const url = internalApi.posts.show({
            spaceId: post.space_id,
            postSlug: post.slug,
          });
          fetchedPost = await queryClient.fetchQuery(url);
        } catch (err) {
          console.error(err);
        }
      }
      findAndReplaceRecord(fetchedPost, queryKey);
    },
    [findAndReplaceRecord],
  );

  const onPostDestroy = useCallback(
    postId => {
      removeRecord(postId, queryKey);
    },
    [removeRecord],
  );

  let posts = [];
  if (!isLoading && data) {
    posts = uniqBy(flatten(data.pages.map(page => page.records)), "id");
  }
  const mapPages = callback => {
    if (isLoading || !data) return [];
    return data.pages.map(callback);
  };

  return {
    mapPages,
    hasNextPage,
    refetch,
    isLoading,
    fetchNextPage,
    posts,
    onPostChange,
    onPostDestroy,
  };
};

export const usePostListPinnedOnSidebar = postsPerPage =>
  usePostList({
    only_pinned: true,
    per_page: postsPerPage || 50,
    used_on: "pinned_post_sidebar",
  });
