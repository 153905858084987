import { useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { eventApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface UseRecurringEventRsvpApiProps {
  event: any;
}

export const useRecurringEventRsvpApi = ({
  event,
}: UseRecurringEventRsvpApiProps) => {
  const [events, setEvents] = useState<any[]>([]);
  const { error } = useToast();

  const onMutationError = (data: any) =>
    error(data?.message ? data?.message : t("something_went_wrong"));

  const { mutate: fetchEvents, isLoading } = useMutation(
    () => eventApi.fetchRecurring({ event }),
    {
      onSuccess: data => setEvents(data),
      onError: onMutationError,
    },
  );

  const { mutateAsync: onRecurringRsvpSubmit } = useMutation(
    (params: any) => eventApi.recurringRsvp({ event, params }),
    {
      onError: onMutationError,
    },
  );

  const { mutateAsync: onRecurringUnRsvpSubmit } = useMutation(
    (params: any) => eventApi.recurringUnRsvp({ event, params }),
    {
      onError: onMutationError,
    },
  );

  return {
    isLoading,
    events,
    fetchEvents,
    onRecurringRsvpSubmit,
    onRecurringUnRsvpSubmit,
  };
};
