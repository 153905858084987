import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { ActionButton } from "./ActionButton";
import { LikeCounter } from "./LikeCounter";

/**
 * @deprecated
 * Please use the new EngagementActions component from app/javascript/react/components/CommentsV3 or duplicate the changes to CommentsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const EngagementActions = ({
  canLike,
  canReply,
  canSeeLikes,
  isLiked,
  isLikeDisabled,
  likesCount,
  onLikeClick,
  onLikesCountClick,
  onReplyClick,
}) => {
  const { currentUser } = usePunditUserContext();

  /*
    We display the likes count based on the following scenarios -
      - Display likes count if and only if canSeeLikes is true, likesCount is greater than 0, and,
      - Display likes count if a user is logged in or if the user can like or reply to the post.
      - The reason for the second condition is that for communities that allow members to sign up we render
        the like/comment button for non logged -n users(we redirect them to the login page) and
        hence we can show the likes count as well.
  */

  const shouldShowLikesCount =
    canSeeLikes && likesCount > 0 && (currentUser || (canLike && canReply));
  return (
    <div className="post__actions post__actions-visible">
      {canLike && (
        <ActionButton
          className={classnames("post__actions--like", { liked: isLiked })}
          label={t(`comments.engagement_actions.${isLiked ? "liked" : "like"}`)}
          onClick={onLikeClick}
          disabled={isLikeDisabled}
          isLiked={isLiked}
        />
      )}
      {canReply && (
        <ActionButton
          className="post__actions--reply"
          label={t("comments.engagement_actions.reply")}
          onClick={onReplyClick}
        />
      )}
      {shouldShowLikesCount && (
        <LikeCounter likesCount={likesCount} onClick={onLikesCountClick} />
      )}
    </div>
  );
};

EngagementActions.propTypes = {
  canLike: PropTypes.bool.isRequired,
  canReply: PropTypes.bool.isRequired,
  canSeeLikes: PropTypes.bool.isRequired,
  isLiked: PropTypes.bool.isRequired,
  isLikeDisabled: PropTypes.bool.isRequired,
  likesCount: PropTypes.number.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onLikesCountClick: PropTypes.func.isRequired,
  onReplyClick: PropTypes.func.isRequired,
};
