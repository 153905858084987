import { useEffect } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import {
  useCurrentCommunityMember,
  useCurrentPostContext,
} from "@circle-react/contexts";
import type { Post, Space } from "@circle-react/types";
import type { Comment as CommentType } from "@circle-react/types/Comment";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Comment } from "../Comment";
import { CommentForm } from "../CommentForm";
import { useJumpToComment } from "../useJumpToComment";
import { useCommentsList } from "./useCommentsList";

export interface CommentsListProps {
  space: Space;
  post: Post;
  parentCommentId?: number;
  preloaded?: boolean;
  preloadedComments?: any[];
  preloadedCommentsTotalCount?: number;
  showReplyForm?: boolean;
  autofocusReplyForm?: boolean;
  communityMemberForReply?: any;
  onCommentReplyClick?: (comment: CommentType) => void;
  onCommentCreate?: (comment: CommentType) => void;
  onCommentDelete?: (comment: CommentType) => void;
  formRef?: any;
}

export const CommentsList = ({
  space,
  post,
  parentCommentId,
  preloaded = false,
  preloadedComments = [],
  preloadedCommentsTotalCount = 0,
  showReplyForm = false,
  autofocusReplyForm = false,
  communityMemberForReply,
  onCommentReplyClick,
  onCommentCreate,
  onCommentDelete,
  formRef,
}: CommentsListProps) => {
  const currentCommunityMember = useCurrentCommunityMember();
  const { usedIn, isUsedInMinimalTipTapViewModal } = useCurrentPostContext();

  const location: any = useLocation();
  const { state } = location;
  const shouldFocusOnCommentsSection = state?.shouldFocusOnCommentsSection;

  const {
    comments,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    addCommentToList,
    updateCommentInList,
    removeCommentFromList,
  } = useCommentsList({
    postId: post.id,
    parentCommentId,
    preloaded,
    preloadedComments,
    preloadedCommentsTotalCount,
    postCommentsClosed: post.is_comments_closed,
    postLikingDisabled: post.is_liking_disabled,
    usedIn,
  });

  useJumpToComment({ enabled: !isLoading && !parentCommentId });

  const handleCommentCreate = (comment: CommentType) => {
    addCommentToList(comment);
    onCommentCreate?.(comment);

    const newCommentElement = document.getElementById(
      `comment_wrapper_${comment.id}`,
    );

    if (isUsedInMinimalTipTapViewModal && newCommentElement) {
      newCommentElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleCommentDelete = (comment: CommentType) => {
    removeCommentFromList(comment);
    onCommentDelete?.(comment);
  };

  const isLoadingComments = isLoading || isFetchingNextPage;

  useEffect(() => {
    if (!isLoadingComments && shouldFocusOnCommentsSection) {
      const commentElement = document.getElementById("all_comments");
      commentElement?.scrollIntoView({ behavior: "smooth" });
      location.state.shouldFocusOnCommentsSection = false;
    }
  }, [isLoadingComments, shouldFocusOnCommentsSection, location.state]);

  const shouldShowForm =
    currentCommunityMember &&
    space &&
    showReplyForm &&
    (!isLoadingComments || comments.length > 0);

  return (
    <div
      className={classNames({
        "my-3": !shouldShowForm && !parentCommentId,
      })}
    >
      {comments.map(comment => (
        <Comment
          key={comment.id}
          space={space}
          post={post}
          comment={comment}
          autofocusReplyForm
          onReplyClick={onCommentReplyClick}
          onUpdate={updateCommentInList}
          onDelete={handleCommentDelete}
          onReplyCreate={onCommentCreate}
          onReplyDelete={onCommentDelete}
        />
      ))}
      {isLoadingComments && <LoaderRow />}
      {!isLoadingComments && hasNextPage && (
        <div
          className={classNames("load-more", {
            "py-5 text-center": !parentCommentId,
            "mb-4 mt-2 pl-5 text-left text-sm font-medium md:pl-6":
              parentCommentId,
          })}
        >
          <button
            type="button"
            onClick={event => {
              event.preventDefault();
              void fetchNextPage();
            }}
            className={classNames(
              "focus-visible:outline-secondary focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
              {
                "load-more__replies text-link": parentCommentId,
                "load-more__comments border-hover bg-primary text-dark hover:text-darkest focus-visible:text-darkest inline-block rounded-md border px-6 py-2 text-sm font-semibold leading-normal transition-colors duration-150":
                  !parentCommentId,
              },
            )}
          >
            {t(
              parentCommentId
                ? "comments.list.show_more_replies"
                : "comments.list.show_more_comments",
            )}
          </button>
        </div>
      )}
      {isLoadingComments && isUsedInMinimalTipTapViewModal && (
        <div className="bg-primary absolute bottom-0 left-0 w-full !px-5 !py-4 md:!px-8">
          <div className="flex gap-4">
            <div className="bg-tertiary h-10 w-10 animate-pulse rounded-full" />
            <div className="border-primary h-10 w-full rounded-lg border p-px" />
          </div>
        </div>
      )}
      {shouldShowForm && (
        <CommentForm
          spaceId={space.id}
          postId={post.id}
          parentCommentId={parentCommentId}
          communityMemberForReply={communityMemberForReply}
          autoFocus={autofocusReplyForm}
          className={classNames(
            isUsedInMinimalTipTapViewModal && "!max-h-[360px]",
          )}
          onCreate={handleCommentCreate}
          bodyInputRef={formRef}
        />
      )}
    </div>
  );
};
