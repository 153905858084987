import { ThreeUserRow } from "@circleco/ui-kit";
import classNames from "classnames";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { LikedByMessage } from "./LikedByMessage";
import { usePostLikesModal } from "./PostLikesModal";

export const PostLikedByMemberStack = () => {
  const { post, isPostBodyEmpty } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const { isV3Enabled } = useIsV3();

  const {
    user_likes_count: likesCount,
    is_liking_enabled: isLikingEnabled,
    sample_user_likes_community_members: communityMembersThatLiked = [],
    post_details_loaded: hasPostDetailsLoaded,
  } = post;
  const postLikesModal = usePostLikesModal();

  if (isV3Enabled) {
    return null;
  }

  if (!hasPostDetailsLoaded) {
    return (
      <div
        className={classNames("post__engagement mb-5", {
          "!pt-5": isImagePost(post) && isPostBodyEmpty,
          "mx-5 md:mx-6": usedIn !== "minimal-post-modal",
        })}
      >
        <SkeletonLoader variant="members-avatar-row" />
      </div>
    );
  }

  if (!isLikingEnabled || !likesCount || !communityMembersThatLiked.length) {
    return null;
  }

  const transformUserRowData = () =>
    communityMembersThatLiked.slice(0, 3).map((user: any) => ({
      src: user.avatar_url,
      name: user.name,
    }));

  return (
    <button
      type="button"
      onClick={() => postLikesModal.show({ post })}
      className={classNames(
        "post__engagement focus-visible:outline-secondary mb-5 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "pt-5":
            isImagePost(post) &&
            usedIn !== "full-post-modal" &&
            isPostBodyEmpty,
          "mx-5 md:mx-6": usedIn !== "minimal-post-modal",
        },
      )}
    >
      <div className="engagement__likes flex items-center">
        <div className="engagement__members border-tertiary mr-3">
          <ThreeUserRow
            users={transformUserRowData()}
            isFullRounded={isV3Enabled}
          />
        </div>
        <div
          className="engagement__members--descriptor text-dark text-left text-sm"
          data-testid="post-engagement"
        >
          <LikedByMessage
            firstCommunityMemberToLike={communityMembersThatLiked[0]}
            secondCommunityMemberToLike={communityMembersThatLiked[1]}
            likesCount={likesCount}
            isLoading={!hasPostDetailsLoaded}
          />
        </div>
      </div>
    </button>
  );
};
