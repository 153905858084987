import { useState } from "react";
import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import { eventApi } from "@circle-react/api";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ATTENDEE_ROLES } from "../utils";

const PER_PAGE = 10;

export const useEventAttendeesApi = (
  event,
  role = ATTENDEE_ROLES.PARTICIPANT,
  perPage = PER_PAGE,
) => {
  const { space_id: spaceId, slug } = event || {};
  const { error, success } = useToast();
  const [page, setPage] = useState(1);
  const fetchNextPage = () => {
    setPage(page + 1);
  };
  const fetchPrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const resetPage = () => {
    setPage(1);
  };

  const {
    isLoading,
    isFetching,
    data,
    refetch,
    remove: removeAttendeesData,
  } = useQuery(
    internalApi.eventAttendees.index({
      spaceId,
      slug,
      params: {
        page,
        role,
        per_page: perPage,
      },
    }),
    {
      keepPreviousData: true,
      enabled: !!event,
    },
  );

  const records = data?.records || [];
  const totalRecordCount = data?.count;
  const totalConfirmedRecordCount = data?.total_confirmed_record_count;
  const pageRecordCount = data?.page_count;
  const pageCount = totalRecordCount
    ? Math.ceil(totalRecordCount / PER_PAGE)
    : 0;
  const hasPrevPage = page > 1;
  const hasNextPage = !!data?.has_next_page;
  const startRecordNumber = (page - 1) * PER_PAGE + 1;
  const endRecordNumber = startRecordNumber + pageRecordCount - 1;

  // Mutation for adding attendees
  const { isLoading: isAdding, mutateAsync: addAttendeesAsync } = useMutation(
    params => eventApi.bulkRsvp({ event, params }),
    {
      onSuccess: () => {
        setPage(1);
        removeAttendeesData();
        success(t("events.edit_modal.people.add_attendees_success"));
      },
      onError: () => {
        error(t("events.edit_modal.people.add_attendees_error"));
      },
    },
  );

  return {
    page,
    records,
    totalRecordCount,
    totalConfirmedRecordCount,
    pageRecordCount,
    pageCount,
    hasPrevPage,
    hasNextPage,
    startRecordNumber,
    endRecordNumber,
    isLoading,
    isFetching,
    isAdding,
    addAttendeesAsync,
    refetch,
    fetchNextPage,
    fetchPrevPage,
    resetPage,
  };
};
