import { PlainMessageBody } from "./PlainMessageBody";
import { TipTapMessageBody } from "./TipTapMessageBody";

export interface ContentBodyProps {
  message: any;
  isInitialPage?: boolean;
  isPinnedMessageAtTop?: boolean;
}

export const ContentBody = ({
  message,
  isInitialPage = false,
  isPinnedMessageAtTop = false,
}: ContentBodyProps) => {
  if (message.rich_text_body) {
    return (
      <TipTapMessageBody
        rich_text_body={message.rich_text_body}
        isInitialPage={isInitialPage}
        isPinnedMessageAtTop={isPinnedMessageAtTop}
        editedAt={message.edited_at}
        chatRoomAccessArr={message.embedded_chat_message_access}
      />
    );
  }

  return (
    <PlainMessageBody
      body={message.body}
      isPinnedMessageAtTop={isPinnedMessageAtTop}
    />
  );
};
