import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const Upload = ({ getRootProps, openFilePicker }) => (
  <div
    className="border-primary mx-8 my-6 flex flex-col items-center justify-center rounded-md border border-dotted py-6"
    role="tabpanel"
    {...getRootProps()}
  >
    <Icon size={42} className="h-[42px] !w-[42px]" type="new-file-plus" />
    <div className="text-dark mb-1 mt-5">
      <Typography.LabelSm weight="semibold">
        {t("courses.edit.lesson.drop_here")}
      </Typography.LabelSm>
    </div>
    <div className="text-default mb-6">
      <Typography.LabelSm>
        {t("courses.edit.lesson.drop_placeholder")}
      </Typography.LabelSm>
    </div>
    <Button type="button" onClick={openFilePicker} variant="primary">
      {t("courses.edit.lesson.choose_a_file")}
    </Button>
  </div>
);
