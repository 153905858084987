import PropTypes from "prop-types";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV2/useCurrentPostHelpers";
import { EventDropdownActions } from "./EventDropdownActions";
import { PostDropdownActions } from "./PostDropdownActions";

/**
 * @deprecated
 * Please use the new NewPostActionsDropdown component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */

export const NewPostActionsDropdown = ({
  onChange,
  onPostDestroy,
  refetchPosts,
  currentCommunity,
}) => {
  const { isBasicPost, isEventPost, isImagePost } = useCurrentPostHelpers();
  return (
    <>
      {(isBasicPost || isImagePost) && (
        <PostDropdownActions
          onChange={onChange}
          onPostDestroy={onPostDestroy}
          refetchPosts={refetchPosts}
          currentCommunity={currentCommunity}
        />
      )}
      {isEventPost && (
        <EventDropdownActions
          onChange={onChange}
          onPostDestroy={onPostDestroy}
        />
      )}
    </>
  );
};

NewPostActionsDropdown.propTypes = {
  onChange: PropTypes.func,
  onPostDestroy: PropTypes.func,
  refetchPosts: PropTypes.func,
  currentCommunity: PropTypes.object,
};
