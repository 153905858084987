import { format, isValid } from "date-fns";
import { t } from "i18n-js";
import { startCase } from "lodash";
import type { Person } from "@circle-react/types/AudienceList";

export const formatDate = (value: string = "", dateFormat: string = "PP") => {
  if (!value) return "─";
  const date = new Date(value);

  if (!isValid(date)) return "─";

  return startCase(format(date, dateFormat));
};

export const getRole = (roles: any = {}) => {
  if (roles.admin) return t("role_admin");
  if (roles.moderator) return t("moderator");
  return t("member");
};

export const getAudienceRole = (person: {
  type: Person["type"];
  roles: Person["roles"];
}) => {
  if (person.type === "CommunityMember") {
    return getRole(person.roles);
  }
  return "─";
};

export const getEmailMarketingStatus = (status: boolean) =>
  status
    ? t("settings.manage_members.subscribed")
    : t("settings.manage_members.unsubscribed");

export const getInvitationStatus = (status: string) =>
  t(["invitation_status_column", status], {
    defaultValue: "─",
  });
